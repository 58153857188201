import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AdsRevenue extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {/* <h3 style={{ marginTop: "2rem" }}>This is analytics page </h3> */}

          <div className="container-fluid">
            <div className="row">
              <div style={{ marginBottom: "3rem" }}>
                <iframe
                  width="1200"
                  height="1000"
                  src="https://lookerstudio.google.com/embed/reporting/1e64d17b-c659-416a-8645-5162f87024ff/page/qlD"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AdsRevenue.propTypes = {
  dashboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {})(AdsRevenue);
