import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddVoiceModal from "../../common/Modal/AddVoice";
import Button from "../../common/Button/Button";
import { validateAddVoice } from "../../../validations/add-validation";
import {
  getAllVoices,
  addVoice,
  searchManagers,
  searchVoice,
  deleteVoice,
  deleteManager,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManageVoices extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddVoiceModal: false,
      showSubscriptionModal: false,
      voice: {
        voiceName: "",
        voiceLocale: "",
        voiceIdentifier: "",
      },
      showFilter: true,
      deviceType: "",
      query: "",
      pageLimit: 20,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    this.props.getAllVoices(this.state.token);
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddVoiceModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddVoiceModalClose = () => {
    this.setState({ showAddVoiceModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteVoice(this.state.id, this.state.token);
    this.props.getAllVoices(this.state.token);
    this.setState({ showDeleteModal: false });
  };

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const voice = { ...this.state.voice };
    voice[e.target.name] = e.target.value;
    this.setState({ voice });
  };

  handleFilterChange = (event) => {
    const selectedOption = event.target.value;

    this.setState({ deviceType: selectedOption }, () => {
      this.props.getAllVoices(this.state.token, this.state.deviceType);
    });
  };

  handleRemoveFilter = () => {
    this.setState({ deviceType: "" }, () => {
      this.props.getAllVoices(this.state.token, this.state.deviceType);
    });
  };

  handleAddVoiceSubmit = (e) => {
    e.preventDefault();

    const { voiceLocale, voiceName, voiceIdentifier } = this.state.voice;

    const result = validateAddVoice(voiceName, voiceLocale, voiceIdentifier);
    if (result) return toast.error(result);

    this.props.addVoice(this.state.voice, this.state.token);

    this.setState({ showAddVoiceModal: false });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchVoice(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllVoices(this.state.token, page, this.state.pageLimit);
  };

  render() {
    // const totalManagers = sessionStorage.getItem("total_voices") || 0;
    const { allVoices } = this.props;
    const totalVoices = allVoices.length || 0;
    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Voices</h5>
          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-5 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Voice"
                  handleClick={this.addManagerModalClicked}
                />
              </div>
              <div className="col-md-2">
                {/* <div className="filter-form float-right">
                  {this.state.showFilter && (
                    <>
                      <i className="fa fa-filter filter-size"></i>
                      <select
                        className="filter-select"
                        value={this.state.deviceType}
                        onChange={this.handleFilterChange}
                      >
                        <option value="" disabled>
                          Select Filter
                        </option>
                        <option value="ios">ios</option>
                        <option value="andriod">andriod</option>
                      </select>
                    </>
                  )}

                  {this.state.deviceType && this.state.showFilter && (
                    <i
                      className="fa fa-times remove-filter-icon"
                      onClick={this.handleRemoveFilter}
                    ></i>
                  )}
                </div> */}
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Voices"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allVoices={this.props.dashboard.allVoices}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={totalVoices}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Voice"
          text="Are you sure you want to delete this voice ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddVoiceModal
          showAddVoiceModal={this.state.showAddVoiceModal}
          voiceName={this.state.voiceName}
          voiceLocale={this.state.voiceLocale}
          voiceIdentifier={this.state.voiceIdentifier}
          handleAddVoiceModalClose={this.handleAddVoiceModalClose}
          handleAddVoiceSubmit={this.handleAddVoiceSubmit}
          handleChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

ManageVoices.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllVoices: PropTypes.func.isRequired,
  addVoice: PropTypes.func.isRequired,
  searchManagers: PropTypes.func.isRequired,
  searchVoice: PropTypes.func.isRequired,
  deleteManager: PropTypes.func.isRequired,
  deleteVoice: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allVoices: state?.dashboard?.allVoices,
});

export default connect(mapStateToProps, {
  getAllVoices,
  addVoice,
  searchManagers,
  searchVoice,
  deleteManager,
  deleteVoice,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageVoices);
