import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

const FormDropdownSub = (props) => {
  const [newSubCategory, setSubCategory] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  console.log("props?.selectedCat:", props?.selectedCat);

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Sub-category
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            height: "300px",
            overflowY: "scroll",
          }}
        >
          {props.allSubCategories &&
            props.allSubCategories.length > 0 &&
            props.allSubCategories.map((p, index) => (
              <Dropdown.Item
                onSelect={() => {
                  setSubCategoryId(p?.["_id"]);
                  setSubCategory(p?.subCategoryName[0]?.name);
                  props.changeSubCatHandler(p?.["_id"]);
                }}
                disabled={
                  p?.subCategoryName[0]?.name === "My Own Affirmations" ||
                  p?.subCategoryName[0]?.name === "Favourites"
                    ? true
                    : false
                }
                active={props?.selectedCat && p?.["_id"] === props?.selectedCat}
              >
                {p?.subCategoryName[0]?.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownSub;
