import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import ProfileDetails from "./ProfileDetails";
import ProfileImg from "./ProfileImg";
import { getProfile, submitProfile } from "../../../actions/dashboardActions";
import { validateProfile } from "../../../validations/profile";
import { api_url } from "../../../utils/api";
import "./styles.scss";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      image: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      created_at: "",
      imagePreviewUrl: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("jwtToken");
    if (token) this.props.getProfile(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.adminProfile) {
      this.setState({
        firstName: nextProps.dashboard.adminProfile.firstName,
        lastName: nextProps.dashboard.adminProfile.lastName,
        email: nextProps.dashboard.adminProfile.email,
        phoneNumber: nextProps.dashboard.adminProfile.phoneNumber,
        createdAt: nextProps.dashboard.adminProfile.createdAt,
        image: nextProps.dashboard.adminProfile.image,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRoleChange = (role) => {
    this.setState({ role });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();

    let file = e.target.files[0];
    console.log("filefile", file);

    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // const validate = validateProfile(this.state);
    // if (validate) return toast.error(validate);

    const profile = new FormData();
    profile.append("image", this.state.image);
    profile.append("firstName", this.state.firstName);
    profile.append("lastName", this.state.lastName);
    profile.append("phoneNumber", this.state.phoneNumber);
    profile.append("email", this.state.email);
    // profile.append("address", this.state.address);
    this.props.submitProfile(profile, this.state.token);
    this.props.getProfile(this.state.token);
  };

  render() {
    let imagePreview = api_url + "/default.png";
    if (this.state.image) imagePreview = `${this.state.image}`;
    if (this.state.imagePreviewUrl) imagePreview = this.state.imagePreviewUrl;

    return (
      <React.Fragment>
        <div className="profile-wrapper new-pw">
          <div className="row">
            <div className="col-md-12">
              <h5 className="page_heading new-page-heading">Manage Profile</h5>
            </div>

            <div className="col-md-12">
              <form onSubmit={this.handleSubmit} encType="multipart/form-data">
                <div className="row p-custom">
                  <div className="col-md-4">
                    <div className="profile-img">
                      <ProfileImg
                        imagePreview={imagePreview}
                        handleImageChange={this.handleImageChange}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email}
                        phoneNumber={this.state.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="profile-details">
                      <ProfileDetails
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email}
                        phoneNumber={this.state.phoneNumber}
                        country={this.state.country}
                        state={this.state.state}
                        city={this.state.city}
                        pin={this.state.pin}
                        gender={this.state.gender}
                        handleChange={this.handleChange}
                        handleRadioChange={this.handleRadioChange}
                        handleSubmit={this.handleSubmit}
                        handleDateChange={this.handleDateChange}
                        handleRoleChange={this.handleRoleChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  dashboard: PropTypes.object.isRequired,
  submitProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getProfile, submitProfile })(Profile);
