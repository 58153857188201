import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";

const Form = (props) => {
  return (
    <React.Fragment>
      <form onSubmit={props.handleVoiceUpdate}>
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">Voice Name</label>
          <FormInput
            icon="fa fa-microphone icon"
            size="13px"
            type="text"
            name="voiceName"
            placeholder="Voice Name"
            className="form-control shadow-none"
            value={props.voiceName}
            handleChange={(e) => props.setVoiceName(e.target.value)}
          />
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">Voice Locale</label>
          <FormInput
            icon="fa fa-microphone icon"
            size="13px"
            type="text"
            name="voiceLocale"
            placeholder="Voice Locale"
            className="form-control shadow-none"
            value={props.voiceLocale}
            handleChange={(e) => props.setVoiceLocale(e.target.value)}
          />
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">Voice Identifier</label>
          <FormInput
            icon="fa fa-microphone icon"
            size="13px"
            type="text"
            name="voiceIdentifier"
            placeholder="Voice Identifier"
            className="form-control shadow-none"
            value={props.voiceIdentifier}
            handleChange={(e) => props.setVoiceIdentifier(e.target.value)}
          />
        </div>

        <div className="form-group mb-0">
          <Button
            value="Update"
            className="btn btn-primary btn-block update-details-btn shadow-none"
            type="submit"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
