import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Button from "../../common/Button/Button";
import { validateTerms } from "../../../validations/cms";
import {
  addTermsAndConditions,
  getCMS,
} from "../../../actions/dashboardActions";
import "./styles.scss";

class Terms extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
      text: "",
    };
  }

  componentDidMount() {
    this.props.getCMS(this.state.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.termsAndConditions) {
      this.setState({
        text: nextProps.dashboard.cms.termsAndConditions,
      });
    }
  }

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ text: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let { text } = this.state;

    let result = validateTerms(text);
    if (result) return toast.error(result);

    this.props.addTermsAndConditions(text, this.state.token);
  };

  render() {
    return (
      <React.Fragment>
        <div className="add-about-wrapper new-aaw">
          <div className="row mx-0">
            <div className="col-md-6 pl-0">
              <h5 className="page_heading new-page-heading">Terms & Conditions</h5>
            </div>
            <div className="col-md-6 pr-0">
              <Link
                target="_blank"
                to="/terms&conditions"
                className="btn view-btn shadow-none"
              >
               <i className="fa fa-eye"></i>   View
              </Link>
            </div>
          </div>
      
          <div className="mt-3">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.handleSubmit}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={this.state.text}
                    onReady={(editor) => this.handleInit(editor)}
                    onChange={(event, editor) =>
                      this.handleChange(event, editor)
                    }
                  />

                  <Button
                    type="submit"
                    className="btn submit-btn shadow-none"
                    value="Update"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Terms.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addTermsAndConditions: PropTypes.func.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { addTermsAndConditions, getCMS })(
  Terms
);
