import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import {
  getTheme,
  updateThemes,
  getAllThemeCategories,
} from "../../../actions/dashboardActions";

const ManagerDetails = (props) => {
  const { id } = useParams();
  const [activeBtn, setActiveBtn] = useState(true);
  const [inactiveBtn, setInactiveBtn] = useState(false);
  const [isPremium, setIsPremium] = useState(true);
  const [themeImage, setThemeImage] = useState(
    props.dashboard.singleTheme?.backgroundImage
  );
  const [smallImage, setSmallImage] = useState("");
  const [smallImagePreviewUrl, setSmallImagePreviewUrl] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [backgroundOpacity, setBackgroundOpacity] = useState("");
  const [fontName, setFontName] = useState("");
  const [andriodFontName, setAndriodFontName] = useState("");
  const token = localStorage.getItem("jwtToken");

  useEffect(() => {
    props.getTheme(id, token);
  }, []);

  useEffect(() => {
    if (props.dashboard.singleTheme) {
      setThemeImage(props.dashboard.singleTheme?.backgroundImage);
      setBackgroundColor(props.dashboard.singleTheme?.backgroundColor);
      setFontColor(props.dashboard.singleTheme?.fontColor);
      setBackgroundOpacity(props.dashboard.singleTheme?.backgroundOpacity);
      setFontName(props.dashboard.singleTheme?.fontName);
      setAndriodFontName(props.dashboard.singleTheme?.andriodFontName);
      setIsPremium(props.dashboard.singleTheme?.isPremium)
    }
  }, [props]);

  const handleThemeUpdate = (e) => {
    e.preventDefault();

    const theme = new FormData();
    theme.append("themeId", id);
    theme.append("theme", smallImage);
    theme.append("fontColor", fontColor);
    theme.append("fontName", fontName);
    theme.append("andriodFontName", andriodFontName);
    theme.append("backgroundColor", backgroundColor);
    theme.append("backgroundOpacity", backgroundOpacity);
    theme.append("isPremium", isPremium);
    console.log("UPDATE THEME PAYLOAD ---->", theme);

    props.updateThemes(theme, token);
    props.history.push("/admin/themes-fonts");
    // window.location.reload();
  };

  const handleSmallImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setSmallImage(file);
      setSmallImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleRadioChange = (status) => {
    console.log("status",status);
    if (status === "true") {
      setIsPremium(true);
      setActiveBtn(true);
      setInactiveBtn(false);
    } else {
      setIsPremium(false);
      setActiveBtn(false);
      setInactiveBtn(true);
    }
  };


  if (!props.dashboard.singleTheme) {
    return <><p>Loading ..</p></>
  }

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">Edit Theme Details</h3>
        <div className="row mx-0">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                key={props.dashboard.singleTheme._id}
                themeImage={themeImage}
                setThemeImage={setThemeImage}
                smallImagePreviewUrl={smallImagePreviewUrl}
                setSmallImagePreviewUrl={setSmallImagePreviewUrl}
                themeDetails={props.dashboard.singleTheme}
                handleThemeUpdate={handleThemeUpdate}
                themeId={id}
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                backgroundOpacity={backgroundOpacity}
                setBackgroundOpacity={setBackgroundOpacity}
                fontName={fontName}
                setFontName={setFontName}
                fontColor={fontColor}
                setFontColor={setFontColor}
                andriodFontName={andriodFontName}
                setAndriodFontName={setAndriodFontName}
                handleSmallImageChange={handleSmallImageChange}
                handleRadioChange={handleRadioChange}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ManagerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getTheme: PropTypes.func.isRequired,
  updateThemes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getTheme, updateThemes })(
  memo(ManagerDetails)
);
