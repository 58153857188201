import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

const FormDropdownPref = (props) => {
  const [preference, setPreference] = useState("");
  const [preferenceId, setPreferenceId] = useState("");

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Preference
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.allPreferences &&
            props.allPreferences.length > 0 &&
            props.allPreferences.map((p, index) => (
              <Dropdown.Item
                onSelect={() => {
                  setPreferenceId(p?.["_id"]);
                  setPreference(p?.preference[0].name);
                  props.changePreferenceHandler(p?.["_id"]);
                }}
                active={
                  props?.selectedPreference &&
                  p?.["_id"] === props?.selectedPreference
                }
              >
                {p?.preference?.[0].name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownPref;
