export const fontNamesIOS = [
  "BadScript-Regular",
  "Belleza-Regular",
  "BubblegumSans-Regular",
  "Calligraffitti-Regular",
  "CinzelDecorative-Bold",
  "ComingSoon-Regular",
  "DancingScript-Regular",
  "Dosis-Medium",
  "FaunaOne",
  "FugazOne-Regular",
  "GentiumBookPlus-Bold",
  "Gruppo",
  "IM_FELL_English_SC",
  "IndieFlower",
  "KosugiMaru-Regular",
  "Labrada-Regular",
  "LuckiestGuy-Regular",
  "Macondo-Regular",
  "MarckScript-Regular",
  "Pacifico-Regular",
  "PermanentMarker-Regular",
  "PinyonScript-Regular",
  "PlayfairDisplay-Regular",
  "PT Serif Bold",
  "Quicksand-Regular",
  "Qwigley-Regular",
  "ReenieBeanie",
  "RockSalt-Regular",
  "Sacramento-Regular",
  "ShadowsIntoLight",
  "ShantellSans-Light_Regular",
  "Yesteryear-Regular",
  "Zeyada",
  "ZillaSlab-Regular",
];

export const fontNamesAndriod = [
  "badscript_regular",
  "belleza_regular",
  "bubblegumsans_regular",
  "calligraffitti_regular",
  "cinzeldecorative_bold",
  "comingsoon_regular",
  "dancingscript_regular",
  "dosis_medium",
  "faunaone",
  "fugazone_regular",
  "gentiumbookplus_bold",
  "gruppo",
  "im_fell_english_sc",
  "indieflower",
  "kosugimaru_regular",
  "labrada_regular",
  "luckiestguy_regular",
  "macondo_regular",
  "marckscript_regular",
  "pacifico_regular",
  "permanent_marker_regular",
  "pinyonscript_regular",
  "playfairdisplay_regular",
  "pt_serif_bold",
  "quicksand_regular",
  "qwigley_regular",
  "reeniebeanie",
  "rocksalt_regular",
  "sacramento_regular",
  "shadowsintolight",
  "shantellsans_light_regular",
  "yesteryear_regular",
  "zeyada",
  "zillaslab_regular",
];
