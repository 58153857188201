import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Form from "./Form";
import { getFAQ, updateFAQ } from "../../../actions/dashboardActions";

const EditFAQ = (props) => {
  const { id } = useParams();
  const [faqTitle, setFaqTitle] = useState("");
  const [faqContent, setFaqContent] = useState([]);
  const token = localStorage.getItem("jwtToken");

  useEffect(() => {
    props.getFAQ(id, token);
  }, []);

  useEffect(() => {
    setFaqTitle(props.dashboard.singleFAQ);
  }, [props]);

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading">Update FAQ</h3>
        <div className="row">
          <div className="col-md-6 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                faqTitle={faqTitle}
                faqContent={setFaqContent}
                history={props.history}
              />
            </div>
          </div>

          <div className="col-md-6 pr-0 mt_sm_30">
            <div className="personal-details">
              <h5 className="text-center">Selected Locale All Question/Answer</h5>

              {faqContent && faqContent[0]?.faqContent.map((content, index) => {
                return (
                  <>
                    <div key={index} className="mt-3 mb-2">
                      <span key={index +1}>
                        <b>Question :</b> {content?.question} <br />
                        <b>Answer :</b> {content?.answer}<br />
                      </span>
                    </div>

                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

EditFAQ.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getFAQ: PropTypes.func.isRequired,
  updateFAQ: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  singleFAQ: state?.dashboard?.singleFAQ,
});

export default connect(mapStateToProps, { getFAQ, updateFAQ })(memo(EditFAQ));
