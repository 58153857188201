import React from "react";
import { Modal } from "react-bootstrap";

import LazyLoadImage from "../../common/Lazy/Image";
import VienRadio from "../../common/Radio/Radio";
import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import Textarea from "../Textarea/Textarea";
import "./styles.scss";

const UpdateBlog = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.showUpdateBlogModal}
        onHide={props.handleUpdateBlogModalClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleUpdateBlogSubmit}>
            <div className="form-group">
              <LazyLoadImage
                src={props.imagePreviewUrl}
                alt="Blog-image"
                width="40"
              />

              <div className="text-center mx-auto">
                <label className="upload-image" htmlFor="upload-button">
                  Upload Image
                </label>
              </div>

              <input
                id="upload-button"
                name="image"
                type="file"
                accept="image/*"
                onChange={props.handleImageChange}
              />
            </div>

            <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="title"
              placeholder="Title"
              value={props.title}
              handleChange={(e) => props.handleInputChange(e)}
            />
            <Textarea
              icon="fa fa-user icon"
              rows="7"
              size="15px"
              type="text"
              name="description"
              placeholder="Description"
              value={props.description}
              handleChange={(e) => props.handleInputChange(e)}
            />

            <div className="form-group mt-3">
              <label className="status-label">Status</label>
              <VienRadio
                name="status"
                text="Active"
                checked={props.activeBtn}
                handleChange={props.handleRadioChange}
              />
              <VienRadio
                name="status"
                text="Inactive"
                checked={props.inactiveBtn}
                handleChange={props.handleRadioChange}
              />
            </div>

            <Button
              type="submit"
              className="btn submit-btn shadow-none"
              value="Update"
              // className="btn btn-primary btn-block add-doctor-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateBlog;
