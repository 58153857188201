import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "../../common/Button/Button";
import FormInput from "../../common/Form-Input/FormInput";
import FormTextArea from "../../common/Form-TextArea/FormTextArea";
import { validateFAQ } from "../../../validations/cms";
import { addFAQ } from "../../../actions/dashboardActions";
import "./styles.scss";
import { toast } from "react-toastify";

class AddFAQ extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
      faqTitle: "",
      question: "",
      answer: "",
    };
  }

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ answer: data });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioChange = (status) => {
    if (status === "Active") {
      this.setState({ status, activeBtn: true, inactiveBtn: false });
    } else {
      this.setState({ status, activeBtn: false, inactiveBtn: true });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { faqTitle, question, answer } = this.state;

    let validate = validateFAQ(question, answer);
    if (validate) {
      toast.error(validate)
      return;
    }

    let faqData = {
      "faq": [
        {
          "locale": "en",
          "faqTitle": faqTitle,
          "faqContent": [
            {
              "question": question,
              "answer": answer
            },
          ]
        },
        {
          "locale": "fr",
          "faqTitle": "",
          "faqContent": []
        },
        {
          "locale": "de",
          "faqTitle": "",
          "faqContent": []
        }
      ]
    }
    this.props.addFAQ(faqData, this.state.token, this.props.history);
  };

  render() {
    return (
      <React.Fragment>
        <div className="add-faq-wrapper new-faq">
          <h5 className="page_heading new-page-heading">Add FAQ</h5>

          <div className="mt-4">
            <div className="row">
              <div className="col-md-12 pr-0 mt_sm_30">
                <div className="personal-details"></div>
                <form onSubmit={this.handleSubmit}>
                  <div className="col-md-12 pl-0 pr-0">
                    <label className="status-label">FAQ Category</label>
                    <FormInput
                      icon="fa fa-question-circle icon"
                      size="13px"
                      type="text"
                      name="faqTitle"
                      placeholder="Enter the FAQ category"
                      className="form-control shadow-none"
                      value={this.state.faqTitle}
                      handleChange={this.handleInputChange}
                    // handleChange={(e) => props.setVoiceName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <label className="status-label">FAQ Question</label>
                    <FormTextArea
                      icon="fa fa-question-circle icon"
                      size="13px"
                      type="text"
                      name="question"
                      placeholder="Enter the FAQ question"
                      className="form-control shadow-none"
                      value={this.state.question}
                      handleChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <label className="status-label">FAQ Answer</label>
                    <FormTextArea
                      icon="fa fa-question-circle icon"
                      size="13px"
                      type="text"
                      name="answer"
                      placeholder="Enter the FAQ answer"
                      className="form-control shadow-none"
                      value={this.state.answer}
                      handleChange={this.handleInputChange}
                    />
                  </div>

                  <div className="form-group mb-0">
                    <Button
                      value="Add FAQ"
                      className="btn btn-block add-patient-btn-new shadow-none"
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AddFAQ.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addFAQ: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { addFAQ })(AddFAQ);
