import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import VienRadio from "../../common/Radio/Radio";

import Button from "../../common/Button/Button";
import { animated_theme_category, image_url } from "../../../utils/api";
import LazyLoadImage from "../../common/Lazy/Image";
import FormInput from "../../common/Form-Input/FormInput";
import { fontNamesIOS, fontNamesAndriod } from "../../../utils/FontNames";
import FormDropdownTheme from "../../common/FormDropdown/FormDropdownTheme";
import FormSelectDropdown from "../../common/FormSelectDropdown/FormSelectDropdown";

const Form = (props) => {
  const [themeDetails, setThemeDetails] = useState(props.themeDetails);
  const [themeImage, setThemeImage] = useState("");
  const [isPremium, setIsPremium] = useState(themeDetails?.isPremium || false);

  const image =
    props.themeDetails?.themeCategoryId !== animated_theme_category
      ? props.themeDetails?.backgroundImage
      : image_url + "/default.png";

  useEffect(() => {
    // Update props.activeBtn whenever isPremium changes
    props.handleRadioChange(isPremium ? "true" : "false");
  }, [isPremium, props]);

  const handleRadioChange = (status) => {
    setIsPremium(status === "true");
  };

  useEffect(() => {
    setThemeImage(image);
    setThemeDetails(props.themeDetails)
    console.log("log " , props.themeDetails);
  }, []);

  if (!props.themeDetails) {
    return <><p>Loading ..</p></>
  }

  return (
    <React.Fragment>
      <form onSubmit={props.handleThemeUpdate} encType="multipart/form-data">
        <div className="thene-circle">
          <LazyLoadImage
            src={props.smallImagePreviewUrl || themeImage}
            key="largeFileName"
            alt="Blog-image"
            className="image-circle"
          />
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Theme background color</label>
            <FormInput
              icon="fa fa-ticket icon"
              size="13px"
              type="text"
              name="backgroundColor"
              placeholder="Background Color ( #Hex-value )"
              className="form-control shadow-none"
              value={props.backgroundColor || themeDetails.backgroundColor}
              handleChange={(e) => props.setBackgroundColor(e.target.value)}
            />
          </div>
        </div>

        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Theme background opacity</label>
            <FormInput
              icon="fa fa-ticket icon"
              size="13px"
              type="text"
              name="backgroundOpacity"
              placeholder="Background Opacity"
              className="form-control shadow-none"
              value={props.backgroundOpacity || themeDetails.backgroundOpacity}
              handleChange={(e) => props.setBackgroundOpacity(e.target.value)}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Font Name ( ios )</label>
            <FormSelectDropdown
              icon="fa fa-ticket icon"
              size="13px"
              type="text"
              name="andriodFontName"
              placeholder="Font Name ( ios )"
              className="form-control shadow-none"
              value={props.fontName || themeDetails.fontName}
              options={fontNamesIOS}
              handleChange={(e) => props.setFontName(e.target.value)}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Font Name ( andriod )</label>
            <FormSelectDropdown
              icon="fa fa-ticket icon"
              size="13px"
              type="text"
              name="fontName"
              placeholder="Font Name ( andriod )"
              className="form-control shadow-none"
              value={props.andriodFontName || themeDetails.andriodFontName}
              options={fontNamesAndriod}
              handleChange={(e) => props.setAndriodFontName(e.target.value)}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Font Color</label>
            <FormInput
              icon="fa fa-ticket icon"
              size="13px"
              type="text"
              name="fontColor"
              placeholder="Font Color ( #Hex-value )"
              className="form-control shadow-none"
              value={props.fontColor || themeDetails.fontColor}
              handleChange={(e) => props.setFontColor(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group mt-3">
          <label className="status-label">Premium : </label>
          <VienRadio
            name="status"
            text="true"
            checked={isPremium}
            handleChange={handleRadioChange}
          />
          <VienRadio
            name="status"
            text="false"
            checked={!isPremium}
            handleChange={handleRadioChange}
          />
        </div>
        <div className="row mx-0">
          <div className="text-center mx-auto">
            <label
              className="upload-image input-width"
              htmlFor="upload-button1"
            >
              Upload Theme Image
            </label>
          </div>
          <input
            id="upload-button1"
            name="image"
            type="file"
            accept="image/*"
            onChange={props.handleSmallImageChange}
          />
        </div>

        <div className="form-group mb-0">
          <Button
            value="Update Details"
            className="btn btn-primary btn-block update-details-btn shadow-none"
            type="submit"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
