import React, { useState, useEffect } from "react";

import Button from "../../common/Button/Button";
import LazyLoadImage from "../../common/Lazy/Image";
import { new_image_url } from "../../../utils/api";
import FormInput from "../../common/Form-Input/FormInput";

const Form = (props) => {
  const [customerDetails, setCustomerDetails] = useState({});

  useEffect(() => {
    if (props.customerDetails) {
      setCustomerDetails(props.customerDetails[0]);
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="favourites">
        <div className="row">
          <div className="col-md-4">
            <h5 className="text-black">
              <i className="fa fa-lightbulb mr-3"></i>Favourite Affirmation :
            </h5>
          </div>
          <div className="col-md-8">
            <p
              className={
                !customerDetails?.favouriteAffirmation ? "favourited" : ""
              }
            >
              {!customerDetails?.favouriteAffirmation
                ? " No affirmation favourited yet "
                : customerDetails?.favouriteAffirmation?.title}
            </p>
          </div>
          <hr />
          <div className="col-md-4">
            <h5 className="text-black">
              <i className="fa fa-bars mr-3"></i>Favourite Category :
            </h5>
          </div>
          <div className="col-md-8">
            <p
              className={
                !customerDetails?.favouriteCategory ? "favourited" : ""
              }
            >
              {!customerDetails?.favouriteCategory
                ? " No category selected yet "
                : customerDetails?.favouriteCategory?.categoryName}
            </p>
          </div>

          <hr />

          <div className="col-md-4">
            <h5 className="text-black">
              <i className="fa fa-ticket mr-3"></i>Favourite Background :
            </h5>
          </div>
          <div className="col-md-8">
            <p
              className={
                !customerDetails?.favouriteBackground ? "favourited" : ""
              }
            >
              {!customerDetails?.favouriteBackground ? (
                " No background selected yet "
              ) : (
                <LazyLoadImage
                  src={
                    new_image_url + customerDetails?.favouriteBackground?.theme
                  }
                  key="largeFileName"
                  alt="theme-background-image"
                  className="image-circle"
                />
              )}
            </p>
          </div>
        </div>
      </div>
      {/* <form onSubmit={props.handleDetailsUpdate}>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Name</label>
            <FormInput
              icon="fa fa-user icon"
              size="13px"
              type="text"
              name="name"
              className="form-control shadow-none"
              placeholder="Name"
              value={props?.customer?.name ? props?.customer?.name : "No name"}
              handleChange={(e) => props.setName(e.target.value)}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Gender</label>
            <FormInput
              icon="fa fa-user icon"
              size="13px"
              type="text"
              name="gender"
              className="form-control shadow-none"
              placeholder="Gender"
              value={
                props?.customer?.gender
                  ? props?.customer?.gender
                  : "Prefer not to say"
              }
              handleChange={(e) => props.setGender(e.target.value)}
            />
          </div>
        </div>
      </form> */}
    </React.Fragment>
  );
};

export default Form;
