import React, { useState, useCallback, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormInput from "../../common/Form-Input/FormInput";
import FormDropdownChar from "../../common/FormDropdown/FormDropdownChar";
import LazyLoadImage from "../../common/Lazy/Image";

import Button from "../../common/Button/Button";

const Form = (props) => {
  const [selectedCharacter, setSelectedCharacter] = useState();

  const characterType =
    props.characterDetails.characterType === 1
      ? "Female"
      : props.characterDetails.characterType === 2
      ? "Male"
      : "Others";

  const characterTypes = [
    { characterType: "Female", characterIndex: 1 },
    { characterType: "Male", characterIndex: 2 },
    { characterType: "Others", characterIndex: 3 },
  ];

  // useEffect(() => {
  //   props.handleSelectChange({
  //     characterType: selectedCharacter,
  //   });
  // }, [selectedCharacter]);

  const changeCharacterHandler = useCallback(
    (value) => {
      setSelectedCharacter(value);
      props.handleChange(value);
    },
    [selectedCharacter]
  );

  const getSelectedCharacter = () => {
    let res = null;

    if (
      selectedCharacter &&
      characterTypes.findIndex((p) => p?.characterIndex == selectedCharacter) >
        -1
    ) {
      const selectedCharcaters =
        characterTypes[
          characterTypes.findIndex(
            (p) => p?.characterIndex == selectedCharacter
          )
        ];
      res = selectedCharcaters?.characterType;
    }
    return res;
  };

  return (
    <React.Fragment>
      <form
        onSubmit={props.handleUpdateCharacter}
        encType="multipart/form-data"
      >
        <div style={{ position: "relative" }}>
          <FormInput
            icon="fa fa-user-secret icon"
            size="15px"
            type="text"
            name="charcterType"
            placeholder="Character Type"
            value={getSelectedCharacter() || props.characterTypeWord}
            handleChange={(e) => props.handleChange(e.target.value)}
          />
          <FormDropdownChar
            changeCharacterHandler={changeCharacterHandler}
            selectedCharacter={selectedCharacter}
            characterTypes={characterTypes}
          />
        </div>

        <div className="form-group">
          <div className="image-preview">
            <LazyLoadImage
              src={
                props.smallImagePreviewUrl ||
                props.characterDetails.characterImage_sm
              }
              key="smallFileName"
              alt="Blog-image"
              width="60"
            />
          </div>
          <div className="text-center mx-auto">
            <label
              className="upload-image input-width"
              htmlFor="upload-button1"
            >
              Upload Small Character Image
            </label>
          </div>
          <input
            id="upload-button1"
            name="image"
            type="file"
            accept="image/*"
            onChange={props.handleSmallImageChange}
          />
        </div>
        <div className="form-group">
          <LazyLoadImage
            src={
              props.largeImagePreviewUrl ||
              props.characterDetails.characterImage_lg
            }
            key="largeFileName"
            alt="Blog-image"
            width="60"
          />
          <div className="text-center mx-auto">
            <label
              className="upload-image input-width"
              htmlFor="upload-button2"
            >
              Upload Large Character Image
            </label>
          </div>

          <input
            id="upload-button2"
            name="image"
            type="file"
            accept="image/*"
            onChange={props.handleLargeImageChange}
          />
        </div>
        <Button
          type="submit"
          value="Update Character"
          className="btn btn-primary btn-block update-details-btn shadow-none"
        />
      </form>
    </React.Fragment>
  );
};

export default Form;
