import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import FormInput from "../../common/Form-Input/FormInput";
import { adminLogin } from "../../../actions/authActions";
import { validateLogin } from "../../../validations/login";
import "./styles.scss";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("jwtToken"))
      props.history.push("/admin/dashboard");
  }, [props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = validateLogin(email, password);
    if (result) return toast.error(result);

    const admin = {
      email,
      password,
    };

    props.adminLogin(admin, props.history);
  };

  return (
    <React.Fragment>
      <div className="admin-login new-admin-login">
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-md-5 mx-auto">
              <div className="card">
                <div className="card-body">
                  <img
                    className="logo mx-auto d-block"
                    // src="../c-logo.png"
                    src="https://api.appservercentral.com/c-logo.png"
                    alt="logo"
                  />
                  <h5>Admin Login</h5>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      icon="fa fa-envelope icon"
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={email}
                      handleChange={(e) => setEmail(e.target.value)}
                    />

                    <FormInput
                      icon="fa fa-key icon"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      handleChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="form-group mt-3 mb-0 new-forgot text-end">
                      <Link to="/admin/forgot/password">Forgot Password?</Link>
                    </div>

                    <div className="form-group mb-0 mt-4">
                      <input
                        type="submit"
                        value="Login"
                        className="btn btn-dark btn-block login-btn shadow-none"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  adminLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { adminLogin })(withRouter(Login));
