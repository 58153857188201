import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

const FormDropdownChar = (props) => {
  const [characterType, setCharacterType] = useState("");
  const [characterIndex, setCharacterIndex] = useState("");

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Gender
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props?.characterTypes &&
            props?.characterTypes.map((p, index) => (
              <Dropdown.Item
                key={p?.characterIndex}
                onSelect={() => {
                  setCharacterIndex(p?.characterIndex);
                  setCharacterType(p?.characterType);
                  props.changeCharacterHandler(p?.characterIndex);
                }}
                active={
                  props?.selectedCharacter &&
                  p?.characterType === props.selectedCharacter
                }
              >
                {p?.characterType || ""}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownChar;
