import React, { Component } from "react";
import "./styles.scss";
import { getCMS } from "../../../actions/dashboardActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("jwtToken"),
      text: "",
    };
  }

  componentDidMount() {
    this.props.getCMS(this.state.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.about) {
      this.setState({
        text: nextProps.dashboard.cms.about,
      });
    }
  }

  render() {
    return (
      <div className="container-fluid policy">
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h4>About</h4>
            <hr />
          </div>
          <div className="col-md-12 px-0">
            <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
          </div>
        </div>
      </div>
    );
  }
}
About.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getCMS })(About);
