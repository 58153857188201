import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";

import "./styles.scss";

const AddVoice = (props) => {
  return (
    <React.Fragment>
      <Modal className="AddPreference-Modal"
        show={props.showAddVoiceModal}
        onHide={props.handleAddVoiceModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Voice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddVoiceSubmit}>
            <FormInput
              icon="fa fa-microphone icon"
              size="15px"
              type="text"
              name="voiceName"
              placeholder="Voice Name"
              value={props.voiceName}
              handleChange={props.handleChange}
            />
            <FormInput
              icon="fa fa-microphone icon"
              size="15px"
              type="text"
              name="voiceLocale"
              placeholder="Voice Locale"
              value={props.voiceLocale}
              handleChange={props.handleChange}
            />
            <FormInput
              icon="fa fa-microphone icon"
              size="15px"
              type="text"
              name="voiceIdentifier"
              placeholder="Voice Identifier"
              value={props.voiceIdentifier}
              handleChange={props.handleChange}
            />
            <Button
              type="submit"
              value="Add Voice"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddVoice;
