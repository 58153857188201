import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import FormDropdownCat from "./FormDropdownCat";

const FormDropdownTheme = (props) => {
  const [themeCategory, setThemeCategory] = useState("");
  const [themeCategoryId, setThemeCategoryId] = useState("");

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Category
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            height: "300px",
            overflowY: "scroll",
          }}
        >
          {props?.allThemesCategories &&
            props?.allThemesCategories.map((p, index) => (
              <Dropdown.Item
                onSelect={() => {
                  setThemeCategoryId(p?.["_id"]);
                  setThemeCategory(p?.themeCategoryName[0]?.name);
                  props?.changeCatHandler(p?.["_id"]);
                }}
                active={props?.selectedCat && p?.["_id"] === props.selectedCat}
              >
                {p?.themeCategoryName || "No Name"}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownTheme;
