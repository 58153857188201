import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import {
  getManager,
  getAffirmation,
  updateAffirmations,
} from "../../../actions/dashboardActions";

const AffirmationDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [type, setType] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [affirmationTranslation, setAffirmationTranslation] = useState([
    {
      locale: "en",
      name: "",
    },
    {
      locale: "ar",
      name: "",
    },
    {
      locale: "ru",
      name: "",
    },
    {
      locale: "de",
      name: "",
    },
    {
      locale: "fr",
      name: "",
    },
    {
      locale: "es",
      name: "",
    },
    {
      locale: "pt-PT",
      name: "",
    },
  ]);

  // const handleAffirmationChange = (index, newValue) => {
  //   setAffirmationTranslation((prevState) => {
  //     const updatedAffirmation = [...prevState];
  //     updatedAffirmation[index].name = newValue;
  //     return updatedAffirmation;
  //   });
  // };

  const handleAffirmationChange = (index, newValue, fieldType) => {
    if (fieldType === "translation") {
      setAffirmationTranslation((prevState) => {
        const updatedAffirmation = [...prevState];
        updatedAffirmation[index].name = newValue;
        return updatedAffirmation;
      });
    } else if (fieldType === "subCategory") {
      setSubCategory(newValue);
    } else if (fieldType === "type") {
      setType(newValue);
    }
  };

  useEffect(() => {
    props.getAffirmation(id, token);
  }, []);

  useEffect(() => {
    if (
      props.dashboard &&
      props.dashboard.singleAffirmation &&
      props.dashboard.singleAffirmation.title &&
      typeof props.dashboard.singleAffirmation.title === "array"
    ) {
      props.dashboard.singleAffirmation.title.map((titleOj) => {
        if (titleOj.locale === "en")
          handleAffirmationChange(0, titleOj.name, "translation");
        if (titleOj.locale === "ar")
          handleAffirmationChange(1, titleOj.name, "translation");
        if (titleOj.locale === "ru")
          handleAffirmationChange(2, titleOj.name, "translation");
        if (titleOj.locale === "de")
          handleAffirmationChange(3, titleOj.name, "translation");
        if (titleOj.locale === "fr")
          handleAffirmationChange(4, titleOj.name, "translation");
        if (titleOj.locale === "es")
          handleAffirmationChange(5, titleOj.name, "translation");
        if (titleOj.locale === "pt-PT")
          handleAffirmationChange(6, titleOj.name);
      });
    }
  }, [props.dashboard.singleAffirmation]);

  const handleAffirmationUpdate = (e) => {
    e.preventDefault();

    const affirmation = {
      affirmationId: id,
      // subCategory: subCategory,
      // type: type,
      affirmationTitle: affirmationTranslation,
    };

    props.updateAffirmations(affirmation, token);
    props.history.push("/admin/affirmations");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">
          Edit Affirmation Translations
        </h3>
        <div className="row mx-0">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                key={props.dashboard.singleAffirmation._id}
                affirmationDetails={props.dashboard.singleAffirmation}
                handleAffirmationUpdate={handleAffirmationUpdate}
                handleAffirmationChange={handleAffirmationChange}
                affirmationTranslation={affirmationTranslation}
                allSubCategories={props.dashboard.allSubCategories}
                allPreferences={props.dashboard.allPreferences}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                type={type}
                setType={setType}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

AffirmationDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getManager: PropTypes.func.isRequired,
  getAffirmation: PropTypes.func.isRequired,
  updateAffirmations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getManager,
  getAffirmation,
  updateAffirmations,
})(memo(AffirmationDetails));
