import React, { useEffect } from "react";
import moment from "moment";

const Staffs = (props) => {
  const { staff } = props;

  let datePurchased, validTill;

  useEffect(() => {
    console.log("axsxsxsxsx", staff);
  }, []);

  for (let s of staff) {
    if (s?.isSubscribed === true) {
      datePurchased =
        new Date(s?.datePurchased || 1689932407528).toISOString() ||
        new Date().toISOString();
      validTill =
        new Date(s?.datePurchased || 1689934200479).toISOString() ||
        new Date().toISOString();
    }
  }

  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>User Id</th>
            <th>Transaction Id</th>
            <th>Plan</th>
            <th>Date of purchase</th>
            <th>Valid till</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {staff?.map((s, index) => {
            return s?.isSubscribed === true ? (
              <tr>
                <td>{index + 1}</td>
                <td>{s?._id || "-"}</td>
                <td>{s?.transactionId || "-"}</td>
                <td>{"Premium" || "-"}</td>
                <td>
                  {moment(datePurchased).format("DD-MM-YYYY HH:mm:ss") || "-"}
                </td>
                <td>
                  {moment(validTill).format("DD-MM-YYYY HH:mm:ss") || "-"}
                </td>
                <td>
                  <span
                    className={s?.status !== "active" ? "inactive" : "active"}
                  >
                    {s?.status !== "active" ? "Expired" : "Active"}
                  </span>
                </td>
              </tr>
            ) : (
              <tr></tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Staffs;
