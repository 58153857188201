import React, { useEffect } from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
} from "react-pro-sidebar";
import GoSubMenu from "./SubMenu";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainMenuItem from "./MenuItem";
import SubMenuItem from "./SubmenuItem";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../../actions/authActions";
import { getProfile } from "../../../actions/dashboardActions";
import "./styles.scss";

const Sidebar = (props) => {
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    props.getProfile(token);
  }, []);

  const handleLogout = () => {
    props.logoutUser(props.history);
  };

  return (
    <React.Fragment>
      <ProSidebar
        collapsed={props.collapsed}
        toggled={props.toggled}
        breakPoint="md"
        onToggle={props.handleToggle}
      >
        <SidebarHeader>
          {window.innerWidth > 1025 &&
            !props.collapsed &&
            (!props.toggled || props.toggled) && (
              <h1 className="logo">
                <img className="logo" src="/logo.png" alt="logo" />
              </h1>
            )}
          {window.innerWidth <= "1025" && props.toggled && (
            <>
              <h3>
                <img className="logo" src="/logo.png" alt="logo" />
              </h3>
              <i
                onClick={() => props.handleToggle("toggled")}
                className="fa fa-times"
              ></i>
            </>
          )}
        </SidebarHeader>

        <SidebarContent>
          {/* Dashboard */}
          <Menu iconShape="square" popperArrow={true}>
            <MainMenuItem
              icon="tachometer"
              collapsed={props.collapsed}
              name="Dashboard"
              link="/admin/dashboard"
              handleClick={() => props.handleToggle("toggled")}
            />
            {/* <MainMenuItem
              icon="chart-pie"
              collapsed={props.collapsed}
              name="Analytics ( Reports )"
              link="/admin/analytics"
              handleClick={() => props.handleToggle("toggled")}
            /> */}
            <GoSubMenu
              collapsed={props.collapsed}
              name="Analytics ( Reports )"
              icon="chart-pie"
            >
              <SubMenuItem
                name="Users Overview"
                link="/admin/analytics/users"
              />
              <SubMenuItem name="App Revenue" link="/admin/analytics/revenue" />
              <SubMenuItem
                name="Google Ads Revenue"
                link="/admin/google-ads/revenue"
              />
              <SubMenuItem
                name="App Versions"
                link="/admin/analytics/app-versions"
              />
            </GoSubMenu>
            <MainMenuItem
              icon="microphone"
              collapsed={props.collapsed}
              name="Manage Voices"
              link="/admin/voices"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="user-secret"
              collapsed={props.collapsed}
              name="Manage Characters"
              link="/admin/characters"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="fa fa-user-o"
              collapsed={props.collapsed}
              name="Manage Customers"
              link="/admin/customers"
              handleClick={() => props.handleToggle("toggled")}
            />

            <MainMenuItem
              icon="bars"
              collapsed={props.collapsed}
              name="Manage Categories"
              link="/admin/categories"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="users"
              collapsed={props.collapsed}
              name="Manage Sub-Categories"
              link="/admin/sub-categories"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="signal"
              collapsed={props.collapsed}
              name="Manage Preferences"
              link="/admin/preferences"
              handleClick={() => props.handleToggle("toggled")}
            />

            <MainMenuItem
              icon="lightbulb"
              collapsed={props.collapsed}
              name="Manage Affirmations"
              link="/admin/affirmations"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="dollar"
              collapsed={props.collapsed}
              name="Manage Transactions"
              link="/admin/subscription-transactions"
              handleClick={() => props.handleToggle("toggled")}
            />

            <MainMenuItem
              icon="file"
              collapsed={props.collapsed}
              name="Manage Text Contents"
              link="/admin/text-contents"
              handleClick={() => props.handleToggle("toggled")}
            />
            {/* 
            <MainMenuItem
              icon="file"
              collapsed={props.collapsed}
              name="Manage Static Content"
              link="/admin/subscribers"
              handleClick={() => props.handleToggle("toggled")}
            /> */}
            {/* <MainMenuItem
              icon="question-circle"
              collapsed={props.collapsed}
              name="Manage Theme Images"
              link="/admin/unsplash/images"
              handleClick={() => props.handleToggle("toggled")}
            /> */}
            <MainMenuItem
              icon="ticket"
              collapsed={props.collapsed}
              name="Theme Category"
              link="/admin/themes-category"
              handleClick={() => props.handleToggle("toggled")}
            />
            <MainMenuItem
              icon="ticket"
              collapsed={props.collapsed}
              name="Themes & Fonts"
              link="/admin/themes-fonts"
              handleClick={() => props.handleToggle("toggled")}
            />
            {/* Manage FAQ */}
            <GoSubMenu
              collapsed={props.collapsed}
              name="FAQs"
              icon="question-circle"
            >
              <SubMenuItem name="Add FAQ" link="/admin/faq/add" />
              <SubMenuItem name="Manage FAQ" link="/admin/faq/manage" />
            </GoSubMenu>
            {/* <GoSubMenu collapsed={props.collapsed} name="Blogs" icon="rss">
              <SubMenuItem
                name="Add Blog"
                link="/admin/blog/add"
                handleClick={() => props.handleToggle("toggled")}
              />
              <SubMenuItem
                name="Manage Blogs"
                link="/admin/blog/manage"
                handleClick={() => props.handleToggle("toggled")}
              />
            </GoSubMenu> */}
            {/* Manage Static Content */}
            <GoSubMenu
              collapsed={props.collapsed}
              name="Static Content"
              icon="file"
            >
              {/* <SubMenuItem name="About" link="/admin/about" />
              <SubMenuItem name="Announcement" link="/admin/announcement" /> */}
              <SubMenuItem name="Privacy Policy" link="/admin/privacy-policy" />
              <SubMenuItem
                name="Terms & conditions"
                link="/admin/terms-and-conditions"
              />
            </GoSubMenu>
            {/* Profile */}
            <MainMenuItem
              icon="user"
              collapsed={props.collapsed}
              name="Profile"
              link="/admin/profile"
              handleClick={() => props.handleToggle("toggled")}
            />
            {/* Settings */}
            <MainMenuItem
              icon="cog"
              collapsed={props.collapsed}
              name="Settings"
              link="/admin/settings"
              handleClick={() => props.handleToggle("toggled")}
            />
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { logoutUser, getProfile })(
  withRouter(Sidebar)
);
