import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import { Pagination } from "@mui/material";
// import Pagination from "../../common/Pagination/Pagination";
import PaginatedItems from "../../common/NewPagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddCustomerModal from "../../common/Modal/AddCustomer";
import Button from "../../common/Button/Button";
import {
  getAllManagers,
  getAllUsers,
  addCustomer,
  searchCustomer,
  deleteCustomer,
  getFilteredCustomers,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class MangageCustomers extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddCustomerModal: false,
      showSubscriptionModal: false,
      customer: {
        name: "",
        gender: "",
      },
      pageCount: 1,
      query: "",
      showFilter: true,
      selectedFilter: "",
      selectedGender: "",
      pageLimit: 200,
      page: 1,
    };
  }

  componentDidMount = () => {
    this.props.getAllUsers(
      this.state.token,
      this.state.page,
      this.state.pageLimit
    );
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddCustomerModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddCustomerModalClose = () => {
    this.setState({ showAddCustomerModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteCustomer(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllUsers(this.state.token);
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const customer = { ...this.state.customer };
    customer[e.target.name] = e.target.value;
    this.setState({ customer });
  };

  handleAddCustomerSubmit = (e) => {
    e.preventDefault();

    this.props.addCustomer(
      this.state.customer,
      this.state.token,
      this.state.page,
      this.state.pageLimit
    );

    this.setState({ showAddCustomerModal: false });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchCustomer(this.state.token, query);
  };

  handleFilterChange = (event) => {
    const selectedOption = event.target.value;

    this.setState({ selectedFilter: selectedOption }, () => {
      if (selectedOption !== "Gender") {
        this.props.getFilteredCustomers(
          this.state.token,
          selectedOption,
          this.state.selectedGender,
          this.state.page,
          this.state.pageLimit
        );
      } else {
        this.props.getFilteredCustomers(
          this.state.token,
          selectedOption,
          this.state.selectedGender,
          this.state.page,
          this.state.pageLimit
        );
      }
      this.setState({ showFilter: true });
    });
  };

  handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    this.setState({ selectedGender }, () => {
      this.handleFilterChange({ target: { value: "Gender" } });
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      selectedFilter: "",
      selectedGender: "",
    });
  };

  handleSelectChange = (newValue) => {
    const customer = { ...this.state.customer, ...newValue };
    this.setState({ customer });
  };

  handlePageClick = (page) => {
    this.setState({ page });
    this.props.getAllUsers(this.state.token, page, this.state.pageLimit);
  };

  render() {
    const { allUsers } = this.props;

    const profile = this.props.dashboard.adminProfileData;
    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Customers</h5>
          <div className="manage-patient">
            <div
              style={{ display: "flex", justifyContent: "end" }}
              className="row search_row"
            >
              <div className="col-md-5 button_col">
                {/* <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Customer"
                  handleClick={this.addManagerModalClicked}
                /> */}
              </div>
              <div className="col-md-2">
                <div className="filter-form float-right">
                  {this.state.showFilter && (
                    <>
                      <i className="fa fa-filter filter-size"></i>
                      <select
                        className="filter-select"
                        value={this.state.selectedFilter}
                        onChange={this.handleFilterChange}
                      >
                        <option value="" disabled>
                          Select Filter
                        </option>
                        <option value="Gender">Gender</option>
                        <option value="Date">Date</option>
                        <option value="Subscribed">Subscribed</option>
                      </select>

                      {this.state.selectedFilter === "Gender" && (
                        <select
                          className="filter-select"
                          value={this.state.selectedGender}
                          onChange={this.handleGenderChange}
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Prefer not to say">
                            Prefer not to say
                          </option>
                        </select>
                      )}

                      {this.state.selectedFilter && (
                        <i
                          className="fa fa-times remove-filter-icon"
                          onClick={this.handleRemoveFilter}
                        ></i>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Customer"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allUsers={this.props.dashboard.allUsers}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div
                style={{ display: "flex", flexDirection: "row-reverse" }}
                className="col-md-6"
              >
                <Pagination
                  count={Math.ceil(allUsers.length / this.state.pageLimit)}
                  page={this.state.page}
                  variant="outlined"
                  shape="rounded"
                  onChange={this.handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Customer"
          text="Are you sure you want to delete this customer's account?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddCustomerModal
          showAddCustomerModal={this.state.showAddCustomerModal}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          code={this.state.code}
          handleAddCustomerModalClose={this.handleAddCustomerModalClose}
          handleAddCustomerSubmit={this.handleAddCustomerSubmit}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
        />
      </React.Fragment>
    );
  }
}

MangageCustomers.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getFilteredCustomers: PropTypes.func.isRequired,
  addCustomer: PropTypes.func.isRequired,
  searchCustomer: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allUsers: state?.dashboard?.allUsers,
  total: state?.dashboard?.total,
});

export default connect(mapStateToProps, {
  getAllManagers,
  getAllUsers,
  addCustomer,
  searchCustomer,
  deleteCustomer,
  getFilteredCustomers,
  blockOrUnblockManager,
  getAllSubscriptions,
})(MangageCustomers);
