import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import VienRadio from "../../common/Radio/Radio";
import Button from "../../common/Button/Button";
import { image_url } from "../../../utils/api";
import LazyLoadImage from "../../common/Lazy/Image";
import FormInput from "../../common/Form-Input/FormInput";

const Form = (props) => {
  const [subCategoryDetails, setSubCategoryDetails] = useState(
    props.subCategoryDetails
  );
  const [subCategoryImage, setSubCategoryImage] = useState(
    props.subCategoryDetails.subCategoryImage
  );

  const [isPremium, setIsPremium] = useState(
    subCategoryDetails?.isPremium || false
  );

  useEffect(() => {
    // Update props.activeBtn whenever isPremium changes
    props.handleRadioChange(isPremium ? "true" : "false");
  }, [isPremium, props]);

  const handleRadioChange = (status) => {
    setIsPremium(status === "true");
  };

  const handleStateInput = (index, value) => {
    const subCategoryDetailsCopy = JSON.parse(
      JSON.stringify(subCategoryDetails)
    );
    subCategoryDetailsCopy.subCategoryName[index].name = value;
    setSubCategoryDetails(subCategoryDetailsCopy);
  };

  return (
    <React.Fragment>
      <form onSubmit={props.handleSubCategoryUpdate}>
        <div className="thene-circle">
          <LazyLoadImage
            src={props.imagePreviewUrl || subCategoryImage}
            key="largeFileName"
            alt="Blog-image"
            className="image-circle"
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="status-label">English</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="english"
              placeholder="English"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[0]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(0, e.target.value);
                handleStateInput(0, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Arabic</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="arabic"
              placeholder="Arabic"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[1]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(1, e.target.value);
                handleStateInput(1, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Russian</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="russian"
              placeholder="Russian"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[2]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(2, e.target.value);
                handleStateInput(2, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">German</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="german"
              placeholder="German"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[3]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(3, e.target.value);
                handleStateInput(3, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">French</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="french"
              placeholder="French"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[4]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(4, e.target.value);
                handleStateInput(4, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Spanish</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="spanish"
              placeholder="Spanish"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[5]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(5, e.target.value);
                handleStateInput(5, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Portugese</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="portugese"
              placeholder="Portugese"
              className="form-control shadow-none"
              value={subCategoryDetails?.subCategoryName?.[6]?.name}
              handleChange={(e) => {
                props.handleSubCategoryChange(6, e.target.value);
                handleStateInput(6, e.target.value);
              }}
            />
          </div>
          <div>
            <label className="status-label">Background Color</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="backgroundColor"
              placeholder="Background Color ( #000000 )"
              className="form-control shadow-none"
              value={
                props.backgroundColor || subCategoryDetails?.backgroundColor
              }
              handleChange={props.handleColorChange}
            />
          </div>
          <div className="form-group mt-3">
            <label className="status-label">Premium : </label>
            <VienRadio
              name="status"
              text="true"
              checked={isPremium}
              handleChange={handleRadioChange}
            />
            <VienRadio
              name="status"
              text="false"
              checked={!isPremium}
              handleChange={handleRadioChange}
            />
          </div>
          <div className="row mx-0">
            <div className="text-center mx-auto">
              <label
                className="upload-image input-width"
                htmlFor="upload-button1"
              >
                Update Sub-Category Image
              </label>
            </div>
            <input
              id="upload-button1"
              name="image"
              type="file"
              accept="image/*"
              onChange={props.handleSmallImageChange}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="form-group mb-0">
              <Button
                value="Update Sub Category"
                className="btn btn-primary btn-block update-details-btn shadow-none"
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
