import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IS_LOADING,
  FAQ,
  ABOUT,
  HELP,
  TERM,
  ALL_SUBSCRIPTIONS,
  GET_CMS,
  GET_MANAGERS,
  GET_MANAGER,
  BLOG,
  SINGLE_BLOG,
  GET_TRANSACTIONS,
  DASHBOARD_STATS,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_VOICES,
  GET_SINGLE_VOICE,
  GET_USERS,
  GET_CHARACTERS,
  GET_AFFIRMATIONS,
  GET_THEMESANDFONTS,
  FAQS,
  SINGLE_FAQ,
  ADMIN_PROFILE,
  GET_PREFERENCES,
  GET_SINGLE_USER,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_PREFERENCE,
  GET_SINGLE_SUBCATEGORY,
  GET_SINGLE_AFFIRMATION,
  GET_SINGLE_THEME,
  GET_SINGLE_CHARACTER,
  GET_SINGLE_TEXT_CONTENTS,
  GET_TEXT_CONTENTS,
  GET_THEME_CATEGORIES,
  GET_THEME_CATEGORIES_DATA,
  GET_AFFIRMATIONS_TO_EXPORT,
  GET_SINGLE_THEME_CATEGORY
} from "./types";

import { api_url, app_url, app_url_local } from "../utils/api";

// Dashboard

export const dashboardStats = (token) => (dispatch) => {
  axios
    .get(app_url + `/dashboard`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: DASHBOARD_STATS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//

/**
 * ! APIS INTEGRATION
 * @param {*} token
 * @returns
 */

/**
 * * Preferences
 */

export const getAllPreferences = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/preferences`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_PREFERENCES,
          payload: res.data.data?.preferences,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getPreference = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/preference/details?preferenceId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_PREFERENCE, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchPreference = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/preference/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_PREFERENCES,
          payload: res.data.data?.preferences,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addPreference = (preference, token) => (dispatch) => {
  axios
    .post(app_url + `/add/preference`, preference, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllPreferences(token));
      }
    })
    .catch((err) => console.log(err));
};

export const addPreferenceTranslations = (preferences, token) => (dispatch) => {
  axios
    .post(app_url + `/preference/translations`, preferences, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllPreferences(token));
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 409)
        return toast.error(err?.response?.data?.message);
    });
};

export const updatePreferences = (preference, token) => (dispatch) => {
  axios
    .put(app_url + `/preference/translations/update`, preference, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAffirmation(preference?.preferenceId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const deletePreference = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/preference/delete?preferenceId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Text Contents
 */

export const getTextContents = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/text-contents/details?contentId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_TEXT_CONTENTS , payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const updateTextContent = (content, token) => (dispatch) => {
  axios
    .put(app_url + `/text-contents/update`, content, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      console.log("response",res);
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllTextContents(token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteTextContent = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/text-contents/delete?contentId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      console.log("delete response", res);
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

export const getAllTextContents = (token) => (dispatch) => {
  axios
    .get(app_url + `/text-contents/all`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_TEXT_CONTENTS,
          payload: res.data.data.allTextContents,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const searchTextContents = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/text-contents/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_TEXT_CONTENTS,
          payload: res.data.data?.allTextContents,
        });
      }
    })
    .catch((err) => console.log("Error --->", err));
};

export const addNewTextContent = (text, token) => (dispatch) => {
  axios
    .post(
      app_url + `/text-content/add`,
      { text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllTextContents(token));
      }
    })
    .catch((err) => console.log(err));
};

export const updateTextContents = (text, token) => (dispatch) => {
  axios
    .post(app_url + `/text-contents/update`, text, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllTextContents(token));
      }
    })
    .catch((err) => console.log(err));
};

/**
 *  * Voices
 */

export const getAllVoices =
  (token, deviceType = "") =>
  (dispatch) => {
    axios
      .get(app_url + `/all/voices?deviceType=${deviceType}`, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          // sessionStorage.setItem("allCategories", res.data.data);
          dispatch({ type: GET_VOICES, payload: res.data.data.voices });
        }
      })
      .catch((err) => console.log(err));
  };

export const getVoice = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/voice/details?voiceId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_VOICE, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchVoice = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/voice/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_VOICES,
          payload: res.data.data.voices,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addVoice = (voice, token) => (dispatch) => {
  axios
    .post(app_url + `/add/voice`, voice, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllVoices(token));
      }
    })
    .catch((err) => console.log(err));
};

export const updateVoice = (voice, token) => (dispatch) => {
  axios
    .put(app_url + `/voice/edit`, voice, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllVoices(token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteVoice = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/voice/delete?voiceId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Categories
 */

export const getAllCategories = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/categories`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_CATEGORIES, payload: res.data.data?.categories });
      }
    })
    .catch((err) => console.log(err));
};

export const getCategory = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/category/details?categoryId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_CATEGORY, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchCategory = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/category/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_CATEGORIES,
          payload: res.data.data.categories,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addCategory = (category, token) => (dispatch) => {
  axios
    .post(app_url + `/add/category`, category, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllCategories(token));
      }
    })
    .catch((err) => console.log(err));
};

export const addCategoryTranslations = (categories, token) => (dispatch) => {
  axios
    .post(app_url + `/category/translations`, categories, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllCategories(token));
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 409)
        return toast.error(err?.response?.data?.message);
    });
};

export const updateCategories = (category, token) => (dispatch) => {
  axios
    .put(app_url + `/category/translations/update`, category, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getCategory(category?.categoryId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteCategory = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/category/delete?categoryId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => {
      toast.error("Something Went Wrong!!")
      console.log(err)
    });
};

/**
 * ! All the affirmations to export
 * @param {*} token
 * @returns
 */

export const getAllAffirmationsToExport = (token) => (dispatch) => {
  axios
    .get(app_url + `/affirmations-to-export`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_AFFIRMATIONS_TO_EXPORT,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

/**
 * ! Upload a csv file of affirmations
 * @param {*} token
 * @returns
 */

export const uploadCSVFile =
  (token, csvFile, page, pageLimit, updateLoader) => (dispatch) => {
    const formData = new FormData();
    formData.append("csvFile", csvFile);

    axios
      .post(app_url + `/import-affirmations/upload-csv`, formData, {
        headers: {
          "x-access-token": token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success(res.data.message);
          dispatch(getAllAffirmations(token, page, pageLimit));
          updateLoader();
        }
      })
      .catch((err) => {
        if (
          err.response.data.status_code === 400 ||
          err.response.data.status_code === 409
        ) {
          updateLoader();
          return toast.error(err.response.data.message, {
            autoClose: 8000,
          });
        }
      });
  };

/**
 *  * Affirmations
 */

export const getAllAffirmations =
  (token, page = 1, pageLimit) =>
  (dispatch) => {
    axios
      .get(app_url + `/affirmations/all?page=${page}&pageLimit=${pageLimit}`, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          // sessionStorage.setItem("allCategories", res.data.data);
          dispatch({
            type: GET_AFFIRMATIONS,
            payload: res.data.data?.affirmations,
          });
        }
      })
      .catch((err) => console.log(err));
  };

export const getAffirmation = (id, token) => (dispatch) => {
  dispatch({ type: GET_SINGLE_AFFIRMATION, payload: {} });
  axios
    .get(app_url + `/affirmation/details?affirmationId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_AFFIRMATION, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchAffirmations = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/affirmation/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_AFFIRMATIONS,
          payload: res.data.data.affirmations,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addAffirmation = (affirmation, token) => (dispatch) => {
  axios
    .post(app_url + `/add/affirmation`, affirmation, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllAffirmations(token));
      }
    })
    .catch((err) => console.log(err));
};

export const addAffirmationTranslations = (categories, token) => (dispatch) => {
  axios
    .post(app_url + `/affirmation/translations`, categories, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllAffirmations(token));
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 409)
        return toast.error(err?.response?.data?.message);
    });
};

export const updateAffirmations = (affirmation, token) => (dispatch) => {
  axios
    .put(app_url + `/affirmation/translations/update`, affirmation, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAffirmation(affirmation?.affirmationId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteAffirmation = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/affirmation/delete?affirmationId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Sub-categories
 */

export const getAllSubCategories = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/sub-categories`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        // sessionStorage.setItem("allCategories", res.data.data);
        dispatch({
          type: GET_SUBCATEGORIES,
          payload: res.data.data?.subCategories,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getSubcategory = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/sub-category/details?subCategoryId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_SUBCATEGORY, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchSubCategory = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/sub-category/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SUBCATEGORIES,
          payload: res.data.data.subCategories,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addSubCategory = (subCategory, token) => (dispatch) => {
  axios
    .post(app_url + `/add/sub-category`, subCategory, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllCategories(token));
      }
    })
    .catch((err) => console.log("errorsadasd", err));
};

export const addSubCategoryTranslations =
  (subCategories, token) => (dispatch) => {
    axios
      .post(app_url + `/sub-category/translations`, subCategories, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 201) {
          toast.success(res.data.message);
          dispatch(getAllSubCategories(token));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 409)
          return toast.error(err?.response?.data?.message);
      });
  };

export const updateSubCategory = (subCategory, token) => (dispatch) => {
  const subCategoryId = subCategory.get("subCategoryId");
  console.log("Updating Sub-category --->", subCategoryId);

  axios
    .put(app_url + `/sub-category/translations/update`, subCategory, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getSubcategory(subCategoryId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteSubCategory = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/sub-category/delete?subCategoryId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Characters
 */

export const getAllCharacters =
  (token, page = 1, pageLimit = 50) =>
  (dispatch) => {
    axios
      .get(app_url + `/all/characters?page=${page}&pageLimit=${pageLimit}`, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CHARACTERS,
            payload: res.data.data?.characters,
          });
        }
      })
      .catch((err) => console.log(err));
  };

export const getCharacter = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/character/details?characterId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_CHARACTER, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const addCharacter = (character, token) => (dispatch) => {
  axios
    .post(app_url + `/add/character`, character, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllCharacters(token));
      }
    })
    .catch((err) => console.log(err));
};

export const updateCharacter = (character, token) => (dispatch) => {
  axios
    .put(app_url + `/character/edit`, character, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        // dispatch(getCharacter(character.characterId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const searchCharacter = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/character/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_CHARACTERS,
          payload: res.data.data.characters,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const deleteCharacter = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/character/delete?characterId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Customers
 */

export const getAllUsers = (token, page, pageLimit) => (dispatch) => {
  axios
    .get(app_url + `/all/users?page=${page}&pageLimit=${pageLimit}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_USERS, payload: res.data.data?.customers });
      }
    })
    .catch((err) => console.log(err));
};

/**
 * ! Get Filtered Results for Customers
 * @param {*} id
 * @returns
 */

export const getFilteredCustomers =
  (token, filterName, gender = null, page, pageLimit) =>
  (dispatch) => {
    axios
      .get(
        app_url +
          `/customers/filter?page=${page}&pageLimit=${pageLimit}&filterName=${filterName}&gender=${gender}`,
        { headers: { "x-access-token": token } }
      )
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          dispatch({ type: GET_USERS, payload: res.data.data?.customers });
        }
      })
      .catch((err) => console.log(err));
  };

export const getCustomer = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/customer/details?customerId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_USER, payload: res.data?.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchCustomer = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/customer/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_USERS,
          payload: res.data.data.customers,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addCustomer = (customer, token, page, pageLimit) => (dispatch) => {
  axios
    .post(app_url + `/add/customer`, customer, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllUsers(token, page, pageLimit));
      }
    })
    .catch((err) => console.log(err));
};

export const updateCustomer = (customer, token) => (dispatch) => {
  axios
    .put(app_url + `/customer/edit`, customer, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getCustomer(customer?.customerId, token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteCustomer = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/customer/delete?customerId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

/**
 *  * Themes and fonts
 */

export const getAllThemeCategories = (token,lang) => (dispatch) => {
  axios
    .get(app_url + `/all/theme-categories?lang=${lang}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        if (lang == "all") {
          dispatch({ type: GET_THEME_CATEGORIES_DATA, payload: res.data.data });
        }
        else{
          dispatch({ type: GET_THEME_CATEGORIES, payload: res.data.data });
        }
      }
    })
    .catch((err) => console.log(err));
};


export const addNewThemeCategory = (text, token) => (dispatch) => {
  axios
    .post(
      app_url + `/add/theme-category`,
      { text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllThemeCategories(token,'all'));
      }
    })
    .catch((err) => console.log(err));
};


export const getThemeCategory = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/all/theme-categories/${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      console.log("res s", res);
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_THEME_CATEGORY , payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const updateThemeCategory = (content, token) => (dispatch) => 
{
  axios
    .put(app_url + `/update/theme-category`, content, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      console.log("response",res);
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllThemeCategories(token));
      }
    })
    .catch((err) =>{
       dispatch(getAllThemeCategories(token));
       toast.error("Something Went Wrong Please Try later");
       console.log(err)
      });
};

export const deleteThemeCategory = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/delete/theme-category?categoryId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      console.log("delete response", res);
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};


export const searchThemeCategory = (token,query) => (dispatch) => {
  axios
    .get(app_url + `/search/theme-category?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_THEME_CATEGORIES_DATA,
          payload: res.data.data.allThemeCategory,
        });
      }
    })
    .catch((err) => console.log("Error --->", err));
};

export const getAllThemesAndFonts = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/themes`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        // sessionStorage.setItem("allCategories", res.data.data);
        dispatch({ type: GET_THEMESANDFONTS, payload: res.data.data?.themes });
      }
    })
    .catch((err) => console.log(err));
};

export const getTheme = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/theme/details?themeId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: GET_SINGLE_THEME, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const searchThemes = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/theme/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_THEMESANDFONTS,
          payload: res.data.data?.themes,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addNewTheme = (theme, token) => (dispatch) => {
  axios
    .post(app_url + `/add/theme`, theme, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllThemesAndFonts(token));
      }
    })
    .catch((err) => console.log(err));
};

export const updateThemes = (theme, token) => (dispatch) => {
  console.log("get theme data",theme);
  axios
    .put(app_url + `/theme/update`, theme, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllThemesAndFonts(token));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteTheme = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/theme/delete?themeId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

export const getAllFAQS = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/faqs`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: FAQS, payload: res.data.data.faqs });
      }
    })
    .catch((err) => console.log(err));
};

export const getFAQ = (id, token) => (dispatch) => {
  axios
    .get(app_url + `/faq-details?faqId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({ type: SINGLE_FAQ, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const addFAQ = (faq, token, history) => (dispatch) => {
  axios
    .post(app_url + `/add/faq`, faq, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 201) {
        toast.success(res.data.message);
        dispatch(getAllFAQS(token));
        history.push("/admin/faq/manage");
      }
    })
    .catch((err) => console.log(err));
};

export const searchFAQ = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/faq/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: FAQS,
          payload: res.data.data.faqs,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const deleteFAQ = (id, token) => (dispatch) => {
  axios
    .delete(app_url + `/faq/delete?faqId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        dispatch(getAllFAQS(token));
      }
    })
    .catch((err) => console.log(err));
};

export const getAllManagers = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/voices`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        sessionStorage.setItem("all_voices", res.data.total_voices);
        dispatch({ type: GET_VOICES, payload: res.data.all_voices });
      }
    })
    .catch((err) => console.log(err));
};

export const createManager = (token, manager) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/manager/add`, manager, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch(getAllManagers(token, 1, 10));
      }
    })
    .catch((err) => console.log(err));
};

export const searchManagers = (token, query) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/manager/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_MANAGERS,
          payload: res.data.data.managers,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getManager = (token, id) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/manager/get?managerId=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_MANAGER,
          manager: res.data.data.manager,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const blockOrUnblockManager = (token, data) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/manager/block/unblock`, data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch(getAllManagers(token, 1, 10));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteManager = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + "/api/admin/manager/delete",
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) {
        return toast.error(res.data.error);
      }
      if (res.data.success) {
        return toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const updateManager = (manager, token) => (dispatch) => {
  axios
    .post(api_url + "/api/admin/manager/update", manager, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch(getManager(token, manager.id));
      }
    })
    .catch((err) => console.log(err));
};

// CMS ( Static-content )

export const getCMS = (token) => (dispatch) => {
  axios
    .get(app_url + `/cms`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        console.log("MSA CMS --->", res.data.data);
        dispatch({ type: GET_CMS, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

// CMS ( Privacy-policy & Terms and Conditions )

export const addPrivacyPolicy = (text, token) => (dispatch) => {
  axios
    .post(
      app_url + `/cms/privacy-policy/update`,
      { privacyPolicy: text },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
      }
    })
    .catch((err) => console.log(err));
};

export const addTermsAndConditions = (text, token) => (dispatch) => {
  axios
    .post(
      app_url + `/cms/terms&conditions/update`,
      { termsAndConditions: text },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
      }
    })
    .catch((err) => console.log(err));
};

/**
 * ! Transactions and Subscriptions
 * @param {*} token
 * @returns
 */

export const getAllTransactions = (token) => (dispatch) => {
  axios
    .get(app_url + `/all/transactions`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_TRANSACTIONS,
          payload: res.data.data.transactions,
        });
      }
    })
    .catch((err) => console.log(err));
};

/**
 * ! Get Filtered Results for Customers
 * @param {*} id
 * @returns
 */

export const getFilteredTransactions =
  (token, filterName, subFilter, page, pageLimit) => (dispatch) => {
    axios
      .get(
        app_url +
          `/transactions/filtered?page=${page}&pageLimit=${pageLimit}&filter=${filterName}&subFilter=${subFilter}`,
        { headers: { "x-access-token": token } }
      )
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_TRANSACTIONS,
            payload: res.data.data?.transactions,
          });
        }
      })
      .catch((err) => console.log(err));
  };

export const searchSubscriber = (token, query) => (dispatch) => {
  axios
    .get(app_url + `/transactions/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_TRANSACTIONS,
          payload: res.data.data.transactions,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const deleteTransaction = (token, query) => (dispatch) => {
  axios
    .delete(app_url + `/transaction/delete?transactionId=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.message);
      if (res.data.status_code === 200) return toast.success(res.data.message);
    })
    .catch((err) => console.log(err));
};

// Profile

export const getProfile = (token) => (dispatch) => {
  try {
    var decode = jwt_decode(token);
  } catch (error) {
    console.log(error);
  }
  if (decode) {
    axios
      .get(app_url + `/profile`, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({ type: ADMIN_PROFILE, payload: res.data.data });
        }
      })
      .catch((err) => console.log(err));
  }
};

export const submitProfile = (profile, token) => (dispatch) => {
  let decode = jwt_decode(token);
  try {
    dispatch({ type: IS_LOADING, payload: true });
  } catch (error) {
    console.log("Dispatching IS_LOADNG error --->", error);
  }

  profile.append("id", decode.id);
  axios
    .put(app_url + "/profile/update", profile, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.status_code === 200) {
        dispatch({ type: IS_LOADING, payload: false });
        dispatch({ type: ADMIN_PROFILE, payload: res.data.data });
        toast.success("Profile updated");
      }
    })
    .catch((err) => console.log(err));
};

export const changePassword =
  (oldPassword, newPassword, confirmNewPassword, token, history) =>
  (dispatch) => {
    axios
      .post(
        app_url + `/change-password`,
        { oldPassword, newPassword, confirmNewPassword },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((res) => {
        if (res.data.status_code === 400) return toast.error(res.data.message);
        if (res.data.status_code === 200) {
          localStorage.removeItem("jwtToken");
          toast.success(res.data.message);
          history.push("/admin/login");
        }
      })
      .catch((err) => console.log(err));
  };

// FAQ

export const allFAQ = (token, page, limit) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/faq/all?page=${page}&limit=${limit}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FAQ,
          payload: res.data.data.faq,
        });
      }

      sessionStorage.setItem("total_faq", res.data.data.all_faq);
    })
    .catch((err) => console.log(err));
};

export const postFAQ = (text, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/faq/post`, text, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const updateFAQ = (updatedData, token) => {
  axios
    .post(
      api_url + `/update/faq`,updatedData,
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      console.log(res);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
      }
    })
    .catch((err) => console.log(err));
};

// About

export const postAbout = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/about/post`,
      { text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch({
          type: ABOUT,
          payload: res.data.data.about,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getAbout = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/about/get`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: ABOUT,
          payload: res.data.data.about,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addAbout = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/about/update`,
      { about: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

// Help

export const postHelp = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/help/post`,
      { text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch({
          type: HELP,
          payload: res.data.data.help,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getHelp = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/help/get`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: HELP,
          payload: res.data.data.help,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Terms

export const postTerm = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/term/conditions/post`,
      { text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch({
          type: TERM,
          payload: res.data.data.term,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getTerm = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/term/conditions/get`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: TERM,
          payload: res.data.data.term,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addTerms = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/terms/update`,
      { terms: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

// Subscription

export const getAllSubscriptions = (token, page, limit) => (dispatch) => {
  axios
    .get(
      `${api_url}/api/admin/subscriptions/get/all?page=${page}&limit=${limit}`,
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.success) {
        sessionStorage.setItem("total_plans", res.data.data.all_plans);
        dispatch({
          type: ALL_SUBSCRIPTIONS,
          payload: res.data.data.plans,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const addAnnouncement = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/announcement/update`,
      { announcement: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

// Policy

export const addPolicy = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/policy/update`,
      { policy: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

// // CMS

export const allBlog = (token, page, limit) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/blog/all?page=${page}&limit=${limit}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: BLOG,
          payload: res.data.data.blog,
        });
      }
      sessionStorage.setItem("total_blogs", res.data.data.all_blog);
    })
    .catch((err) => console.log(err));
};

export const getBlog = (id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/blog/get?id=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: SINGLE_BLOG,
          payload: res.data.data.blog,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const searchBlog = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/blog/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: BLOG,
          payload: res.data.data.blog,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const postBlog = (data, token) => (dispatch) => {
  dispatch({ type: IS_LOADING, payload: true });
  axios
    .post(api_url + `/api/admin/blog/post`, data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: IS_LOADING, payload: false });
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const updateBlog = (data, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/blog/update`, data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: IS_LOADING, payload: false });
        toast.success(res.data.msg);
        dispatch(allBlog(token, 1, 10));
      }
    })
    .catch((err) => console.log(err));
};

export const deleteBlog = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/blog/delete`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch(allBlog(token, 1, 10));
      }
    })
    .catch((err) => console.log(err));
};
