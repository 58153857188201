import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { api_url } from "../../../utils/api";

const Table = (props) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr no.</th>
            <th>User Id</th>
            <th>User Name</th>
            <th>Product Id</th>
            <th>Transaction Id</th>
            <th>Device</th>
            <th>Gifted</th>
            <th>Date Purchased</th>
            <th>Valid Till</th>
            <th>Status</th>
            <th className="action">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.allTransactions &&
            props.allTransactions.length > 0 &&
            props.allTransactions.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>{p._id}</td>
                <td>{p.userName ? p?.userName : "N/A"}</td>
                <td>{p.productId ? p.productId : "-"}</td>
                <td>
                  {p.originalTransactionId ? p.originalTransactionId : "-"}
                </td>
                <td>{p.deviceType ? p.deviceType : "-"}</td>
                <td>{JSON.stringify(p?.isGift)}</td>
                <td>{moment(p.datePurchased).format("MMM DD, YYYY")}</td>
                <td>{moment(p.validTill).format("MMM DD, YYYY")}</td>
                <td>
                  <span
                    className={p.status === "inactive" ? "inactive" : "active"}
                  >
                    {p.status === "inactive" ? "Expired" : "Active"}
                  </span>
                </td>
                <td>
                  {/* <Link
                    to={`/admin/customer-details/${p._id}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link> */}
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p._id)}
                    ></i>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
