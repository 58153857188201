import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import BasicDetails from "./BasicDetails";
import Staffs from "./Staffs";
import { validateEditManager } from "../../../validations/add-validation";
import {
  getCategory,
  updateCategories,
} from "../../../actions/dashboardActions";

const CategoryDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [categoryTranslation, setCategoryTranslation] = useState([
    {
      locale: "en",
      name: "",
    },
    {
      locale: "ar",
      name: "",
    },
    {
      locale: "ru",
      name: "",
    },
    {
      locale: "de",
      name: "",
    },
    {
      locale: "fr",
      name: "",
    },
    {
      locale: "es",
      name: "",
    },
    {
      locale: "pt-PT",
      name: "",
    },
  ]);

  const handleCategoryChange = (index, newValue) => {
    setCategoryTranslation((prevState) => {
      const updatedCategory = [...prevState];
      updatedCategory[index].name = newValue;
      return updatedCategory;
    });
  };

  useEffect(() => {
    props.getCategory(id, token);
  }, []);

  const handleCategoryUpdate = (e) => {
    e.preventDefault();

    const category = {
      categoryId: id,
      categoryName: categoryTranslation,
    };

    props.updateCategories(category, token);
    props.history.push("/admin/categories");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">
          Edit Category Translations
        </h3>
        <div className="row">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              {/* <h5>Personal Details</h5> */}
              <Form
                key={props.dashboard.singleCategory._id}
                categoryDetails={props.dashboard.singleCategory}
                handleCategoryUpdate={handleCategoryUpdate}
                handleCategoryChange={handleCategoryChange}
                categoryTranslation={categoryTranslation}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CategoryDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCategory: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  singleCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  singleCategory: state?.dashboard?.singleCategory,
});

export default connect(mapStateToProps, { getCategory, updateCategories })(
  memo(CategoryDetails)
);
