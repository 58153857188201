import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";

import "./styles.scss";

const AddPreference = (props) => {
  return (
    <React.Fragment>
      <Modal className="AddPreference-Modal"
        show={props.showAddPreferenceModal}
        onHide={props.handleAddPreferenceModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Preference</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddPreferenceSubmit}>
            <FormInput
              icon="fa fa-microphone icon"
              size="15px"
              type="text"
              name="preference"
              placeholder="Preference"
              value={props.preference}
              handleChange={props.handleChange}
            />
            <Button
              type="submit"
              value="Add Preference"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddPreference;
