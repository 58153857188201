import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";

const Form = (props) => {
  const [preferenceDetails, setPreferenceDetails] = useState(
    props.preferenceDetails
  );

  useEffect(() => {
    console.log("Prefs in form --->", preferenceDetails);
  }, []);

  const handleStateInput = (index, value) => {
    const preferenceDetailsCopy = JSON.parse(JSON.stringify(preferenceDetails));
    preferenceDetailsCopy.preference[index].name = value;
    setPreferenceDetails(preferenceDetailsCopy);
  };

  return (
    <React.Fragment>
      <form onSubmit={props.handlePreferenceUpdate}>
        <div className="row">
          <div className="col-md-6">
            <label className="status-label">English</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="english"
              placeholder="English"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[0]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(0, e.target.value);
                handleStateInput(0, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Arabic</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="arabic"
              placeholder="Arabic"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[1]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(1, e.target.value);
                handleStateInput(1, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Russian</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="russian"
              placeholder="Russian"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[2]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(2, e.target.value);
                handleStateInput(2, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">German</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="german"
              placeholder="German"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[3]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(3, e.target.value);
                handleStateInput(3, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">French</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="french"
              placeholder="French"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[4]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(4, e.target.value);
                handleStateInput(4, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Spanish</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="spanish"
              placeholder="Spanish"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[5]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(5, e.target.value);
                handleStateInput(5, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Portugese</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="portugese"
              placeholder="Portugese"
              className="form-control shadow-none"
              value={preferenceDetails?.preference?.[6]?.name}
              handleChange={(e) => {
                props.handlePreferenceChange(6, e.target.value);
                handleStateInput(6, e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="form-group mb-0">
              <Button
                value="Update Category"
                className="btn btn-primary btn-block update-details-btn shadow-none"
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
