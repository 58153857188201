import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import BasicDetails from "./BasicDetails";
import Staffs from "./Staffs";
import { validateEditVoice } from "../../../validations/add-validation";
import { getVoice, updateVoice } from "../../../actions/dashboardActions";

const VoiceDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");

  const [voiceName, setVoiceName] = useState("");
  const [voiceLocale, setVoiceLocale] = useState("");
  const [voiceIdentifier, setVoiceIdentifier] = useState("");

  useEffect(() => {
    props.getVoice(id, token);
  }, []);

  useEffect(() => {
    if (props.dashboard.singleVoice) {
      setVoiceName(props.dashboard.singleVoice.voiceName);
      setVoiceLocale(props.dashboard.singleVoice.voiceLocale);
      setVoiceIdentifier(props.dashboard.singleVoice.voiceIdentifier);
    }
  }, [props]);

  const handleVoiceUpdate = (e) => {
    e.preventDefault();

    const result = validateEditVoice(voiceName, voiceLocale, voiceIdentifier);
    if (result) return toast.error(result);

    const voice = {
      voiceId: id,
      voiceName,
      voiceLocale,
      voiceIdentifier,
    };

    props.updateVoice(voice, token);
    props.history.push("/admin/voices");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading">Update Voice</h3>
        <div className="row">
          <div className="col-md-6 pr-0 mt_sm_30">
            <div className="personal-details">
              <h5>Voice Details</h5>
              <Form
                voiceName={voiceName}
                voiceLocale={voiceLocale}
                voiceIdentifier={voiceIdentifier}
                setVoiceName={setVoiceName}
                setVoiceLocale={setVoiceLocale}
                setVoiceIdentifier={setVoiceIdentifier}
                handleVoiceUpdate={handleVoiceUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

VoiceDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getVoice: PropTypes.func.isRequired,
  updateVoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getVoice, updateVoice })(
  memo(VoiceDetails)
);
