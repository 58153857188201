import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import FormDropdownCat from "./FormDropdownCat";

const FormDropdownGender = (props) => {
  const [gender, setGender] = useState("");

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Gender
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props?.genders &&
            props?.genders.map((p, index) => (
              <Dropdown.Item
                onSelect={() => {
                  setGender(p?.gender);
                  props?.changeGenderHandler(p?.index);
                }}
                active={
                  props?.selectedGender && p?.gender === props.selectedGender
                }
              >
                {p?.gender || ""}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownGender;
