import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import memoizeOne from "memoize-one";

import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
// import { Pagination } from "@mui/material";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddAffirmationModal from "../../common/Modal/AddAffirmation";
import Button from "../../common/Button/Button";
import { validateAddAffirmation } from "../../../validations/add-validation";
import {
  getAllAffirmations,
  getAllSubCategories,
  getAllPreferences,
  getAllManagers,
  addAffirmation,
  uploadCSVFile,
  addAffirmationTranslations,
  getAllAffirmationsToExport,
  searchAffirmations,
  deleteAffirmation,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";
import { RotatingLines } from "react-loader-spinner";

class ManageAffirmation extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddAffirmationModal: false,
      showSubscriptionModal: false,
      csvImported: true,
      affirmationsLoaded: true,
      affirmation: {
        title: "",
        subCategory: "",
        preferenceId: "",
      },
      affirmations: [],
      query: "",
      csvFile: "",
      headers: [
        { label: "Sr no.", key: "srNo" },
        { label: "Id", key: "id" },
        { label: "English", key: "english" },
        { label: "Arabic", key: "arabic" },
        { label: "Russian", key: "russian" },
        { label: "German", key: "german" },
        { label: "French", key: "french" },
        { label: "Spanish", key: "spanish" },
        { label: "Portugese", key: "portugese" },
        { label: "SubCategory", key: "subCategory" },
        { label: "Preference", key: "preference" },
      ],
      data: [],
      pageLimit: 500,
      currentPage: 1,
    };
  }

  debounceTimer = null;

  affirmationObj = () => ({
    affirmationId: "",
    subCategory: "",
    type: "",
    affirmationNameArr: "",
    affirmationTitle: [
      {
        locale: "ar",
        name: "",
      },
      {
        locale: "ru",
        name: "",
      },
      {
        locale: "de",
        name: "",
      },
      {
        locale: "fr",
        name: "",
      },
      {
        locale: "es",
        name: "",
      },
      {
        locale: "pt-PT",
        name: "",
      },
    ],
  });

  componentDidMount = () => {
    this.setState({ affirmationsLoaded: false });

    // this.props.getAllAffirmations(
    //   this.state.token,
    //   this.state.page,
    //   this.state.pageLimit
    // );

    // After Memoizing

    this.memoizedGetAllAffirmations(
      this.state.token,
      this.state.page,
      this.state.pageLimit
    );

    this.props.getAllSubCategories(this.state.token);
    this.props.getAllPreferences(this.state.token);
    this.props.getAllAffirmationsToExport(this.state.token);
  };

  memoizedGetAllAffirmations = memoizeOne((token, page, pageLimit) => {
    this.props.getAllAffirmations(token, page, pageLimit);
  });

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allAffirmations && nextProps.allAffirmations.length > 0) {
      this.state.data = nextProps?.allAffirmationstoExport?.map(
        (row, index) => {
          const rowData = {
            srNo: index + 1,
            id: row._id,
            subCategory: row.subCategory || "General",
            preference: row.type || "Fundamentals",
          };

          row.title.forEach((title) => {
            switch (title.locale) {
              case "en":
                rowData.english = title.name;
                break;
              case "ar":
                rowData.arabic = title.name || "";
                break;
              case "ru":
                rowData.russian = title.name || "";
                break;
              case "de":
                rowData.german = title.name || "";
                break;
              case "fr":
                rowData.french = title.name || "";
                break;
              case "es":
                rowData.spanish = title.name || "";
                break;
              case "pt-PT":
                rowData.portugese = title.name || "";
                break;
              default:
                break;
            }
          });
          return rowData;
        }
      );

      this.setState({
        affirmations: nextProps.allAffirmations.map((aff) => {
          const copyAffObj = this.affirmationObj();
          copyAffObj.affirmationId = aff._id;
          copyAffObj.affirmationNameArr = [
            ...aff?.title,
            ...copyAffObj.affirmationTitle,
          ];
          copyAffObj.subCategory = aff.subCategory;
          copyAffObj.type = aff.type;

          return copyAffObj;
        }),
      });
    }
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddAffirmationModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddAffirmationModalClose = () => {
    this.setState({ showAddAffirmationModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteAffirmation(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllAffirmations(this.state.token);
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const affirmation = { ...this.state.affirmation };
    affirmation[e.target.name] = e.target.value;
    this.setState({ affirmation });
  };

  handleSelectChange = (newValue) => {
    const affirmation = { ...this.state.affirmation, ...newValue };
    this.setState({ affirmation });
  };

  handleAddAffirmationSubmit = (e) => {
    e.preventDefault();

    const { title, subCategory, preferenceId } = this.state.affirmation;

    const result = validateAddAffirmation(title, subCategory, preferenceId);
    if (result) return toast.error(result);

    this.props.addAffirmation(this.state.affirmation, this.state.token);

    this.setState({ showAddAffirmationModal: false });
  };

  handleAddAffirmationTranslations = (index) => {
    if (this.state.affirmations?.[index]) {
      this.props.addAffirmationTranslations(
        this.state.affirmations?.[index],
        this.state.token
      );

      this.setState({ showAddAffirmationModal: false });
    }
  };

  updateCSVLoader = () => {
    this.setState({ csvImported: true });
  };

  handleCSVUpload = () => {
    const csvFileUpload = new FormData();
    csvFileUpload.append("csvFile", this.state.csvFile);

    this.props.uploadCSVFile(
      this.state.token,
      this.state.csvFile,
      this.state.page,
      this.state.pageLimit,
      this.updateCSVLoader
    );
  };

  handleImportFileChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file && file.type === "text/csv") {
      this.setState({ csvFile: file, csvImported: false }, () => {
        this.handleCSVUpload();
      });
    } else {
      toast.error("Unsupported file type. Only CSV files are allowed.");
    }
  };

  handleSearch = (query) => {
    this.setState({ query });

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.props.searchAffirmations(this.state.token, query);
    }, 2000);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllAffirmations(this.state.token, page, this.state.pageLimit);
  };

  render() {
    const { totalCount } = this.props;

    const handleLangInputChange = (index, langInx, value) => {
      const copyAffirmations = [...this.state.affirmations];
      copyAffirmations[index].affirmationTitle[langInx].name = value;
      this.setState({ affirmations: copyAffirmations });
    };

    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        {this.state.affirmationsLoaded ? (
          <div className="loader">
            <h3>Loading affirmations...</h3>
            <RotatingLines
              strokeColor="#5a5abe"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="manage-patient-wrapper m-pw">
              <div className="row align-items-center mb-4">
                <div className="col-md-6">
                  <h5 className="page_heading new-page-heading">
                    Manage Affirmations
                  </h5>
                </div>
                <div className="col-md-6">
                  <div className="float-right">
                    <label
                      className="upload-image input-width d-inline"
                      htmlFor="upload-button1"
                    >
                      Import <i className="fa fa-upload ml-2"></i>
                    </label>
                    <input
                      id="upload-button1"
                      name="csv"
                      type="file"
                      accept="text/*"
                      onChange={this.handleImportFileChange}
                    />
                    <CSVLink
                      filename={"All_Affirmations.csv"}
                      data={this.state.data}
                      headers={this.state.headers}
                      className="btn"
                      target="_blank"
                    >
                      <button className="btn btn-primary export-btn shadow-none">
                        Export
                        <i className="fa fa-download ml-2"></i>
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </div>

              {!this.state.csvImported && (
                <div className="loader">
                  <h3>Importing...</h3>
                  <RotatingLines
                    strokeColor="#5a5abe"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={!this.state.csvImported}
                  />
                </div>
              )}

              <div className="manage-patient">
                <div className="row search_row">
                  <div className="col-md-7 button_col">
                    <Button
                      type="button"
                      className="btn btn-primary add-new-patient-btn shadow-none"
                      value="Add Affirmation"
                      handleClick={this.addManagerModalClicked}
                    />
                  </div>
                  <div className="col-md-5 search_col">
                    <Search
                      icon="fa fa-search icon"
                      size="15px"
                      placeholder="Search Affirmations"
                      styles={{
                        marginBottom: "20px",
                        fontSize: "15px",
                        paddingLeft: "35px",
                      }}
                      search={this.state.query}
                      handleSearch={this.handleSearch}
                    />
                  </div>
                </div>

                <div className="table-responsive">
                  <Table
                    deleteModalClicked={this.deleteModalClicked}
                    allAffirmations={this.state.affirmations}
                    blockOrUnblockManager={this.blockOrUnblockManager}
                    handleAddAffirmationTranslations={
                      this.handleAddAffirmationTranslations
                    }
                    handleLangInputChange={handleLangInputChange}
                    editSubscription={this.subscriptionModalClicked}
                    data={profile}
                    showSubscription
                  />
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      paddingTop: "1rem",
                    }}
                    className="col-md-6"
                  >
                    {!this.state.query && (
                      <Pagination
                        count={totalCount}
                        pageLimit={this.state.pageLimit}
                        currentPage={this.state.currentPage}
                        handlePageClick={this.handlePageClick}
                      />
                    )}
                    {/* <Pagination
                  className="dog"
                  count={Math.ceil(totalAffirmations / this.state.pageLimit)}
                  variant="outlined"
                  shape="rounded"
                /> */}
                  </div>
                </div>
              </div>
            </div>

            <DeleteModal
              showDeleteModal={this.state.showDeleteModal}
              handleDeleteModalClose={this.handleDeleteModalClose}
              title="Delete Affirmation"
              text="Are you sure you want to delete this affirmation ?"
              handleYesBtnClick={this.handleYesBtnClick}
              handleNoBtnClick={this.handleNoBtnClick}
            />

            <SubscriptionModal
              showDeleteModal={this.state.showSubscriptionModal}
              handleDeleteModalClose={() =>
                this.setState({ showSubscriptionModal: false })
              }
              title="Choose Subscription"
              token={this.state.token}
              userId={this.state.id}
              handleYesBtnClick={this.handleYesBtnClickSubscription}
              handleNoBtnClick={this.handleNoBtnClick}
            />

            <AddAffirmationModal
              showAddAffirmationModal={this.state.showAddAffirmationModal}
              title={this.state.title}
              subCategory={this.state.subCategory}
              preferenceId={this.state.preferenceId}
              allSubCategories={this.props.dashboard.allSubCategories}
              allPreferences={this.props.dashboard.allPreferences}
              handleAddAffirmationModalClose={
                this.handleAddAffirmationModalClose
              }
              handleAddAffirmationSubmit={this.handleAddAffirmationSubmit}
              handleAddAffirmationTranslations={
                this.handleAddAffirmationTranslations
              }
              handleChange={this.handleChange}
              handleSelectChange={this.handleSelectChange}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

ManageAffirmation.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllAffirmations: PropTypes.func.isRequired,
  getAllAffirmationsToExport: PropTypes.func.isRequired,
  getAllSubCategories: PropTypes.func.isRequired,
  addAffirmation: PropTypes.func.isRequired,
  uploadCSVFile: PropTypes.func.isRequired,
  addAffirmationTranslations: PropTypes.func.isRequired,
  searchAffirmations: PropTypes.func.isRequired,
  deleteAffirmation: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allAffirmations: state?.dashboard?.allAffirmations,
    allSubCategories: state?.dashboard?.allSubCategories,
    allPreferences: state?.dashboard?.allPreferences,
    totalCount: state.dashboard.dashboardStats.allAffirmations,
    allAffirmationstoExport: state.dashboard.allAffirmationstoExport,
  }
);

export default connect(mapStateToProps, {
  getAllManagers,
  getAllAffirmations,
  getAllSubCategories,
  getAllPreferences,
  addAffirmation,
  uploadCSVFile,
  addAffirmationTranslations,
  searchAffirmations,
  deleteAffirmation,
  blockOrUnblockManager,
  getAllSubscriptions,
  getAllAffirmationsToExport,
})(ManageAffirmation);
