import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/scss/bootstrap.scss";
import "./App.scss";
import "./Custom.css";

import store from "./store";
import { RouteWrapper } from "./components/startup/RouteWrapper";
import AddBlog from "./components/panel/Blog/AddBlog";
import ManageBlog from "./components/panel/Blog/ManageBlog";
import Login from "./components/auth/Login/Login";
import ForgotPassword from "./components/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword/ResetPassword";
import Admin from "./components/layouts/Admin";
import Dashboard from "./components/panel/Dashboard/Dashboard";
import ManageVoices from "./components/panel/Voices/ManageVoices";
import VoiceDetails from "./components/panel/Voices/VoiceDetails";
import CharcterDetails from "./components/panel/Characters/CharcterDetails";
import CustomerDetails from "./components/panel/Customers/CustomerDetails";
import CategoryDetails from "./components/panel/Categories/CategoryDetails";
import PreferenceDetails from "./components/panel/Preferences/PreferenceDetails";
import SubCategoryDetails from "./components/panel/SubCategories/SubCategoryDetails";
import AffirmationDetails from "./components/panel/Affirmations/AffirmationDetails";
import ThemeDetails from "./components/panel/ThemesAndFonts/ThemeDetails";

import ManagePreferences from "./components/panel/Preferences/ManagePreference";
import ManageCharacters from "./components/panel/Characters/ManageCharacters";
import ManageCustomers from "./components/panel/Customers/ManageCustomers";
import ManageCategories from "./components/panel/Categories/ManageCategories";
import ManageTextContent from "./components/panel/Text Content/ManageTextContent";
import TextContentDetails from "./components/panel/Text Content/ContentDetails";
import ManageSubCategories from "./components/panel/SubCategories/ManageSubCategories";
import ManageAffirmation from "./components/panel/Affirmations/ManageAffirmations";
import ManageThemesAndFonts from "./components/panel/ThemesAndFonts/ManageThemes&Fonts";
import ManageThemesCategory from "./components/panel/ThemesCategory/ManageThemesCategory";
import ManageThemesCategoryDetails from "./components/panel/ThemesCategory/ContentDetails";
import ManageUnsplashImages from "./components/panel/Unsplash Images/ManageUnsplashImages.js";
import ManageTransactions from "./components/panel/Transactions/ManageTransactions";
import Profile from "./components/panel/Profile/Profile";
import AddFAQ from "./components/panel/FAQ/AddFAQ";
import ManageFAQ from "./components/panel/FAQ/ManageFAQ";
import Settings from "./components/panel/Settings/Settings";
import VerifyEmail from "./components/panel/VerifyEmail/VerifyEmail";
import About from "./components/panel/CMS/About";
import Policy from "./components/panel/CMS/Policy";
import UpdateAbout from "./components/panel/CMS/About";
import Announcement from "./components/panel/CMS/Announcement";
import Terms from "./components/panel/CMS/Terms-and-condition";
import TermsCondition from "./components/panel/Terms/Terms";
import PrivacyPolicy from "./components/panel/Policy/Policy";
import AboutUs from "./components/panel/About/About";
import EditFAQ from "./components/panel/FAQ/EditFAQ";
import UsersReport from "./components/panel/Analytics/UserReport";
import AppRevenue from "./components/panel/Analytics/AppRevenue";
import AdsRevenue from "./components/panel/Analytics/AdsRevenue";
import AppVersions from "./components/panel/Analytics/AppVersions";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            transition={Slide}
          />
          <Switch>
            <Route exact path="/verify/email/:id" component={VerifyEmail} />
            <Route exact path="/admin/login" component={Login} />
            <Route
              exact
              path="/admin/forgot/password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/admin/reset/password"
              component={ResetPassword}
            />
            <Route exact path="/terms&conditions" component={TermsCondition} />
            <Route exact path="/about" component={AboutUs} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <RouteWrapper
              exact
              path="/"
              component={ManageVoices}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/dashboard"
              component={Dashboard}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/analytics/users"
              component={UsersReport}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/analytics/revenue"
              component={AppRevenue}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/google-ads/revenue"
              component={AdsRevenue}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/analytics/app-versions"
              component={AppVersions}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/voices"
              component={ManageVoices}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/characters"
              component={ManageCharacters}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/customers"
              component={ManageCustomers}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/preferences"
              component={ManagePreferences}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/categories"
              component={ManageCategories}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/text-contents"
              component={ManageTextContent}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/text-contents-details/:id"
              component={TextContentDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/sub-categories"
              component={ManageSubCategories}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/voice-details/:id"
              component={VoiceDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/character-details/:id"
              component={CharcterDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/customer-details/:id"
              component={CustomerDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/category-details/:id"
              component={CategoryDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/preference-details/:id"
              component={PreferenceDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/sub-category/details/:id"
              component={SubCategoryDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/affirmation/details/:id"
              component={AffirmationDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/theme-details/:id"
              component={ThemeDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/unsplash/images"
              component={ManageUnsplashImages}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/themes-category"
              component={ManageThemesCategory}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/theme-category-details/:id"
              component={ManageThemesCategoryDetails}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/themes-fonts"
              component={ManageThemesAndFonts}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/blog/add"
              component={AddBlog}
              layout={Admin}
            />
            <RouteWrapper
              path="/admin/blog/manage"
              component={ManageBlog}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/faq/add"
              component={AddFAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/faq/manage"
              component={ManageFAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/faq-details/:id"
              component={EditFAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/about"
              component={UpdateAbout}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/announcement"
              component={Announcement}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/about"
              component={About}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/privacy-policy"
              component={Policy}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/terms-and-conditions"
              component={Terms}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/profile"
              component={Profile}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/settings"
              component={Settings}
              layout={Admin}
            />

            <RouteWrapper
              exact
              path="/admin/affirmations"
              component={ManageAffirmation}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/subscription-transactions"
              component={ManageTransactions}
              layout={Admin}
            />
          </Switch>
        </React.Fragment>
      </Router>
    </Provider>
  );
}

export default App;
