import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "../../common/Button/Button";
import { addAnnouncement, getCMS } from "../../../actions/dashboardActions";
import TextArea from "../../common/Textarea/Textarea";
import "./styles.scss";

class Announcement extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
      text: "",
    };
  }

  componentDidMount() {
    this.props.getCMS(this.state.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.announcement) {
      this.setState({
        text: nextProps.dashboard.cms.announcement,
      });
    }
  }

  handleChange = (value) => {
    this.setState({ text: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addAnnouncement(this.state.text, this.state.token);
  };

  render() {
    return (
      <React.Fragment>
        <div className="add-about-wrapper">
          <h5 className="page_heading">Announcement</h5>
          <hr />
          <div className="mt-4">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.handleSubmit}>
                  <TextArea
                    icon="fa fa-exclamation icon"
                    name="text"
                    placeholder="Announcement"
                    value={this.state.text}
                    handleChange={(event) =>
                      this.handleChange(event.target.value)
                    }
                    readOnly={false}
                    rows={15}
                  />

                  <Button
                    type="submit"
                    className="btn submit-btn shadow-none"
                    value="Update"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Announcement.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addAnnouncement: PropTypes.func.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { addAnnouncement, getCMS })(
  Announcement
);
