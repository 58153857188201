import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import { Pagination } from "@mui/material";
import Search from "../../common/Search/Search";
import Button from "../../common/Button/Button";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddSubCategoryModal from "../../common/Modal/AddSubCategory";
import { validateAddSubCategory } from "../../../validations/add-validation";
import {
  getAllManagers,
  getAllCategories,
  getAllSubCategories,
  addSubCategory,
  searchSubCategory,
  deleteSubCategory,
  getAllSubscriptions,
  blockOrUnblockManager,
  addSubCategoryTranslations,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManageSubCategory extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddSubCategoryModal: false,
      showSubscriptionModal: false,
      subCategory: {
        categoryId: "",
        subCategoryName: "",
        isPremium: true,
        subCategoryImage: "",
        backgroundColor: "",
      },
      imagePreview: "",
      activeBtn: true,
      inactiveBtn: false,
      subCategories: [],
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  subCategoryObj = () => ({
    subCategoryId: "",
    subCategoryArr: "",
    category: "",
    subCategoryName: [
      {
        locale: "ar",
        name: "",
      },
      {
        locale: "ru",
        name: "",
      },
      {
        locale: "de",
        name: "",
      },
      {
        locale: "fr",
        name: "",
      },
      {
        locale: "es",
        name: "",
      },
      {
        locale: "pt-PT",
        name: "",
      },
    ],
  });

  componentDidMount = () => {
    this.props.getAllSubCategories(this.state.token);
    this.props.getAllCategories(this.state.token);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allSubCategories && nextProps.allSubCategories.length > 0) {
      this.setState({
        subCategories: nextProps.allSubCategories.map((cat) => {
          const copySubCatObj = this.subCategoryObj();
          copySubCatObj.subCategoryId = cat._id;
          copySubCatObj.category = cat.category;
          copySubCatObj.subCategoryArr = [
            ...cat.subCategoryName,
            copySubCatObj.subCategoryName,
          ];

          return copySubCatObj;
        }),
      });
    }
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let subCategory = { ...this.state.subCategory };
      subCategory.subCategoryImage = file;
      this.setState({
        subCategory,
        imagePreview: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleRadioChange = (status) => {
    const subCategory = { ...this.state.subCategory };

    if (status === "true") {
      subCategory.isPremium = true;
      this.setState({
        activeBtn: true,
        inactiveBtn: false,
      });
    } else {
      subCategory.isPremium = false;
      this.setState({
        activeBtn: false,
        inactiveBtn: true,
      });
    }
    this.setState({ subCategory });
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  subCategoryModalClicked = () => {
    this.setState({ showAddSubCategoryModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddSubCategoryModalClose = () => {
    this.setState({ showAddSubCategoryModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteSubCategory(this.state.id, this.state.token);
    this.props.getAllSubCategories(this.state.token);
    this.setState({ showDeleteModal: false });
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const subCategory = { ...this.state.subCategory };
    subCategory[e.target.name] = e.target.value;
    this.setState({ subCategory });
  };

  handleSelectChange = (newValue) => {
    const subCategory = { ...this.state.subCategory, ...newValue };
    this.setState({ subCategory });
  };

  handleCategoryChange = (e) => {
    const subCategoryName = { ...this.state.subCategories };
    subCategoryName[e.target.name] = e.target.value;
    this.setState({ subCategoryName });
  };

  handleAddSubCategorySubmit = (e) => {
    e.preventDefault();

    const {
      isPremium,
      categoryId,
      subCategoryName,
      backgroundColor,
      subCategoryImage,
    } = this.state.subCategory;

    const allCategoriesIds = this.props.dashboard.allCategories?.map(
      (item) => item._id
    );

    const result = validateAddSubCategory(
      categoryId,
      subCategoryName,
      backgroundColor,
      subCategoryImage,
      allCategoriesIds
    );

    if (result) return toast.error(result);

    const subCategory = new FormData();
    subCategory.append("isPremium", isPremium);
    subCategory.append("categoryId", categoryId);
    subCategory.append("subCategoryName", subCategoryName);
    subCategory.append("backgroundColor", backgroundColor);
    subCategory.append("subCategoryImage", subCategoryImage);

    this.props.addSubCategory(subCategory, this.state.token);

    this.setState({ showAddSubCategoryModal: false });
  };

  handleAddSubCategoryTranslationSubmit = (index) => {
    if (this.state.subCategories?.[index]) {
      this.props.addSubCategoryTranslations(
        this.state.subCategories?.[index],
        this.state.token
      );

      this.setState({ showAddCategoryModal: false });
    }
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchSubCategory(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllSubCategories(
      this.state.token,
      page,
      this.state.pageLimit
    );
  };

  render() {
    const profile = this.props.dashboard.adminProfileData;

    const handleLangInputChange = (index, langInx, value) => {
      const copyCategories = [...this.state.subCategories];
      copyCategories[index].subCategoryName[langInx].name = value;
      this.setState({ subCategories: copyCategories });
    };
    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">
            Manage Sub Categories
          </h5>
          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Sub Category"
                  handleClick={this.subCategoryModalClicked}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Sub-Category"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                // allSubCategories={this.props.dashboard.allSubCategories}
                allSubCategories={this.state.subCategories}
                handleLangInputChange={handleLangInputChange}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                handleAddSubCategoryTranslationSubmit={
                  this.handleAddSubCategoryTranslationSubmit
                }
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingTop: "1rem",
                }}
                className="col-md-6"
              >
                {/* <Pagination
                  count={Math.ceil(totalSubCategories / this.state.pageLimit)}
                  variant="outlined"
                  shape="rounded"
                /> */}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete sub-category"
          text="Are you sure you want to delete this sub-category?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddSubCategoryModal
          showAddSubCategoryModal={this.state.showAddSubCategoryModal}
          categoryId={this.state.subCategory.categoryId}
          subCategoryName={this.state.subCategory.subCategoryName}
          isPremium={this.state.subCategory.isPremium}
          subCategoryImage={this.state.subCategory.subCategoryImage}
          backgroundColor={this.state.subCategory.backgroundColor}
          handleAddSubCategoryModalClose={this.handleAddSubCategoryModalClose}
          allCategories={this.props.dashboard.allCategories}
          allSubCategories={this.props.dashboard.allSubCategories}
          handleSelectChange={this.handleSelectChange}
          imagePreview={this.state.imagePreview}
          activeBtn={this.state.activeBtn}
          inactiveBtn={this.state.inactiveBtn}
          handleChange={this.handleChange}
          handleImageChange={this.handleImageChange}
          handleRadioChange={this.handleRadioChange}
          handleAddSubCategorySubmit={this.handleAddSubCategorySubmit}
        />
      </React.Fragment>
    );
  }
}

ManageSubCategory.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllManagers: PropTypes.func.isRequired,
  getAllSubCategories: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  addSubCategory: PropTypes.func.isRequired,
  addSubCategoryTranslations: PropTypes.func.isRequired,
  searchSubCategory: PropTypes.func.isRequired,
  deleteSubCategory: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allSubCategories: state?.dashboard?.allSubCategories,
    // allCategories: state?.dashboard?.allCategories,
  }
);

export default connect(mapStateToProps, {
  getAllManagers,
  getAllSubCategories,
  getAllCategories,
  addSubCategoryTranslations,
  addSubCategory,
  searchSubCategory,
  deleteSubCategory,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageSubCategory);
