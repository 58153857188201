import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import BasicDetails from "./BasicDetails";
import Staffs from "./Staffs";
import { validateEditManager } from "../../../validations/add-validation";
import { getCustomer, updateCustomer } from "../../../actions/dashboardActions";

const CustomerDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [favouriteTheme, setFavouriteTheme] = useState("");
  const [favouriteCategory, setFavouriteCategory] = useState("");
  const [favouriteAffirmation, setFavouriteAffirmation] = useState("");

  useEffect(() => {
    props.getCustomer(id, token);
  }, []);

  useEffect(() => {
    if (props.dashboard.singleUser) {
      console.log("CUSTOMER DETAILS --->", props.dashboard.singleUser);
    }
  }, []);

  const handleDetailsUpdate = (e) => {
    e.preventDefault();

    // const result = validateEditManager(firstName, lastName, email, phone);
    // if (result) return toast.error(result);

    const customer = {
      customerId: id,
      name,
      gender,
    };

    props.updateCustomer(customer, token);
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">Customer Details</h3>

        <div className="row mx-0">
          <div className="col-md-4 pl-0">
            <div className="basic-details py-5">
              <BasicDetails
                details={
                  props.dashboard.singleUser ? props.dashboard.singleUser : ""
                }
              />
            </div>
          </div>
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details p-4">
              <h5 className="mb-4">Customer's Favourite :</h5>
              <Form
                favouriteTheme={favouriteTheme}
                favouriteCategory={favouriteCategory}
                favouriteAffirmation={favouriteAffirmation}
                handleDetailsUpdate={handleDetailsUpdate}
                customerDetails={props.dashboard.singleUser}
              />
            </div>
          </div>
        </div>

        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <div className="ratings">
              <h5>Subscription History</h5>
              <div className="table-responsive">
                <Staffs staff={props.dashboard.singleUser} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CustomerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getCustomer, updateCustomer })(
  memo(CustomerDetails)
);
