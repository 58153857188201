import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import VienRadio from "../../common/Radio/Radio";
import Button from "../../common/Button/Button";
import FormInput from "../../common/Form-Input/FormInput";
import Textarea from "../../common/Textarea/Textarea";
import LazyLoadImage from "../../common/Lazy/Image";
import { postBlog } from "../../../actions/dashboardActions";
import { app_url } from "../../../utils/api";
import "./styles.scss";

class AddBlog extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      title: "",
      description: "",
      image: "",
      status: "Active",
      image: "",
      imagePreviewUrl: "",
      activeBtn: true,
      inactiveBtn: false,
      defaultImg: app_url + "/image.png",
    };
  }

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ answer: data });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioChange = (status) => {
    if (status === "Active") {
      this.setState({ status, activeBtn: true, inactiveBtn: false });
    } else {
      this.setState({ status, activeBtn: false, inactiveBtn: true });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const blog = new FormData();
    blog.append("image", this.state.image);
    blog.append("title", this.state.title);
    blog.append("description", this.state.description);
    blog.append("status", this.state.status);

    this.props.postBlog(blog, this.state.token);
  };

  render() {
    return (
      <React.Fragment>
        <div className="add-faq-wrapper">
          <h5>Add Blog</h5>

          <hr />

          <div className="mt-4">
            <div className="row">
              <div className="col-md-12">
                <form
                  onSubmit={this.handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="form-group">
                    <div className="uploadImg">
                      <LazyLoadImage
                        src={
                          this.state.imagePreviewUrl || this.state.defaultImg
                        }
                        alt="Blog-image"
                      />
                    </div>

                    <div className="text-center mx-auto mt-3">
                      <label
                        className="upload-image mt-0"
                        htmlFor="upload-button"
                      >
                        Upload Image
                      </label>
                    </div>

                    <input
                      id="upload-button"
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={this.handleImageChange}
                    />
                  </div>

                  <FormInput
                    icon="fa fa-user icon"
                    size="15px"
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={this.state.title}
                    handleChange={this.handleInputChange}
                  />

                  <Textarea
                    rows={6}
                    icon="fa fa-user icon"
                    size="15px"
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={this.state.description}
                    handleChange={this.handleInputChange}
                  />

                  <div className="form-group mt-3">
                    <label className="status-label">Status</label>
                    <VienRadio
                      name="status"
                      text="Active"
                      checked={this.state.activeBtn}
                      handleChange={this.handleRadioChange}
                    />
                    <VienRadio
                      name="status"
                      text="Inactive"
                      checked={this.state.inactiveBtn}
                      handleChange={this.handleRadioChange}
                    />
                  </div>

                  <Button
                    type="submit"
                    className="btn submit-btn shadow-none mt-0"
                    value="Add"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AddBlog.propTypes = {
  dashboard: PropTypes.object.isRequired,
  postBlog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { postBlog })(AddBlog);
