import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddCharacterModal from "../../common/Modal/AddCharacter.js";
import Button from "../../common/Button/Button";
import { validateAddCharacter } from "../../../validations/add-validation";
import {
  addCharacter,
  deleteCharacter,
  searchCharacter,
  getAllCharacters,
  getAllSubscriptions,
  blockOrUnblockManager,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManageCharacters extends Component {
  constructor() {
    super();

    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddCharacterModal: false,
      showSubscriptionModal: false,
      manager: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        code: "",
      },
      characterType: "1",
      characterImage_sm: "",
      characterImage_lg: "",
      smallImagePreviewUrl: "",
      largeImagePreviewUrl: "",
      image: "",
      query: "",
      pageLimit: 50,
      page: 1,
    };
  }

  componentDidMount = () => {
    const token = localStorage.getItem("jwtToken");
    this.props.getAllCharacters(token, this.state.page, this.state.pageLimit);
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddCharacterModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddCharacterModalClose = () => {
    this.setState({ showAddCharacterModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    const token = localStorage.getItem("jwtToken");
    this.setState({ showDeleteModal: false });
    this.props.deleteCharacter(this.state.id, token);
    this.props.getAllCharacters(token, this.state.page, this.state.pageLimit);
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (value) => {
    this.setState({ characterType: value });
  };

  handleAddCharacterSubmit = (e) => {
    e.preventDefault();

    const { characterType, characterImage_lg, characterImage_sm } = this.state;

    const result = validateAddCharacter(
      characterType,
      characterImage_sm,
      characterImage_lg
    );
    if (result) return toast.error(result);

    const character = new FormData();
    character.append("characterType", characterType);
    character.append("characterImage_sm", characterImage_sm);
    character.append("characterImage_lg", characterImage_lg);

    this.props.addCharacter(character, this.state.token);

    this.setState({ showAddCharacterModal: false });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchCharacter(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ page });
    this.props.getAllCharacters(
      this.state.token,
      this.state.page,
      this.state.pageLimit
    );
  };

  handleSmallImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        characterImage_sm: file,
        smallImagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleLargeImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        characterImage_lg: file,
        largeImagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  handleSelectChange = (newValue) => {
    const character = { ...this.state.character, ...newValue };
    this.setState({ character });
  };

  render() {
    const { allCharacters } = this.props;
    const totalCharacters = allCharacters.length;
    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Characters</h5>

          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Character"
                  handleClick={this.addManagerModalClicked}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Characters"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allCharacters={this.props.dashboard.allCharacters}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={totalCharacters}
                    pageLimit={this.state.pageLimit}
                    page={this.state.page}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Character"
          text="Are you sure you want to delete this character ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddCharacterModal
          showAddCharacterModal={this.state.showAddCharacterModal}
          handleAddCharacterModalClose={this.handleAddCharacterModalClose}
          handleSmallImageChange={this.handleSmallImageChange}
          smallImagePreviewUrl={this.state.smallImagePreviewUrl}
          handleLargeImageChange={this.handleLargeImageChange}
          largeImagePreviewUrl={this.state.largeImagePreviewUrl}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          handleAddCharacterSubmit={this.handleAddCharacterSubmit}
        />
      </React.Fragment>
    );
  }
}

ManageCharacters.propTypes = {
  dashboard: PropTypes.object.isRequired,
  allCharacters: PropTypes.func.isRequired,
  deleteCharacter: PropTypes.func.isRequired,
  addCharacter: PropTypes.func.isRequired,
  searchCharacter: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allCharacters: state?.dashboard?.allCharacters,
  }
);

export default connect(mapStateToProps, {
  getAllCharacters,
  deleteCharacter,
  addCharacter,
  searchCharacter,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageCharacters);
