import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import "./styles.scss";

const AddTextContent = (props) => {
  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showAddCategoryModal}
        onHide={props.handleAddCategoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Text Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddTextContent}>
            <FormInput
              icon="fa fa-file icon"
              size="15px"
              type="text"
              name="textContent"
              placeholder="Text Content"
              value={props.textContent}
              handleChange={props.handleChange}
            />
            <Button
              type="submit"
              value="Add Text Content"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddTextContent;
