import React from "react";
import { Link } from "react-router-dom";

const Table = (props) => {
  console.log("Text Contents", props);

  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th className="action">Action</th>
            <th>English(en) </th>
            <th>Spanish (es)</th>
            <th>Arabic (ar)</th>
            <th>French (fr)</th>
            <th>Russian (ru)</th>
            <th>Portugese (pt-PT)</th>
            <th>German (de)</th>
            <th className="translation">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.allThemesCategoriesData &&
            props.allThemesCategoriesData.length > 0 &&
            props.allThemesCategoriesData.map((p, index) => (
              <tr key={p?._id}>
                <td>{index + 1}</td>
                <td>
                  <Link to={`/admin/theme-category-details/${p?._id}`}>
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>

                  <Link to="#">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p?._id)}
                    ></i>
                  </Link>
                </td>
                <td>
                  <textarea
                    type="text"
                    placeholder="English"
                    value={p?.themeCategory[0]?.name}
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[1]?.name}
                    placeholder="Spanish"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[2]?.name}
                    placeholder="Arabic"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[3]?.name}
                    placeholder="French"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[4]?.name}
                    placeholder="Russian"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[5]?.name}
                    placeholder="Portugese"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.themeCategory[6]?.name}
                    placeholder="German"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <span className={p?.status === "active" ? "active" : "suspended"}>
                    {p?.status === "active" ? "Active" : "Inactive"}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
