import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { api_url } from "../../../utils/api";

const Table = (props) => {
  return (
    <React.Fragment>
      <table className="table text-nowrap">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th className="action">Action</th>
            <th>English(en) </th>
            <th>Arabic (ar)</th>
            <th>Russian (ru)</th>
            <th>German (de)</th>
            <th>French (fr)</th>
            <th>Spanish (es)</th>
            <th>Portugese (pt-PT)</th>
            <th>Sub-Category</th>
            <th>Preference Type</th>
            <th className="translation">Submit</th>

            {/* {props?.showSubscription && <th>Subscription</th>}
            {props?.showSubscription && <th>Valid Till</th>} */}
          </tr>
        </thead>
        <tbody>
          {props.allAffirmations &&
            props.allAffirmations.length > 0 &&
            props.allAffirmations.map((p, index) => (
              <tr key={p?.affirmationId}>
                <td>{index + 1}</td>
                <td>
                  <Link
                    to={`/admin/affirmation/details/${p.affirmationId}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p.affirmationId)}
                    ></i>
                  </Link>
                </td>
                <td>
                  <textarea value={p?.affirmationNameArr[0]?.name}></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[1]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        0,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Arabic"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[2]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        1,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Russian"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[3]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        2,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="German"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[4]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        3,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="French"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[5]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        4,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Spanish"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    value={p?.affirmationNameArr[6]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        5,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Portugese"
                  ></textarea>
                </td>
                <td>{p?.subCategory}</td>
                <td>{p?.type}</td>
                <td>
                  <button
                    type="button"
                    className="btn-sucess"
                    onClick={(e) =>
                      props.handleAddAffirmationTranslations(index)
                    }
                  >
                    Done
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
