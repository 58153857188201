import React from "react";
import moment from "moment";
import { image_url } from "../../../utils/api";

const BasicDetails = (props) => {
  let { details } = props;

  let image =
    details[0] && details[0]?.character !== null
      ? details[0]?.character
      : image_url + "/default.png";

  return (
    <React.Fragment>
      <img src={image} alt="profile-img" className="details-image" />
      <h1>{details ? details?.[0]?.name : "No Name"}</h1>
      <h6 className="user_email">
        {details ? details?.[0]?.gender : "Prefer not to say"}
      </h6>
      <span
        className={details[0]?.isSubscribed ? "subscribed" : "not_subscribed"}
      >
        {details[0]?.isSubscribed ? "Subscribed" : "Not subscribed"}
      </span>
      <p>
        {details ? moment(details[0]?.createdAt).format("MMM DD, YYYY") : ""}
      </p>
    </React.Fragment>
  );
};

export default BasicDetails;
