import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

const FormDropdownCat = (props) => {
  const [newCategory, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");

  return (
    <React.Fragment>
      <Dropdown className="dropdown-selects">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Select Category
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.allCategories &&
            props.allCategories.length > 0 &&
            props.allCategories.map((p, index) => (
              <Dropdown.Item
                onSelect={() => {
                  setCategoryId(p?.["_id"]);
                  setCategory(p?.categoryName[0]?.name);
                  props.changeCatHandler(p?.["_id"]);
                }}
                active={props?.selectedCat && p?.["_id"] === props?.selectedCat}
              >
                {p?.categoryName[0]?.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default FormDropdownCat;
