import React from "react";
import { Link } from "react-router-dom";

const Table = (props) => {
  console.log("props.allPreferences", props.allPreferences);

  return (
    <React.Fragment>
      <table className="table text-nowrap">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th className="action">Action</th>
            <th>English(en) </th>
            <th>Arabic (ar)</th>
            <th>Russian (ru)</th>
            <th>German (de)</th>
            <th>French (fr)</th>
            <th>Spanish (es)</th>
            <th>Portugese (pt-PT)</th>
            <th className="translation">Submit</th>
            {/* {props?.showSubscription && <th>Subscription</th>}
            {props?.showSubscription && <th>Valid Till</th>} */}
          </tr>
        </thead>
        <tbody>
          {props.allPreferences &&
            props.allPreferences.length > 0 &&
            props.allPreferences.map((p, index) => (
              <tr key={p?.preferenceId}>
                <td>{index + 1}</td>
                <td>
                  <Link
                    to={`/admin/preference-details/${p.preferenceId}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p.preferenceId)}
                    ></i>
                  </Link>
                </td>
                <td>
                  <textarea value={p?.preferenceNameArr[0]?.name}></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[1]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        0,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Arabic"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[2]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        1,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Russian"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[3]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        2,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="German"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[4]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        3,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="French"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[5]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        4,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Spanish"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.preferenceNameArr[6]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        5,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Portugese"
                  ></textarea>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn-sucess"
                    onClick={(e) =>
                      props.handleAddPreferenceTranslationSubmit(index)
                    }
                  >
                    Done
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
