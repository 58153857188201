import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { toast } from "react-toastify";
import { IS_AUTHENTICATED } from "./types";
import { app_url } from "../utils/api";

export const adminLogin = (admin, history) => (dispatch) => {
  axios
    .post(app_url + "/login", admin)
    .then((res) => {
      if (res.data.status_code === 200) {
        setAuthToken(res.data.data.token);
        localStorage.setItem("jwtToken", res.data.data.token);
        dispatch({ type: IS_AUTHENTICATED, payload: true });
        toast.success("Logged in successfully");
        history.push("/admin/dashboard");
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

/**
 * ! Forgot password
 * @param email
 * @param token
 * @param history
 */

export const adminForgotPassword = (email, history) => (dispatch) => {
  axios
    .post(app_url + `/forgot-password`, { email })
    .then((res) => {
      if (res.data.status_code === 404) return toast.error(res.data.message);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        history.push("/admin/reset/password");
      }
    })
    .catch((err) => toast.error(err.response.data?.message));
};

export const adminResetPassword = (data, history) => (dispatch) => {
  axios
    .post(app_url + `/reset-password`, data)
    .then((res) => {
      if (res.data.status_code === 400) return toast.error(res.data.error);
      if (res.data.status_code === 200) {
        toast.success(res.data.message);
        history.push("/admin/login");
      }
    })
    .catch((err) => toast.error(err.response.data?.message));
};

export const logoutUser = (token, history) => (dispatch) => {
  axios
    .post(app_url + "/logout", {}, { headers: { "x-access-token": token } })
    .then((res) => {
      if (res.data.status_code === 200) {
        history.push("/admin/login");
        toast.success(res.data.message);
        localStorage.removeItem("jwtToken");
      }
    })
    .catch((err) => {
      history.push("/admin/login");
    });
};
