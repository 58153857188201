import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import { validateEditManager } from "../../../validations/add-validation";
import {
  getPreference,
  updatePreferences,
} from "../../../actions/dashboardActions";

const PreferenceDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [preferenceTranslation, setPreferenceTranslation] = useState([
    {
      locale: "en",
      name: "",
    },
    {
      locale: "ar",
      name: "",
    },
    {
      locale: "ru",
      name: "",
    },
    {
      locale: "de",
      name: "",
    },
    {
      locale: "fr",
      name: "",
    },
    {
      locale: "es",
      name: "",
    },
    {
      locale: "pt-PT",
      name: "",
    },
  ]);

  useEffect(() => {
    props.getPreference(id, token);
  }, []);

  const handlePreferenceChange = (index, newValue) => {
    setPreferenceTranslation((prevState) => {
      const upatedPreference = [...prevState];
      upatedPreference[index].name = newValue;
      return upatedPreference;
    });
  };

  const handlePreferenceUpdate = (e) => {
    e.preventDefault();

    const preference = {
      preferenceId: id,
      preference: preferenceTranslation,
    };

    props.updatePreferences(preference, token);
    props.history.push("/admin/preferences");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">
          Edit Preference Translations
        </h3>
        <div className="row">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                key={props.dashboard.singlePreference._id}
                preferenceDetails={props.dashboard.singlePreference}
                handlePreferenceUpdate={handlePreferenceUpdate}
                handlePreferenceChange={handlePreferenceChange}
                preferenceTranslation={preferenceTranslation}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PreferenceDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getPreference: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getPreference, updatePreferences })(
  memo(PreferenceDetails)
);
