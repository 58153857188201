import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";
import FormPreferenceDropdown from "../../common/FormPreferenceDropdown/FormPreferenceDropdown";
import FormSubCategoryDropdown from "../../common/FormSubCategoryDropdown/FormSubCategoryDropdown";

const Form = (props) => {
  const [affirmationDetails, setAffirmationDetails] = useState(
    props.affirmationDetails
  );
  const [subCategories, setSubCategories] = useState(props.allSubCategories);

  const handleSubCategoryChange = (e) => {
    const newValue = e.target.value;
    props.handleAffirmationChange(0, newValue, "subCategory");
  };

  const handleSubChange = (e) => {
    props.setSubCategory(e.target.value);
  };

  const handleTypeChange = (e) => {
    const newValue = e.target.value;
    props.handleAffirmationChange(0, newValue, "type");
  };

  const handleStateInput = (index, value) => {
    const affirmationDetailCopy = JSON.parse(
      JSON.stringify(affirmationDetails)
    );
    affirmationDetailCopy.title[index].name = value;
    setAffirmationDetails(affirmationDetailCopy);
  };

  return (
    <React.Fragment>
      <form onSubmit={props.handleAffirmationUpdate}>
        {/* {props.affirmationDetails?.title?.map((aff, index) => { */}
        <div className="row">
          <div className="col-md-6">
            <label className="status-label">English</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="english"
              placeholder="English"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[0]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(0, e.target.value);
                handleStateInput(0, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Arabic</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="arabic"
              placeholder="Arabic"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[1]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(1, e.target.value);
                handleStateInput(1, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Russian</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="russian"
              placeholder="Russian"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[2]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(2, e.target.value);
                handleStateInput(2, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">German</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="german"
              placeholder="German"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[3]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(3, e.target.value);
                handleStateInput(3, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">French</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="french"
              placeholder="French"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[4]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(4, e.target.value);
                handleStateInput(4, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Spanish</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="spanish"
              placeholder="Spanish"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[5]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(5, e.target.value);
                handleStateInput(5, e.target.value);
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="status-label">Portugese</label>
            <FormInput
              icon="fa fa-globe icon"
              size="13px"
              type="text"
              name="portugese"
              placeholder="Portugese"
              className="form-control shadow-none"
              value={affirmationDetails?.title?.[6]?.name}
              handleChange={(e) => {
                props.handleAffirmationChange(6, e.target.value);
                handleStateInput(6, e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Affirmation Sub-Category</label>
            <FormSubCategoryDropdown
              icon="fa fa-globe icon"
              size="13px"
              name="subCategory"
              placeholder="Select Sub-Category"
              className="form-control shadow-none"
              // options={props.allSubCategories}
              options={subCategories}
              type="subCategory"
              value={props.subCategory || affirmationDetails.subCategory}
              subCategory={props.subCategory}
              handleChange={(e) => {
                props.setSubCategory(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">Affirmation Preference</label>
            <FormPreferenceDropdown
              icon="fa fa-globe icon"
              size="13px"
              name="type"
              placeholder="Select Preference"
              className="form-control shadow-none"
              options={props.allPreferences}
              value={props.type || affirmationDetails.type}
              handleChange={(e) => {
                props.setType(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="form-group mb-0">
              <Button
                value="Update Category"
                className="btn btn-primary btn-block update-details-btn shadow-none"
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
