import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { image_url } from "../../../utils/api";

const Table = (props) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr no.</th>
            <th>Voice</th>
            <th>Voice Name</th>
            <th>Locale</th>
            <th>Voice Identifier</th>
            <th>Supported By</th>
            <th>Date Registered</th>
            <th>Status</th>
            <th className="action">Action</th>
            {/* {props?.showSubscription && <th>Subscription</th>}
            {props?.showSubscription && <th>Valid Till</th>} */}
          </tr>
        </thead>
        <tbody>
          {props.allVoices &&
            props.allVoices.length > 0 &&
            props.allVoices.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={
                      p.image && p.image
                        ? p.image
                        : image_url + "/voice_default.png"
                    }
                    alt="profile"
                  />
                </td>
                <td>{p.voiceName}</td>
                <td>{p.voiceLocale}</td>
                <td>{p.voiceIdentifier}</td>
                <td>{p.voiceSupportedBy}</td>
                <td>{moment(p?.createdAt).format("MMM DD, YYYY")}</td>
                <td>
                  <span className={p.isUserBlocked ? "suspended" : "active"}>
                    {p.isUserBlocked ? "Suspended" : "Active"}
                  </span>
                </td>
                <td>
                  <Link
                    to={`/admin/voice-details/${p._id}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p._id)}
                    ></i>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
