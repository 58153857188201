import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { api_url } from "../../../utils/api";

const Table = (props) => {
  console.log("All SubCategories --->", props.allSubCategories);
  return (
    <React.Fragment>
      <table className="table text-nowrap">
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Sr No.</th>
            <th className="action">Action</th>
            <th>English(en) </th>
            <th>Arabic (ar)</th>
            <th>Russian (ru)</th>
            <th>German (de)</th>
            <th>French (fr)</th>
            <th>Spanish (es)</th>
            <th>Portugese (pt-PT)</th>
            <th>Category</th>
            <th className="translation">Submit</th>
          </tr>
        </thead>
        <tbody>
          {props.allSubCategories &&
            props.allSubCategories.length > 0 &&
            props.allSubCategories.map((p, index) => (
              <tr key={p?._id}>
                <td>{index + 1}</td>
                <td>
                  <Link
                    to={`/admin/sub-category/details/${p.subCategoryId}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  {/* <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p.subCategoryId)}
                    ></i>
                  </Link> */}
                </td>
                {/* <td>
                  <LazyLoadImage
                    src={
                      p.image && p.image ? p.image : api_url + "/default.png"
                    }
                    alt="profile"
                  />
                </td> */}
                <td>
                  <textarea value={p?.subCategoryArr[0]?.name}></textarea>
                </td>

                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[1]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        0,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Arabic"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[2]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        1,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Russian"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[3]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        2,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="German"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[4]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        3,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="French"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[5]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        4,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Spanish"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.subCategoryArr[6]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        5,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Portugese"
                  ></textarea>
                </td>
                <td>{p?.category === null ? "Main Category" : p?.category}</td>
                <td>
                  <button
                    type="button"
                    className="btn-sucess"
                    onClick={(e) =>
                      props.handleAddSubCategoryTranslationSubmit(index)
                    }
                  >
                    Done
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
