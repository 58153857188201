import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Search from "../../common/Search/Search";
import Button from "../../common/Button/Button";
import DeleteModal from "../../common/Modal/DeleteModal";
import Pagination from "../../common/Pagination/Pagination";
import AddCharacterModal from "../../common/Modal/AddCharacter";
import { validateAddManager } from "../../../validations/add-validation";
import {
  getAllTransactions,
  createManager,
  searchSubscriber,
  deleteTransaction,
  blockOrUnblockManager,
  getAllSubscriptions,
  getFilteredTransactions,
} from "../../../actions/dashboardActions";
import "./styles.scss";

class ManageTransactions extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddCharacterModal: false,
      showSubscriptionModal: false,
      manager: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        code: "",
      },
      showFilter: true,
      selectedFilter: "",
      selectedStatus: "",
      selectedDevice: "",
      query: "",
      page: 1,
      pageLimit: 200,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    this.props.getAllTransactions(this.state.token);
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddCharacterModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddCharacterModalClose = () => {
    this.setState({ showAddCharacterModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteTransaction(this.state.token, this.state.id);
    this.setState({ showDeleteModal: false });
    this.props.getAllTransactions(this.state.token);
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchSubscriber(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllTransactions(this.state.token, page, this.state.pageLimit);
  };

  handleFilterChange = (event) => {
    const selectedOption = event.target.value;

    console.log("CHECK SUB FILTER STATUS ------>", this.state.selectedStatus);

    const selectedSubFilter = this.state.selectedStatus
      ? this.state.selectedStatus
      : this.state.selectedDevice;

    this.setState({ selectedFilter: selectedOption }, () => {
      this.props.getFilteredTransactions(
        this.state.token,
        this.state.selectedFilter,
        selectedSubFilter,
        this.state.page,
        this.state.pageLimit
      );

      this.setState({ showFilter: true });
    });
  };

  handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    this.setState({ selectedStatus }, () => {
      this.handleFilterChange({ target: { value: "status" } });
    });
  };

  handleDeviceChange = (event) => {
    const selectedDevice = event.target.value;
    this.setState({ selectedDevice }, () => {
      this.handleFilterChange({ target: { value: "device" } });
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      selectedFilter: "",
      selectedStatus: "",
      selectedDevice: "",
    });

    this.props.getAllTransactions(this.state.token);
  };

  render() {
    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Transactions</h5>
          <div className="manage-patient">
            <div
              className="row search_row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {/* <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Transaction"
                  handleClick={this.addManagerModalClicked}
                />
              </div> */}
              <div className="col-md-2">
                <div className="filter-form float-right">
                  {this.state.showFilter && (
                    <>
                      <i className="fa fa-filter filter-size"></i>
                      <select
                        className="filter-select"
                        value={this.state.selectedFilter}
                        onChange={this.handleFilterChange}
                      >
                        <option value="" disabled>
                          Select Filter
                        </option>
                        <option value="status">status</option>
                        <option value="date">date</option>
                        <option value="device">device</option>
                      </select>

                      {this.state.selectedFilter === "status" && (
                        <select
                          className="filter-select"
                          value={this.state.selectedStatus}
                          onChange={this.handleStatusChange}
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option value="active">active</option>
                          <option value="inactive">inactive</option>
                        </select>
                      )}

                      {this.state.selectedFilter === "device" && (
                        <select
                          className="filter-select"
                          value={this.state.selectedDevice}
                          onChange={this.handleDeviceChange}
                        >
                          <option value="" disabled>
                            Select Device
                          </option>
                          <option value="ios">ios</option>
                          <option value="android">android</option>
                        </select>
                      )}

                      {this.state.selectedFilter && (
                        <i
                          className="fa fa-times remove-filter-icon"
                          onClick={this.handleRemoveFilter}
                        ></i>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Transaction Id"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allTransactions={this.props.dashboard.allTransactions}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={10}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Transaction"
          text="Are you sure you want to delete this transaction ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />
      </React.Fragment>
    );
  }
}

ManageTransactions.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllTransactions: PropTypes.func.isRequired,
  getFilteredTransactions: PropTypes.func.isRequired,
  createManager: PropTypes.func.isRequired,
  searchSubscriber: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  allTransactions: state?.dashboard?.allTransactions,
});

export default connect(mapStateToProps, {
  getAllTransactions,
  createManager,
  searchSubscriber,
  deleteTransaction,
  blockOrUnblockManager,
  getAllSubscriptions,
  getFilteredTransactions,
})(ManageTransactions);
