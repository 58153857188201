import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";

import {
  getSubcategory,
  updateSubCategory,
} from "../../../actions/dashboardActions";

const SubCategoryDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");

  const [activeBtn, setActiveBtn] = useState(true);
  const [inactiveBtn, setInactiveBtn] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [smallImage, setSmallImage] = useState("");
  const [isPremium, setIsPremium] = useState(true);
  const [subCategoryTranslation, setSubCategoryTranslation] = useState([
    {
      locale: "en",
      name: "",
    },
    {
      locale: "ar",
      name: "",
    },
    {
      locale: "ru",
      name: "",
    },
    {
      locale: "de",
      name: "",
    },
    {
      locale: "fr",
      name: "",
    },
    {
      locale: "es",
      name: "",
    },
    {
      locale: "pt-PT",
      name: "",
    },
  ]);

  const handleSubCategoryChange = (index, newValue) => {
    setSubCategoryTranslation((prevState) => {
      const updatedSubCategory = [...prevState];
      updatedSubCategory[index].name = newValue;
      return updatedSubCategory;
    });
  };

  useEffect(() => {
    props.getSubcategory(id, token);
  }, []);

  const handleSubCategoryUpdate = (e) => {
    e.preventDefault();

    const subCategory = new FormData();
    subCategory.append("subCategoryId", id);
    subCategory.append("isPremium", isPremium);
    subCategory.append("subCategoryImage", smallImage);
    subCategory.append("backgroundColor", backgroundColor);
    subCategory.append(
      "subCategoryName",
      JSON.stringify(subCategoryTranslation)
    );

    props.updateSubCategory(subCategory, token);
    props.history.push("/admin/sub-categories");
  };

  const handleSmallImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setSmallImage(file);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleRadioChange = (status) => {
    if (status === "true") {
      setIsPremium(true);

      setActiveBtn(true);
      setInactiveBtn(false);
    } else {
      setIsPremium(false);

      setActiveBtn(false);
      setInactiveBtn(true);
    }
  };

  const handleColorChange = (e) => {
    e.preventDefault();

    setBackgroundColor(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">
          Edit Sub-Category Details
        </h3>
        <div className="row mx-0">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                key={props.dashboard.singleSubcategory._id}
                subCategoryDetails={props.dashboard.singleSubcategory}
                handleSubCategoryChange={handleSubCategoryChange}
                subCategoryTranslation={subCategoryTranslation}
                handleSubCategoryUpdate={handleSubCategoryUpdate}
                smallImage={smallImage}
                imagePreviewUrl={imagePreviewUrl}
                activeBtn={activeBtn}
                inactiveBtn={inactiveBtn}
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                handleColorChange={handleColorChange}
                handleRadioChange={handleRadioChange}
                handleSmallImageChange={handleSmallImageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SubCategoryDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getSubcategory: PropTypes.func.isRequired,
  updateSubCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getSubcategory, updateSubCategory })(
  memo(SubCategoryDetails)
);
