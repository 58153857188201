import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddThemeImageModal from "../../common/Modal/AddThemeImage";
import Button from "../../common/Button/Button";
import { validateAddManager } from "../../../validations/add-validation";
import {
  getAllManagers,
  getAllCharacters,
  createManager,
  searchManagers,
  deleteManager,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManageUnsplashImages extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("token"),
      id: "",
      showDeleteModal: false,
      showAddThemeImageModal: false,
      showSubscriptionModal: false,
      manager: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        code: "",
      },
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    const token = localStorage.getItem("jwtToken");
    this.props.getAllCharacters(token);
    // this.props.getAllSubscriptions(this.state.token, 1, 1000);
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddThemeImageModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddThemeImageModalClose = () => {
    this.setState({ showAddThemeImageModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteManager(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllCharacters(
      this.state.token
      // this.state.currentPage,
      // this.state.pageLimit
    );
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const manager = { ...this.state.manager };
    manager[e.target.name] = e.target.value;
    this.setState({ manager });
  };

  handleAddManagerSubmit = (e) => {
    e.preventDefault();

    const { code, firstName, lastName, email, phoneNumber } =
      this.state.manager;

    const result = validateAddManager(
      firstName,
      lastName,
      email,
      phoneNumber,
      code
    );
    if (result) return toast.error(result);

    this.props.createManager(this.state.token, this.state.manager);

    this.setState({ showAddThemeImageModal: false });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchManagers(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllCharacters(this.state.token, page, this.state.pageLimit);
  };

  render() {
    // const allCharacters = sessionStorage.getItem("total_voices") || 0;
    const { allCharacters } = this.props;
    const totalCharacters = allCharacters.length || 0;
    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper">
          <h5 className="page_heading">Manage Theme Images</h5>
          <hr />
          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Image"
                  handleClick={this.addManagerModalClicked}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Theme Images"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allCharacters={this.props.dashboard.allCharacters}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={totalCharacters}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Account"
          text="Are you sure you want to delete this manager's account?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddThemeImageModal
          showAddThemeImageModal={this.state.showAddThemeImageModal}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          code={this.state.code}
          handleAddThemeImageModalClose={this.handleAddThemeImageModalClose}
          handleAddManagerSubmit={this.handleAddManagerSubmit}
          handleChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

ManageUnsplashImages.propTypes = {
  dashboard: PropTypes.object.isRequired,
  allCharacters: PropTypes.func.isRequired,
  createManager: PropTypes.func.isRequired,
  searchManagers: PropTypes.func.isRequired,
  deleteManager: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allCharacters: state?.dashboard?.allCharacters,
  }
);

export default connect(mapStateToProps, {
  getAllCharacters,
  createManager,
  searchManagers,
  deleteManager,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageUnsplashImages);
