import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import LazyLoadImage from "../../common/Lazy/Image";
import VienRadio from "../../common/Radio/Radio";
import FormInput from "../Form-Input/FormInput";
import FormSelect from "../Form-select/FormSelect";
import FormDropdownCat from "../FormDropdown/FormDropdownCat";

import "./styles.scss";

const AddSubCategory = (props) => {
  const [status, setStatus] = useState("Active");
  const [selectedCat, setSelectedCat] = useState(props.newSubCategory);

  useEffect(() => {
    props.handleSelectChange({
      categoryId: selectedCat,
    });
  }, [selectedCat]);

  const changeCatHandler = useCallback(
    (value) => {
      setSelectedCat(value);
    },
    [selectedCat]
  );

  const getSelectedCat = () => {
    let res = null;

    if (
      selectedCat &&
      props.allCategories.findIndex((p) => p?.["_id"] == selectedCat) > -1
    ) {
      const selectedCatObj =
        props.allCategories[
          props.allCategories.findIndex((p) => p?.["_id"] == selectedCat)
        ];
      res = selectedCatObj?.categoryName[0]?.name;
    }
    return res;
  };

  return (
    <React.Fragment>
      <Modal
        className="AddSubcategory-Modal"
        show={props.showAddSubCategoryModal}
        onHide={props.handleAddSubCategoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Sub-Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={props.handleAddSubCategorySubmit}
            encType="multipart/form-data"
          >
            <FormInput
              icon="fa fa-users icon"
              size="15px"
              type="text"
              name="subCategoryName"
              placeholder="Sub Category Name"
              value={props.categoryName}
              handleChange={props.handleChange}
            />
            <div style={{ position: "relative" }}>
              <FormSelect
                icon="fa fa-users icon"
                size="15px"
                type="text"
                name="categoryName"
                placeholder="Category"
                value={getSelectedCat()}
                handleChange={props.handleChange}
              />
              <FormDropdownCat
                changeCatHandler={changeCatHandler}
                selectedCat={selectedCat}
                allCategories={props.allCategories}
              />
            </div>
            <FormInput
              icon="fa fa-users icon"
              size="15px"
              type="text"
              name="backgroundColor"
              placeholder="Sub Category Colour ( #Hex-value )"
              value={props.backgroundColor}
              handleChange={props.handleChange}
            />
            <div className="form-group mt-3">
              <label className="status-label">Premium : </label>
              <VienRadio
                name="status"
                text="true"
                checked={props.activeBtn}
                handleChange={props.handleRadioChange}
              />
              <VienRadio
                name="status"
                text="false"
                checked={props.inactiveBtn}
                handleChange={props.handleRadioChange}
              />
            </div>
            <div className="form-group">
              <LazyLoadImage
                src={props.imagePreview}
                key="smallFileName"
                alt="Blog-image"
                width="60"
              />
              <div
                className="text-center mx-auto mt-1"
                style={{ width: "100%" }}
              >
                <label className="upload-image mt-0" htmlFor="upload-button">
                  Sub Category Image
                </label>
              </div>
              <input
                id="upload-button"
                name="image"
                type="file"
                accept="image/*"
                onChange={props.handleImageChange}
              />
            </div>
            <Button
              type="submit"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
              // className="btn submit-btn shadow-none mt-0"
              value="Add Sub Category"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddSubCategory;
