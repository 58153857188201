import React, { useEffect, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import multipleFilesUpload from "./MultipleFilesUpload";
import LazyLoadImage from "../Lazy/Image";
import FormDropdownChar from "../FormDropdown/FormDropdownChar";
import "./styles.scss";
import { addCharacter } from "../../../actions/dashboardActions";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

const AddCharacter = (props) => {
  const [selectedCharacter, setSelectedCharacter] = useState(
    props.characterType
  );
  const [smallImagePreviewUrl, setSmallImagePreviewUrl] = useState("");
  const [largeImagePreviewUrl, setLargeImagePreviewUrl] = useState("");

  const characterTypes = [
    { characterType: "Female", characterIndex: 1 },
    { characterType: "Male", characterIndex: 2 },
    { characterType: "Others", characterIndex: 3 },
  ];

  useEffect(() => {
    props.handleSelectChange({
      characterType: selectedCharacter,
    });
  }, [selectedCharacter]);

  const changeCharacterHandler = useCallback(
    (value) => {
      setSelectedCharacter(value);
      props.handleChange(value);
    },
    [selectedCharacter]
  );

  const getSelectedCharacter = () => {
    let res = null;

    if (
      selectedCharacter &&
      characterTypes.findIndex((p) => p?.characterIndex == selectedCharacter) >
        -1
    ) {
      const selectedCharcaters =
        characterTypes[
          characterTypes.findIndex(
            (p) => p?.characterIndex == selectedCharacter
          )
        ];
      res = selectedCharcaters?.characterType;
    }
    return res;
  };

  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showAddCharacterModal}
        onHide={props.handleAddCharacterModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Character</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={props.handleAddCharacterSubmit}
            encType="multipart/form-data"
          >
            <div style={{ position: "relative" }}>
              <FormInput
                icon="fa fa-user-secret icon"
                size="15px"
                type="text"
                name="charcterType"
                placeholder="Character Type"
                value={getSelectedCharacter()}
                handleChange={(e) => props.handleChange(e.target.value)}
              />
              <FormDropdownChar
                changeCharacterHandler={changeCharacterHandler}
                selectedCharacter={selectedCharacter}
                characterTypes={characterTypes}
              />
            </div>

            <div className="form-group">
              <LazyLoadImage
                src={props.smallImagePreviewUrl}
                key="smallFileName"
                alt="Blog-image"
                width="60"
              />
              <div className="text-center mx-auto">
                <label className="upload-image" htmlFor="upload-button1">
                  Upload Small Character Image
                </label>
              </div>
              <input
                id="upload-button1"
                name="image"
                type="file"
                accept="image/*"
                onChange={props.handleSmallImageChange}
              />
            </div>
            <div className="form-group">
              <LazyLoadImage
                src={props.largeImagePreviewUrl}
                key="largeFileName"
                alt="Blog-image"
                width="60"
              />
              <div className="text-center mx-auto">
                <label className="upload-image" htmlFor="upload-button2">
                  Upload Large Character Image
                </label>
              </div>

              <input
                id="upload-button2"
                name="image"
                type="file"
                accept="image/*"
                onChange={props.handleLargeImageChange}
              />
            </div>
            <Button
              type="submit"
              value="Add Character"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddCharacter;
