import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../common/Button/Button";
import { addAbout, getCMS } from "../../../actions/dashboardActions";
import "./styles.scss";

class UpdateAbout extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
      text: "",
    };
  }

  componentDidMount() {
    this.props.getCMS(this.state.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.about) {
      this.setState({
        text: nextProps.dashboard.cms.about,
      });
    }
  }

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ text: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.addAbout(this.state.text, this.state.token);
  };

  render() {
    return (
      <React.Fragment>
        <div className="add-about-wrapper">
          <div className="row mx-0">
            <div className="col-md-6 pl-0">
              <h5 className="page_heading">About</h5>
            </div>
            <div className="col-md-6 pr-0">
              <Link
                target="_blank"
                to="/about"
                className="btn view-btn shadow-none"
              >
                View
              </Link>
            </div>
          </div>
          <hr />

          <div className="mt-4">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.handleSubmit}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={this.state.text}
                    onReady={(editor) => this.handleInit(editor)}
                    onChange={(event, editor) =>
                      this.handleChange(event, editor)
                    }
                  />

                  <Button
                    type="submit"
                    className="btn submit-btn shadow-none"
                    value="Update"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UpdateAbout.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addAbout: PropTypes.func.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { addAbout, getCMS })(UpdateAbout);
