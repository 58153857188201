import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import { Pagination } from "@mui/material";
import Search from "../../common/Search/Search";
import Button from "../../common/Button/Button";
import DeleteModal from "../../common/Modal/DeleteModal";
import AddThemeModal from "../../common/Modal/AddThemeModal";
// import Pagination from "../../common/Pagination/Pagination";
import { validateAddNewTheme } from "../../../validations/add-validation";
import {
  getAllThemeCategories,
  getAllThemesAndFonts,
  addNewTheme,
  searchThemes,
  deleteTheme,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManageThemesAndFonts extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddThemeModal: false,
      showSubscriptionModal: false,
      newTheme: {
        themeCategoryId: "",
        theme: "",
        isPremium: true,
        fontColor: "",
        fontName: "",
        andriodFontName: "",
        backgroundColor: "",
        backgroundOpacity: "",
      },
      imagePreview: "",
      activeBtn: true,
      inactiveBtn: false,
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    this.props.getAllThemesAndFonts(this.state.token);
    this.props.getAllThemeCategories(this.state.token,'en');
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddThemeModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddThemeModalClose = () => {
    this.setState({ showAddThemeModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deleteTheme(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllThemesAndFonts(this.state.token);
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const newTheme = { ...this.state.newTheme };
    newTheme[e.target.name] = e.target.value;
    this.setState({ newTheme });
  };

  handleRadioChange = (status) => {
    const newTheme = { ...this.state.newTheme };

    if (status === "true") {
      newTheme.isPremium = true;
      this.setState({
        activeBtn: true,
        inactiveBtn: false,
      });
    } else {
      newTheme.isPremium = false;
      this.setState({
        activeBtn: false,
        inactiveBtn: true,
      });
    }
    this.setState({ newTheme });
  };

  handleSelectChange = (newValue) => {
    const newTheme = { ...this.state.newTheme, ...newValue };
    this.setState({ newTheme });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let newTheme = { ...this.state.newTheme };
      newTheme.theme = file;
      this.setState({
        newTheme,
        imagePreview: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleAddThemeSubmit = (e) => {
    e.preventDefault();

    const {
      themeCategoryId,
      theme,
      isPremium,
      fontColor,
      fontName,
      andriodFontName,
      backgroundColor,
      backgroundOpacity,
    } = this.state.newTheme;

    const result = validateAddNewTheme(
      themeCategoryId,
      theme,
      fontColor,
      fontName,
      andriodFontName,
      backgroundColor,
      backgroundOpacity
    );

    if (result) return toast.error(result);

    console.log("isPremium",isPremium);

    const newTheme = new FormData();
    newTheme.append("theme", theme);
    newTheme.append("themeCategoryId", themeCategoryId);
    newTheme.append("isPremium", isPremium);
    newTheme.append("fontColor", fontColor);
    newTheme.append("fontName", fontName);
    newTheme.append("andriodFontName", andriodFontName);
    newTheme.append("backgroundColor", backgroundColor);
    newTheme.append("backgroundOpacity", backgroundOpacity);

    this.props.addNewTheme(newTheme, this.state.token);
    this.setState({ showAddThemeModal: false });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchThemes(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllThemesAndFonts(this.state.token);
  };

  render() {
    const { allThemesAndFonts } = this.props;

    const profile = this.props.dashboard.adminProfileData;

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Themes</h5>

          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Theme"
                  handleClick={this.addManagerModalClicked}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Themes"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allThemesAndFonts={this.props.dashboard.allThemesAndFonts}
                blockOrUnblockManager={this.blockOrUnblockManager}
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingTop: "1rem",
                }}
                className="col-md-6"
              >
                <Pagination
                  count={Math.ceil(totalThemes / this.state.pageLimit)}
                  variant="outlined"
                  shape="rounded"
                />
              </div> */}
              {/* {!this.state.query && (
                  <Pagination
                    count={totalThemes}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )} */}
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Theme"
          text="Are you sure you want to delete this theme ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddThemeModal
          showAddThemeModal={this.state.showAddThemeModal}
          themeCategoryId={this.state.newTheme.themeCategoryId}
          isPremium={this.state.newTheme.isPremium}
          fontColor={this.state.newTheme.fontColor}
          fontName={this.state.newTheme.fontName}
          andriodFontName={this.state.newTheme.andriodFontName}
          backgroundColor={this.state.newTheme.backgroundColor}
          backgroundOpacity={this.state.newTheme.backgroundOpacity}
          imagePreview={this.state.imagePreview}
          allThemesCategories={this.props.allThemesCategories}
          handleAddThemeModalClose={this.handleAddThemeModalClose}
          handleAddThemeSubmit={this.handleAddThemeSubmit}
          handleChange={this.handleChange}
          handleRadioChange={this.handleRadioChange}
          handleImageChange={this.handleImageChange}
          handleSelectChange={this.handleSelectChange}
        />
      </React.Fragment>
    );
  }
}

ManageThemesAndFonts.propTypes = {
  dashboard: PropTypes.object.isRequired,
  allThemesAndFonts: PropTypes.func.isRequired,
  addNewTheme: PropTypes.func.isRequired,
  searchThemes: PropTypes.func.isRequired,
  deleteTheme: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allThemesAndFonts: state?.dashboard?.allThemesAndFonts,
    allThemesCategories: state?.dashboard?.allThemesCategories,
  }
);

export default connect(mapStateToProps, {
  getAllThemesAndFonts,
  getAllThemeCategories,
  addNewTheme,
  searchThemes,
  deleteTheme,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManageThemesAndFonts);
