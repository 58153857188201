import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UpdateBlog from "../../common/Modal/UpdateBlog";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import ViewBlog from "../../common/Modal/ViewBlog";
import {
  allBlog,
  deleteBlog,
  searchBlog,
  getBlog,
  updateBlog,
} from "../../../actions/dashboardActions";
import "./styles.scss";

class ManageBlog extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      query: "",
      pageLimit: 10,
      currentPage: 1,
      title: "",
      description: "",
      status: "",
      image: "",
      imagePreviewUrl: "",
      showViewModal: false,
      showUpdateBlogModal: false,
    };
  }

  componentDidMount() {
    this.props.allBlog(
      this.state.token,
      this.state.currentPage,
      this.state.pageLimit
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.singleBlog) {
      let data = nextProps.dashboard.singleBlog;
      this.setState({
        title: data.title,
        description: data.description,
        imagePreviewUrl: data.image,
        status: data.status,
        activeBtn: data.status === "Active" ? true : false,
        inactiveBtn: data.status === "Inactive" ? true : false,
      });
    }
  }

  handleDelete = (id) => {
    this.props.deleteBlog(id, this.state.token);
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchBlog(query, this.state.token);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.allBlog(this.state.token, page, this.state.pageLimit);
  };

  handleViewModalClose = () => {
    this.setState({ showViewModal: false });
  };

  handleViewModalShow = (id) => {
    this.setState({ showViewModal: true, id });

    this.props.getBlog(id, this.state.token);
  };

  handleUpdateBlog = (id) => {
    this.setState({ showUpdateBlogModal: true, id });

    this.props.getBlog(id, this.state.token);
  };

  handleUpdateBlogModalClose = () => {
    this.setState({ showUpdateBlogModal: false });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInit = (editor) => {
    this.setState({ editor });
  };
  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ answer: data });
  };
  handleRadioChange = (status) => {
    if (status === "Active") {
      this.setState({ status, activeBtn: true, inactiveBtn: false });
    } else {
      this.setState({ status, activeBtn: false, inactiveBtn: true });
    }
  };

  handleUpdateBlogSubmit = (e) => {
    e.preventDefault();

    const blog = new FormData();
    blog.append("id", this.state.id);
    blog.append("image", this.state.image);
    blog.append("title", this.state.title);
    blog.append("description", this.state.description);
    blog.append("status", this.state.status);

    this.props.updateBlog(blog, this.state.token);

    this.setState({
      editor: "",
      title: "",
      description: "",
      status: "",
      activeBtn: false,
      inactiveBtn: false,
      showUpdateBlogModal: false,
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const totalBlog = sessionStorage.getItem("total_blogs") || 0;

    return (
      <React.Fragment>
        <div className="manage-faq-wrapper">
          <h5>Manage Blogs</h5>
          <hr />
          <div className="manage-faq custom_class">
            <div className="row mx-0">
              <div className="col-md-7 pl-0"></div>
              <div className="col-md-5 pr-0">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Blog"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.dashboard.blog &&
                    this.props.dashboard.blog.length > 0 &&
                    this.props.dashboard.blog.map((f, index) => (
                      <tr key={f._id}>
                        <td>{index + 1}</td>
                        <td>{f.title}</td>
                        <td>
                          {f.description &&
                            f.description.substring(0, 100) + "..."}
                        </td>
                        <td>
                          <span className={f.status}>{f.status}</span>
                        </td>
                        <td style={{ display: "flex" }}>
                          <Link
                            to="#"
                            onClick={() => this.handleViewModalShow(f._id)}
                          >
                            <i className="fa fa-eye"></i>
                          </Link>

                          <Link
                            to="#"
                            onClick={() => this.handleUpdateBlog(f._id)}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>

                          <Link to="#" onClick={() => this.handleDelete(f._id)}>
                            <i className="fa fa-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
              <div className="col-md-6 pl-0">
                {!this.state.query && (
                  <Pagination
                    count={totalBlog}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <ViewBlog
          showViewModal={this.state.showViewModal}
          handleViewModalClose={this.handleViewModalClose}
          details={this.props.dashboard.singleBlog}
        />

        <UpdateBlog
          imagePreviewUrl={this.state.imagePreviewUrl}
          showUpdateBlogModal={this.state.showUpdateBlogModal}
          handleUpdateBlogModalClose={this.handleUpdateBlogModalClose}
          handleUpdateBlogSubmit={this.handleUpdateBlogSubmit}
          handleImageChange={this.handleImageChange}
          handleInputChange={this.handleInputChange}
          handleTagsChange={this.handleTagsChange}
          handleInit={this.handleInit}
          handleChange={this.handleChange}
          handleRadioChange={this.handleRadioChange}
          title={this.state.title}
          description={this.state.description}
          activeBtn={this.state.activeBtn}
          inactiveBtn={this.state.inactiveBtn}
        />
      </React.Fragment>
    );
  }
}

ManageBlog.propTypes = {
  dashboard: PropTypes.object.isRequired,
  allBlog: PropTypes.func.isRequired,
  deleteBlog: PropTypes.func.isRequired,
  searchBlog: PropTypes.func.isRequired,
  getBlog: PropTypes.func.isRequired,
  updateBlog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  allBlog,
  deleteBlog,
  searchBlog,
  getBlog,
  updateBlog,
})(ManageBlog);
