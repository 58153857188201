export const IS_LOADING = "IS_LOADING";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const ADMIN_PROFILE_DATA = "ADMIN_PROFILE_DATA";
export const FAQ = "FAQ";
export const SINGLE_FAQ = "SINGLE_FAQ";
export const ABOUT = "ABOUT";
export const HELP = "HELP";
export const TERM = "TERM";
export const ALL_SUBSCRIPTIONS = "ALL_SUBSCRIPTIONS";
export const VERIFY_ERROR = "VERIFY_ERROR";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const GET_QUESTION = "GET_QUESTION";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const GET_CMS = "GET_CMS";
export const GET_MANAGERS = "GET_MANAGERS";
export const GET_MANAGER = "GET_MANAGER";
export const GET_STAFF = "GET_STAFF";
export const GET_STAFFS = "GET_STAFFS";
export const Blog = "Blog";
export const BLOG = "BLOG";
export const SINGLE_BLOG = "SINGLE_BLOG";
export const GET_THEME_CATEGORIES = "GET_THEME_CATEGORIES";
export const GET_THEME_CATEGORIES_DATA = "GET_THEME_CATEGORIES_DATA";
export const GET_SINGLE_THEME_CATEGORY = "GET_SINGLE_THEME_CATEGORY";
export const DASHBOARD_STATS = "DASHBOARD_STATS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const CATEGORY_TRANSLATIONS = "CATEGORY_TRANSLATIONS";
export const GET_SINGLE_CATEGORY = "GET_SINGLE_CATEGORY";
export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_SINGLE_SUBCATEGORY = "GET_SINGLE_SUBCATEGORY";
export const GET_MODULES = "GET_MODULES";
export const GET_PREFERENCES = "GET_PREFERENCES";
export const GET_SINGLE_PREFERENCE = "GET_SINGLE_PREFERENCE";
export const GET_USERS = "GET_USERS";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const GET_VOICES = "GET_VOICES";
export const GET_SINGLE_VOICE = "GET_SINGLE_VOICE";
export const GET_CHARACTERS = "GET_CHARACTERS";
export const GET_SINGLE_CHARACTER = "GET_SINGLE_CHARACTER";
export const GET_AFFIRMATIONS = "GET_AFFIRMATIONS";
export const GET_SINGLE_AFFIRMATION = "GET_SINGLE_AFFIRMATION";
export const GET_THEMESANDFONTS = "GET_THEMESANDFONTS";
export const GET_SINGLE_THEME = "GET_SINGLE_THEME";
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TEXT_CONTENTS = "GET_TEXT_CONTENTS";
export const GET_SINGLE_TEXT_CONTENTS = "GET_SINGLE_TEXT_CONTENTS";
export const FAQS = "FAQS";
export const ADMIN_PROFILE = "ADMIN_PROFILE";
export const GET_AFFIRMATIONS_TO_EXPORT = "GET_AFFIRMATIONS_TO_EXPORT";
