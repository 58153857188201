import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";
import { validatePassChange } from "../../../validations/settings";
import { changePassword } from "../../../actions/dashboardActions";
import "./styles.scss";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("jwtToken"),
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePasswordChange = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmNewPassword } = this.state;

    const result = validatePassChange(
      oldPassword,
      newPassword,
      confirmNewPassword
    );
    if (result) return toast.error(result);

    this.props.changePassword(
      oldPassword,
      newPassword,
      confirmNewPassword,
      this.state.token,
      this.props.history
    );
  };

  render() {
    return (
      <div className="container-fluid settings new-sett">
        <div className="row">
          <div className="col-md-12">
            <h3 className="page_heading new-page-heading">Settings</h3>
          </div>
        </div>

        {/* Changes Jatin */}

        <div className="row">
          <div className="col-md-5">
            <div className="change-password">
              <h4>Change Password</h4>

              <form onSubmit={this.handlePasswordChange}>
                <FormInput
                  icon="fa fa-key icon"
                  type="password"
                  name="oldPassword"
                  placeholder="Old Password"
                  value={this.state.oldPassword}
                  handleChange={this.handleChange}
                />

                <FormInput
                  icon="fa fa-key icon"
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  value={this.state.newPassword}
                  handleChange={this.handleChange}
                />

                <FormInput
                  icon="fa fa-key icon"
                  type="password"
                  name="confirmNewPassword"
                  placeholder="Confirm Password"
                  value={this.state.confirmNewPassword}
                  handleChange={this.handleChange}
                />

                <Button
                  type="submit"
                  value="Submit"
                  className="btn btn-dark shadow-none change-pass-btn"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  dashboard: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  changePassword,
})(withRouter(Settings));
