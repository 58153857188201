import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { api_url } from "../../../utils/api";

const Table = (props) => {
  console.log("Text Contents", props.allTextContents?.allTextContents);

  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Action</th>
            <th>English(en) </th>
            <th>Arabic (ar)</th>
            <th>Russian (ru)</th>
            <th>German (de)</th>
            <th>French (fr)</th>
            <th>Spanish (es)</th>
            <th>Portugese (pt-PT)</th>
            <th className="translation">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.allTextContents &&
            props.allTextContents.length > 0 &&
            props.allTextContents.map((p, index) => (
              <tr key={p?._id}>
                <td>{index + 1}</td>
                <td>
                  <Link to={`/admin/text-contents-details/${p?._id}`}>
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>

                  {/* <Link to="#">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p?._id)}
                    ></i>
                  </Link> */}
                </td>
                <td>
                  <textarea
                    type="text"
                    placeholder="English"
                    value={p?.textName[0]?.name}
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[1]?.name}
                    placeholder="Arabic"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[2]?.name}
                    placeholder="Russian"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[3]?.name}
                    placeholder="German"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[4]?.name}
                    placeholder="French"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[5]?.name}
                    placeholder="Spanish"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.textName[6]?.name}
                    placeholder="Portugese"
                    onChange={props.handleChange}
                    readOnly
                  ></textarea>
                </td>
                <td>
                  <span className={p?.status === "active" ? "active" : "suspended"}>
                    {p?.status ==="active" ? "Active" : "Inactive"}
                  </span>
                  {/* <button
                    type="button"
                    className="btn-sucess"
                    onClick={(e) =>
                      props.handleTextUpdateSubmit(index)
                    }
                  >
                    Done
                  </button> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
