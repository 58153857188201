import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AppRevenue extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      editor: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {/* <h3 style={{ marginTop: "2rem" }}>This is analytics page </h3> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="app-revenue">
                  <h5>App Store Subscription :</h5>
                  <iframe
                    width="100%"
                    height="450"
                    src="https://lookerstudio.google.com/embed/reporting/9c0aac32-3d8d-4078-a06f-6280c3574421/page/NHOYD"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="screen-view">
                  <h5>Screen view :</h5>
                  <iframe
                    width="100%"
                    height="450"
                    src="https://lookerstudio.google.com/embed/reporting/e44f67ff-b518-485e-b452-37ae0d12f8b1/page/nmYYD"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              {/* <div className="col-md-6 mb-3">
                <div className="geography">
                  <h5>Geographical Distribution :</h5>
                  <iframe
                    width="100%"
                    height="450"
                    src="https://lookerstudio.google.com/embed/reporting/4470678a-c5c9-41a0-a16d-77b1f0a93537/page/UZfYD"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen
                  ></iframe>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AppRevenue.propTypes = {
  dashboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {})(AppRevenue);
