import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import "./styles.scss";

const AddThemeCategory = (props) => {
  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showAddThemeCategoryModal}
        onHide={props.handleAddThemeCategoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Theme Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddNew}>
            <FormInput
              icon="fa fa-file icon"
              size="15px"
              type="text"
              name="textContent"
              placeholder="Enter Theme Category"
              value={props.textContent}
              handleChange={props.handleChange}
            />
            <Button
              type="submit"
              value="Add"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddThemeCategory;
