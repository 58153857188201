import React from "react";

const FormTextArea = (props) => {
  return (
    <React.Fragment>
      <div className="form-group input-icons">
        <i className={props.icon} style={{ fontSize: props.size }}></i>
        <textarea
          style={{ height: "50px", borderRadius: "8px", marginBottom: "25px" }}
          type={props.type}
          name={props.name}
          className="form-control shadow-none"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly}
        />
      </div>
    </React.Fragment>
  );
};

export default FormTextArea;
