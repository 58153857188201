import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Button/Button";
import "./styles.scss";

const SubscriptionModal = (props) => {
  const subscriptions = useSelector((state) => state).dashboard.subscriptions;

  const dispatch = useDispatch();

  const [groups, setGroups] = useState({});
  const [subscription, setSubscription] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState("");

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  useEffect(() => {
    setGroups(groupBy(subscriptions || [], "planName"));
  }, [subscriptions]);

  console.log("subscription", subscription);

  const onSubmit = async () => {
    if (!subscriptionDetails) return;

    try {
      const data = {
        productId: subscriptionDetails?.productId?.ios,
        id: props.userId,
      };
      //   let purchase = await dispatch(assignSubscription(props.token, data));

      setTimeout(() => {
        setSubscription("");
        setSubscriptionDetails("");
        props.handleDeleteModalClose();
      }, 100);
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.showDeleteModal}
        onHide={props.handleDeleteModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {subscription?.[0]?.planName ?? "Select Subscription"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(groups).map((each, i) => (
                  <Dropdown.Item
                    href="#/action-1"
                    onSelect={() => {
                      setSubscription(groups[each]);
                      setSubscriptionDetails("");
                    }}
                  >
                    {each}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {subscription ? (
              <Dropdown style={{ marginLeft: "25px" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {subscriptionDetails === ""
                    ? "Select Tenure"
                    : subscriptionDetails?.duration === 1
                    ? "Monthly"
                    : "Yearly"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {subscription.map((each) => (
                    <Dropdown.Item
                      href="#/action-1"
                      onSelect={() => {
                        setSubscriptionDetails(each);
                      }}
                    >
                      {each?.duration == 1 ? "Monthly" : "Yearly"}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>
          {subscriptionDetails ? (
            <div style={{ marginTop: "5%" }}>
              <h4>Vessels avaliable : {subscriptionDetails?.vesselCount}</h4>
              <h3>
                expires on :{" "}
                {moment()
                  .add(subscriptionDetails?.duration, "months")
                  .format("DD MMM YYYY")}
              </h3>
              <h5>{subscriptionDetails?.text}</h5>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            value="No Thanks"
            className="btn shadow-none no-btn"
            type="button"
            handleClick={props.handleDeleteModalClose}
          />
          <div style={{ opacity: subscriptionDetails ? 1 : 0.5 }}>
            <Button
              value="Submit"
              className="btn btn-danger shadow-none yes-btn"
              type="button"
              handleClick={onSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SubscriptionModal;
