import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Table from "./Table";
import Search from "../../common/Search/Search";
import Button from "../../common/Button/Button";
import DeleteModal from "../../common/Modal/DeleteModal";
import Pagination from "../../common/Pagination/Pagination";
import AddPreferenceModal from "../../common/Modal/AddPreference";
import { validateAddPreference } from "../../../validations/add-validation";
import {
  getAllManagers,
  getAllPreferences,
  addPreference,
  addPreferenceTranslations,
  createManager,
  searchPreference,
  deletePreference,
  blockOrUnblockManager,
  getAllSubscriptions,
} from "../../../actions/dashboardActions";
import "./styles.scss";
import SubscriptionModal from "../../common/Modal/SubscriptionModal";

class ManagePreferences extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      showDeleteModal: false,
      showAddPreferenceModal: false,
      showSubscriptionModal: false,
      preference: {
        preference: "",
      },
      preferences: [],
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  preferenceObj = () => ({
    preferenceId: "",
    preferenceNameArr: "",
    preferences: [
      {
        locale: "ar",
        name: "",
      },
      {
        locale: "ru",
        name: "",
      },
      {
        locale: "de",
        name: "",
      },
      {
        locale: "fr",
        name: "",
      },
      {
        locale: "es",
        name: "",
      },
      {
        locale: "pt-PT",
        name: "",
      },
    ],
  });

  componentDidMount = () => {
    this.props.getAllPreferences(this.state.token);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allPreferences && nextProps.allPreferences.length > 0) {
      this.setState({
        preferences: nextProps.allPreferences.map((pref) => {
          const copyPreferenceObj = this.preferenceObj();
          copyPreferenceObj.preferenceId = pref._id;
          copyPreferenceObj.preferenceNameArr = [
            ...pref.preference,
            copyPreferenceObj.preferences,
          ];
          return copyPreferenceObj;
        }),
      });
    }
  };

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  addManagerModalClicked = () => {
    this.setState({ showAddPreferenceModal: true });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleAddPreferenceModalClose = () => {
    this.setState({ showAddPreferenceModal: false });
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscription: true, id });
  };

  handleYesBtnClick = () => {
    this.props.deletePreference(this.state.id, this.state.token);
    this.props.getAllPreferences(this.state.token);
    this.setState({ showDeleteModal: false });
  };

  handleYesBtnClickSubscription = () => {};

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  blockOrUnblockManager = (id, status) => {
    let data = {
      id,
      status,
    };

    this.props.blockOrUnblockManager(this.state.token, data);
  };

  subscriptionModalClicked = (id) => {
    this.setState({ showSubscriptionModal: true, id });
  };

  handleChange = (e) => {
    const preference = { ...this.state.preference };
    preference[e.target.name] = e.target.value;
    this.setState({ preference });
  };

  handleAddPreferenceSubmit = (e) => {
    e.preventDefault();

    const { preference } = this.state.preference;

    const result = validateAddPreference(preference);
    if (result) return toast.error(result);

    this.props.addPreference(this.state.preference, this.state.token);

    this.setState({ showAddPreferenceModal: false });
  };

  handleAddPreferenceTranslationSubmit = (index) => {
    if (this.state.preferences?.[index]) {
      this.props.addPreferenceTranslations(
        this.state.preferences?.[index],
        this.state.token
      );

      this.setState({ showAddPreferenceModal: false });
    }
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchPreference(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllVoices(this.state.token, page, this.state.pageLimit);
  };

  render() {
    // const totalManagers = sessionStorage.getItem("total_voices") || 0;
    const { allPreferences } = this.props;
    const totalPreferences = allPreferences.length;
    const profile = this.props.dashboard.adminProfileData;

    const handleLangInputChange = (index, langInx, value) => {
      const copyPreferences = [...this.state.preferences];
      copyPreferences[index].preferences[langInx].name = value;
      this.setState({ preferences: copyPreferences });
    };

    return (
      <React.Fragment>
        <div className="manage-patient-wrapper m-pw">
          <h5 className="page_heading new-page-heading">Manage Preferences</h5>
          <div className="manage-patient">
            <div className="row search_row">
              <div className="col-md-7 button_col">
                <Button
                  type="button"
                  className="btn btn-primary add-new-patient-btn shadow-none"
                  value="Add Preference"
                  handleClick={this.addManagerModalClicked}
                />
              </div>
              <div className="col-md-5 search_col">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search Preferences"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="table-responsive">
              <Table
                deleteModalClicked={this.deleteModalClicked}
                allPreferences={this.state.preferences}
                blockOrUnblockManager={this.blockOrUnblockManager}
                handleLangInputChange={handleLangInputChange}
                handleAddPreferenceTranslationSubmit={
                  this.handleAddPreferenceTranslationSubmit
                }
                editSubscription={this.subscriptionModalClicked}
                data={profile}
                showSubscription
              />
            </div>
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {!this.state.query && (
                  <Pagination
                    count={totalPreferences}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Account"
          text="Are you sure you want to delete this preference ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <SubscriptionModal
          showDeleteModal={this.state.showSubscriptionModal}
          handleDeleteModalClose={() =>
            this.setState({ showSubscriptionModal: false })
          }
          title="Choose Subscription"
          token={this.state.token}
          userId={this.state.id}
          handleYesBtnClick={this.handleYesBtnClickSubscription}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddPreferenceModal
          showAddPreferenceModal={this.state.showAddPreferenceModal}
          handleAddPreferenceModalClose={this.handleAddPreferenceModalClose}
          handleAddPreferenceSubmit={this.handleAddPreferenceSubmit}
          handleChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

ManagePreferences.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllPreferences: PropTypes.func.isRequired,
  addPreference: PropTypes.func.isRequired,
  addPreferenceTranslations: PropTypes.func.isRequired,
  searchPreference: PropTypes.func.isRequired,
  deletePreference: PropTypes.func.isRequired,
  blockOrUnblockManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("state>>", state),
  {
    dashboard: state.dashboard,
    allPreferences: state?.dashboard?.allPreferences,
  }
);

export default connect(mapStateToProps, {
  getAllPreferences,
  addPreference,
  addPreferenceTranslations,
  searchPreference,
  deletePreference,
  blockOrUnblockManager,
  getAllSubscriptions,
})(ManagePreferences);
