import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { image_url } from "../../../utils/api";

const Table = (props) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr no.</th>
            <th>Character</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Subscribed</th>
            <th>Notifications Count</th>
            <th>Date Registered</th>
            <th>Status</th>
            <th className="action">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.allUsers &&
            props.allUsers.length > 0 &&
            props.allUsers.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={
                      p.character === null && "null"
                        ? image_url + "/default.png"
                        : p.character
                    }
                    alt="profile"
                  />
                </td>
                <td>{p.name ? p.name : "N/A"}</td>
                <td>{p.gender ? p.gender : "Prefer not to answer"}</td>
                <td>{JSON.stringify(p?.isSubscribed)}</td>
                <td>{p?.notificationsCount || 0}</td>
                <td>{moment(p?.createdAt).format("MMM DD, YYYY")}</td>
                <td>
                  <span
                    className={p?.status !== "active" ? "inactive" : "active"}
                  >
                    {p?.status !== "active" ? "Inactive" : "Active"}
                  </span>
                </td>
                <td>
                  <Link
                    to={`/admin/customer-details/${p._id}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p._id)}
                    ></i>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
