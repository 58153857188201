import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import VienRadio from "../../common/Radio/Radio";
import LazyLoadImage from "../../common/Lazy/Image";
import FormSelectDropdown from "../FormSelectDropdown/FormSelectDropdown";
import { fontNamesIOS, fontNamesAndriod } from "../../../utils/FontNames";
import FormDropdownTheme from "../../common/FormDropdown/FormDropdownTheme";
import "./styles.scss";

const AddTheme = (props) => {
  const [activeBtn, setActiveBtn] = useState(true);
  const [inactiveBtn, setInactiveBtn] = useState(false);
  const [selectedCat, setSelectedCat] = useState(props.themeCategory);

  useEffect(() => {
    props.handleSelectChange({
      themeCategoryId: selectedCat,
    });
  }, [selectedCat]);

  const changeCatHandler = useCallback(
    (value) => {
      setSelectedCat(value);
    },
    [selectedCat]
  );

  const getSelectedCat = () => {
    let res = null;
    if (
      selectedCat &&
      props.allThemesCategories.findIndex((p) => p?.["_id"] == selectedCat) > -1
    ) {
      const selectedCatObj =
        props.allThemesCategories[
          props.allThemesCategories.findIndex((p) => p?.["_id"] == selectedCat)
        ];

      res = selectedCatObj?.themeCategoryName;
    }

    return res;
  };

  const handleRadioChange = (status) => {
    if (status === "true") {
      setActiveBtn(true);
      setInactiveBtn(false);
    } else {
      setActiveBtn(false);
      setInactiveBtn(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="AddSubcategory-Modal"
        show={props.showAddThemeModal}
        onHide={props.handleAddThemeModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Theme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={props.handleAddThemeSubmit}
            encType="multipart/form-data"
          >
            <div style={{ position: "relative" }}>
              <FormInput
                icon="fa fa-ticket icon"
                size="15px"
                type="text"
                name="themeCategoryId"
                placeholder="Theme Category"
                value={getSelectedCat()}
                handleChange={props.handleChange}
              />
              <FormDropdownTheme
                changeCatHandler={changeCatHandler}
                selectedCat={selectedCat}
                allThemesCategories={props.allThemesCategories}
              />
            </div>

            <FormInput
              icon="fa fa-ticket icon"
              size="15px"
              type="text"
              name="backgroundColor"
              placeholder="Theme Background Color ( #HexValue )"
              value={props.backgroundColor}
              handleChange={props.handleChange}
            />
            <FormInput
              icon="fa fa-ticket icon"
              size="15px"
              type="text"
              name="backgroundOpacity"
              placeholder="Theme Background Opacity"
              value={props.backgroundOpacity}
              handleChange={props.handleChange}
            />
            <FormSelectDropdown
              icon="fa fa-ticket icon"
              size="15px"
              type="text"
              name="fontName"
              placeholder="Font Name (ios)"
              value={props.fontName}
              handleChange={props.handleChange}
              options={fontNamesIOS}
            />

            <FormSelectDropdown
              icon="fa fa-ticket icon"
              size="15px"
              type="text"
              name="andriodFontName"
              placeholder="Font Name (andriod)"
              value={props.andriodFontName}
              handleChange={props.handleChange}
              options={fontNamesAndriod}
            />

            <FormInput
              icon="fa fa-ticket icon"
              size="15px"
              type="text"
              name="fontColor"
              placeholder="Font Color ( #Hex-value )"
              value={props.fontColor}
              handleChange={props.handleChange}
            />
            <div className="form-group mt-3">
              <label className="status-label">Premium : </label>
              <VienRadio
                name="status"
                text="true"
                checked={activeBtn}
                handleChange={handleRadioChange}
              />
              <VienRadio
                name="status"
                text="false"
                checked={inactiveBtn}
                handleChange={handleRadioChange}
              />
            </div>
            <div className="form-group">
              <LazyLoadImage
                src={props.imagePreview}
                key="smallFileName"
                alt="Blog-image"
                width="60"
              />

              <div className="text-center mx-auto">
                <label className="upload-image" htmlFor="upload-button1">
                  Upload Theme
                </label>
              </div>
              <input
                id="upload-button1"
                name="image"
                multiple
                type="file"
                accept="image/*"
                onChange={props.handleImageChange}
              />
            </div>

            <Button
              type="submit"
              value="Add Theme"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddTheme;
