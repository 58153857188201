import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { app_url } from "../../../utils/api";

const Table = (props) => {
  console.log("Subscription", props);
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Sr no.</th>
            <th>Character</th>
            <th>Character type</th>
            <th>Date Registered</th>
            <th>Status</th>
            <th className="action">Action</th>
            {/* {props?.showSubscription && <th>Subscription</th>}
            {props?.showSubscription && <th>Valid Till</th>} */}
          </tr>
        </thead>
        <tbody>
          {props.allCharacters &&
            props.allCharacters.length > 0 &&
            props.allCharacters.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={
                      p.characterImage_sm && p.characterImage_sm
                        ? p.characterImage_sm
                        : app_url + "/default.png"
                    }
                    alt="profile"
                  />
                </td>
                <td>
                  {p.characterType === 1
                    ? "Female"
                    : p.characterType === 2
                    ? "Male"
                    : "Others"}
                </td>
                <td>{moment(p.createdAt).format("MMM DD, YYYY")}</td>
                <td>
                  <span className={p.isUserBlocked ? "suspended" : "active"}>
                    {p.isUserBlocked ? "Suspended" : "Active"}
                  </span>
                </td>
                <td>
                  <Link
                    to={`/admin/character-details/${p._id}`}
                    className="Edit-Button"
                  >
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </Link>
                  {p.isUserBlocked && (
                    <Link to="#">
                      <i
                        className="fa fa-unlock"
                        onClick={() =>
                          props.blockOrUnblockManager(p._id, false)
                        }
                      ></i>
                    </Link>
                  )}
                  <Link to="#" className="Delete-Button">
                    <i
                      className="fa fa-trash"
                      onClick={() => props.deleteModalClicked(p._id)}
                    ></i>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
