import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import { validateEditManager } from "../../../validations/add-validation";
import {
  getTextContents,
  getThemeCategory,
  updateTextContent,
  updateThemeCategory
} from "../../../actions/dashboardActions";

const ContentDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [preferenceTranslation, setPreferenceTranslation] = useState([
    {
      locale: "en",
      name: "",
    },
    {
      locale: "es",
      name: "",
    },
    {
      locale: "ar",
      name: "",
    },
    {
      locale: "fr",
      name: "",
    },
    {
      locale: "ru",
      name: "",
    },
    {
      locale: "pt-PT",
      name: "",
    },
    {
      locale: "de",
      name: "",
    },
  ]);

  useEffect(() => {
    props.getThemeCategory(id, token);
  }, []);

  const handlePreferenceChange = (index, newValue) => {
    setPreferenceTranslation((prevState) => {
      const upatedPreference = [...prevState];
      upatedPreference[index].name = newValue;
      return upatedPreference;
    });
  };

  const handlePreferenceUpdate = (e) => {
    e.preventDefault();

    const content = {
      categoryId: id,
      changeCategory: preferenceTranslation,
    };

    props.updateThemeCategory(content, token  );
    props.history.push("/admin/themes-category");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">
          Edit Theme Category
        </h3>
        <div className="row">
          <div className="col-md-8 pr-0 mt_sm_30">
            <div className="personal-details">
              <Form
                key={props.dashboard.singleThemeCategory._id}
                preferenceDetails={props.dashboard.singleThemeCategory}
                handlePreferenceUpdate={handlePreferenceUpdate}
                handlePreferenceChange={handlePreferenceChange}
                preferenceTranslation={preferenceTranslation}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ContentDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getThemeCategory: PropTypes.func.isRequired,
  updateThemeCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getThemeCategory, updateThemeCategory })(
  memo(ContentDetails)
);
