import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import LazyLoadImage from "../../common/Lazy/Image";
import { api_url } from "../../../utils/api";

const Table = (props) => {
  console.log("props.allCategories", props.allCategories);

  return (
    <React.Fragment>
      <table className="table text-nowrap">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th className="action">Action</th>
            <th>English(en) </th>
            <th>Arabic (ar)</th>
            <th>Russian (ru)</th>
            <th>German (de)</th>
            <th>French (fr)</th>
            <th>Spanish (es)</th>
            <th>Portugese (pt-PT)</th>
            <th className="translation">Submit</th>
          </tr>
        </thead>
        <tbody>
          {props.allCategories &&
            props.allCategories.length > 0 &&
            props.allCategories.map((p, index) => (
              <tr key={p?.categoryId}>
                <td>{index + 1}</td>
                <td>
                  <td>
                    <Link
                      to={`/admin/category-details/${p.categoryId}`}
                      className="Edit-Button"
                    >
                      <span>
                        <i className="fa fa-edit"></i>
                      </span>
                    </Link>
                    {/* <Link to="#" className="Delete-Button">
                      <i
                        className="fa fa-trash"
                        onClick={() => props.deleteModalClicked(p.categoryId)}
                      ></i>
                    </Link> */}
                  </td>
                </td>
                <td>
                  <textarea
                    value={
                      p?.categoryNameVal[0]?.name === "" || null
                        ? "General with local"
                        : p?.categoryNameVal[0]?.name
                    }
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[1]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        0,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Arabic"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[2]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        1,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Russian"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[3]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        2,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="German"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[4]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        3,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="French"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[5]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        4,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Spanish"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    value={p?.categoryNameVal[6]?.name}
                    onChange={(e) =>
                      props.handleLangInputChange(
                        index,
                        5,
                        e?.target?.value ?? ""
                      )
                    }
                    placeholder="Portugese"
                  ></textarea>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn-sucess"
                    onClick={(e) =>
                      props.handleAddCategoryTranslationSubmit(index)
                    }
                  >
                    Done
                  </button>
                </td>

                {/* <td>
                  <span className={p.isUserBlocked ? "suspended" : "active"}>
                    {p.isUserBlocked ? "Suspended" : "Active"}
                  </span>
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
