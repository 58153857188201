import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormInput from "../../common/Form-Input/FormInput";
import FormTextArea from "../../common/Form-TextArea/FormTextArea";
import Button from "../../common/Button/Button";
import { updateFAQ } from "../../../actions/dashboardActions";
import { toast } from "react-toastify";

const Form = (props) => {
  const [title, setTitle] = useState();
  const [slectedLocale, setSlectedLocale] = useState();
  const [localeQuestions, setLocaleQuestions] = useState([]);
  const [updateQuestion, setUpdateQuestion] = useState("");
  const [updateAnswer, setUpdateAnswer] = useState("");
  const [faqTitle, setFaqTitle] = useState("");
  const [questionId, setQuestionId] = useState(null);
  const [faqId, setFaqID] = useState();
  useEffect(() => {
    setTitle(props.faqTitle);
  }, [props])

  const handleChangeLocale = (event) => {
    event.preventDefault();
    setSlectedLocale(event.target.value)

    const faqContent = title.faq.filter((v) => {
      return v.locale == event.target.value
    })

    setLocaleQuestions(faqContent)
    setFaqTitle(faqContent[0]?.faqTitle)
    setFaqID(faqContent[0]?._id)
    props.faqContent(faqContent)
    setUpdateQuestion("")
    setUpdateAnswer("")
    setQuestionId(null)
  }

  const handleChangeQuestionIndex = (event) => {
    event.preventDefault();
    let questionId = event.target.value
    let questionAnswer = localeQuestions[0]?.faqContent.filter((v) => {
      return v._id === questionId
    })
    setQuestionId(questionAnswer[0]?._id)
    setUpdateQuestion(questionAnswer[0]?.question)
    setUpdateAnswer(questionAnswer[0]?.answer)
  }

  const handleFaqUpdate = (e) => {
    
    e.preventDefault();
    const token = localStorage.getItem("jwtToken");
    if (localeQuestions.length == 0) {
      toast.error("Please Select Locale First ")
      return ;
    }

    if (faqTitle === "" || null || undefined) {
      toast.error("FAQ Category is required")
      return ;
    }

    if (updateQuestion === "" || null || undefined) {
      toast.error("Question is required")
      return ;
    }

    if (updateAnswer === "" || null || undefined) {
      toast.error("Answer is required")
      return ;
    }

    let updateBody = {
      faqId: faqId,
      faqTitle: faqTitle,
      faqContent: {
        question: updateQuestion,
        answer: updateAnswer,
        _id: questionId
      }
    }

    if (questionId=== null) {
      delete updateBody.faqContent._id
    }

    updateFAQ(updateBody, token);
    props.history.push("/admin/faq/manage");
  };

  const localeNames = {
    en: 'English',
    fr: 'French',
    de: 'German',
  };

  return (
    <React.Fragment>
      <form onSubmit={handleFaqUpdate} >
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">
            Select Locale
          </label>

          <select
            className="w-100 select_option mb-3 px-2"
            onChange={(e) => handleChangeLocale(e)}
          >
            <option key={'0'} >
              Select Locale
            </option>
            {title?.faq?.map((v, index) => {
              return (
                <>
                  <option value={v?.locale} key={index}>
                    {localeNames[v?.locale]}
                  </option>
                </>
              );
            })}
          </select>
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">FAQ Category</label>
          <FormInput
            icon="fa fa-question-circle icon"
            size="13px"
            type="text"
            name="faqTitle"
            placeholder="FAQ Category"
            className="form-control shadow-none"
            value={faqTitle}
            handleChange={(e) => setFaqTitle(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <label className="status-label">
            Select question you want to edit
          </label>

          <select
            className="w-100 select_option mb-3 px-2"
            onChange={(e) => handleChangeQuestionIndex(e)}
          >
            <option key={'0'} >
              Select Questions
            </option>
            {localeQuestions && localeQuestions[0]?.faqContent.map((content, index) => {
              return (
                <>
                  <option value={content?._id} key={index}>
                    {content?.question}
                  </option>
                </>
              );
            })}
          </select>
        </div>

        <>
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">{`Question `}</label>
            <FormInput
              icon="fa fa-question-circle icon"
              size="13px"
              type="text"
              name="question"
              placeholder="FAQ Question"
              className="form-control shadow-none"
              value={updateQuestion}
              handleChange={(e) => setUpdateQuestion(e.target.value)}
            />
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <label className="status-label">{`Answer `}</label>
            <FormTextArea
              icon="fa fa-question-circle icon"
              size="13px"
              type="text"
              name="answer"
              placeholder="FAQ Answer"
              className="form-control shadow-none"
              value={updateAnswer}
              handleChange={(e) => setUpdateAnswer(e.target.value)}
            />
          </div>
        </>
        <div className="form-group mb-0">
          <Button
            value="Update"
            className="btn btn-primary btn-block update-details-btn shadow-none"
            type="submit"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
