import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import "./styles.scss";

const AddCategory = (props) => {
  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showAddCategoryModal}
        onHide={props.handleAddCategoryModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddCategorySubmit}>
            <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="categoryName"
              placeholder="Category Name"
              value={props.categoryName}
              handleChange={props.handleChange}
            />
            <Button
              type="submit"
              value="Add Category"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddCategory;
