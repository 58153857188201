import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "./Form";
import BasicDetails from "./BasicDetails";
import Staffs from "./Staffs";
import { validateEditCharacter } from "../../../validations/add-validation";
import {
  getCharacter,
  updateCharacter,
} from "../../../actions/dashboardActions";

const CharcterDetails = (props) => {
  const { id } = useParams();
  const token = localStorage.getItem("jwtToken");
  const [characterDetails, setCharacterDetails] = useState("");
  const [characterType, setCharacterType] = useState(1);
  const [characterImage_sm, setCharacterImage_sm] = useState("");
  const [characterImage_lg, setCharacterImage_lg] = useState("");
  const [smallImagePreviewUrl, setSmallImagePreviewUrl] = useState("");
  const [largeImagePreviewUrl, setLargeImagePreviewUrl] = useState("");

  const characterTypeWord =
    props.dashboard.singleCharacter?.characterType === 1
      ? "Female"
      : props.dashboard.singleCharacter?.characterType === 2
      ? "Male"
      : "Others";

  const handleChange = (value) => {
    setCharacterType(value);
  };

  const handleSmallImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setCharacterImage_sm(file);
      setSmallImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleLargeImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setCharacterImage_lg(file);
      setLargeImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    props.getCharacter(id, token);
  }, []);

  useEffect(() => {
    if (props.dashboard.singleCharacter) {
      setCharacterDetails(props.dashboard.singleCharacter);
    }
  }, [props]);

  const handleUpdateCharacter = (e) => {
    e.preventDefault();

    const result = validateEditCharacter(
      characterType,
      characterImage_sm,
      characterImage_lg
    );
    if (result) return toast.error(result);

    const character = new FormData();
    character.append("characterId", id);
    character.append("characterType", characterType);
    character.append("characterImage_sm", characterImage_sm);
    character.append("characterImage_lg", characterImage_lg);

    props.updateCharacter(character, token);
    props.history.push("/admin/characters");
  };

  return (
    <React.Fragment>
      <div className="patient-details new-pd">
        <h3 className="page_heading new-page-heading">Character Details</h3>

        <div className="row mx-0">
          <div className="col-md-6 pr-0 mt_sm_30">
            <div className="personal-details">
              <h5>Character Details</h5>
              <Form
                key={id}
                characterDetails={characterDetails}
                characterTypeWord={characterTypeWord}
                handleChange={handleChange}
                setCharacterDetails={setCharacterDetails}
                largeImagePreviewUrl={largeImagePreviewUrl}
                smallImagePreviewUrl={smallImagePreviewUrl}
                // handleSelectChange={handleSelectChange}
                handleSmallImageChange={handleSmallImageChange}
                handleLargeImageChange={handleLargeImageChange}
                handleUpdateCharacter={handleUpdateCharacter}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CharcterDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCharacter: PropTypes.func.isRequired,
  updateCharacter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getCharacter, updateCharacter })(
  memo(CharcterDetails)
);
