import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import FormInput from "../../common/Form-Input/FormInput";
import { adminForgotPassword } from "../../../actions/authActions";
import "./styles.scss";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (localStorage.getItem("jwtToken"))
      props.history.push("/admin/dashboard");
  }, [props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.trim().length === 0)
      return toast.error("Please enter your email");
    console.log("Forgot password func");
    props.adminForgotPassword(email, props.history);
  };

  return (
    <React.Fragment>
      <div className="admin-forgot-password ad-for-psd">
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-md-5 mx-auto">
              <div className="card">
                <img
                  className="mx-auto d-block"
                  //  src="/c-logo.png"
                  src="https://api.appservercentral.com/c-logo.png"
                  alt="logo"
                />
                <div className="card-body">
                  <h5>Admin Forgot Password</h5>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      icon="fa fa-envelope icon"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      handleChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="form-group mb-0 mt-4">
                      <input
                        type="submit"
                        value="Send OTP"
                        className="btn btn-dark btn-block forgot-password-btn shadow-none"
                      />
                    </div>

                    <div className="form-group mt-3 mb-0">
                      <Link to="/admin/login" className="backTo">
                        <i className="fa fa-arrow-left"></i> Back to Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  adminForgotPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { adminForgotPassword })(
  withRouter(ForgotPassword)
);
