import React from "react";
import Statistic from "./Statistic";

const Statistics = (props) => {
  const { stats } = props;

  return (
    <>
      <div className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allCustomers}
              name="Customers"
              icon="user"
              size={35}
              // bgColor="#06cbff"
              bgColor="#696bcc"
            />
          </div>
          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allAffirmations}
              name="Affirmations"
              icon="lightbulb"
              size={35}
              bgColor="#696bcc"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allVoices}
              name="Voices"
              icon="microphone"
              size={35}
              bgColor="#696bcc"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allThemesAndFonts}
              name="Themes & Fonts"
              icon="ticket"
              size={35}
              bgColor="#696bcc"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allSubscribers}
              name="Subscribers"
              icon="dollar"
              size={35}
              bgColor="#4646c8"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allCategories}
              name="Categories"
              icon="bars"
              size={35}
              bgColor="#4646c8"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allSubCategories}
              name="Sub-categories"
              icon="signal"
              size={35}
              bgColor="#4646c8"
            />
          </div>

          <div className="col-md-3 pl-0">
            <Statistic
              value={stats && stats.allCharacters}
              name="Characters"
              icon="user-secret"
              size={35}
              bgColor="#4646c8"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
