import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import UpdateFAQ from "../../common/Modal/UpdateFAQ";
import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import {
  allFAQ,
  getAllFAQS,
  deleteFAQ,
  searchFAQ,
  updateFAQ,
} from "../../../actions/dashboardActions";
import { validateFAQ } from "../../../validations/faq";
import "./styles.scss";
import DeleteModal from "../../common/Modal/DeleteModal";

class ManageFAQ extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("jwtToken"),
      id: "",
      query: "",
      pageLimit: 10,
      currentPage: 1,
      showUpdateFAQModal: false,
      question: "",
      answer: "",
      status: "",
      activeBtn: false,
      inactiveBtn: false,
      showDeleteModal: false,
      filterLocale : 0
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("jwtToken");
    this.props.getAllFAQS(token);
    // this.state.currentPage,
    // this.state.pageLimit
  }

  handleDelete = (id) => {
    this.setState({ showDeleteModal: true, id });
  };

  handleSearch = (query) => {
    this.setState({ query });
    this.props.searchFAQ(this.state.token, query);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.props.getAllFAQS(this.state.token, page, this.state.pageLimit);
  };

  handleUpdateFAQ = (faq) => {
    this.setState({
      showUpdateFAQModal: true,
      id: faq._id,
      question: faq.question,
      answer: faq.answer,
      status: faq.status,
      activeBtn: faq.status === "Active" ? true : false,
      inactiveBtn: faq.status === "Inactive" ? true : false,
    });
  };

  handleUpdateFAQModalClose = () => {
    this.setState({ showUpdateFAQModal: false });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ answer: data });
  };

  handleRadioChange = (status) => {
    if (status === "Active") {
      this.setState({ status, activeBtn: true, inactiveBtn: false });
    } else {
      this.setState({ status, activeBtn: false, inactiveBtn: true });
    }
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  handleYesBtnClick = () => {
    this.props.deleteFAQ(this.state.id, this.state.token);
    this.setState({ showDeleteModal: false });
    this.props.getAllFAQS(this.state.token);
  };

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false });
  };

  handleUpdateFAQSubmit = (e) => {
    e.preventDefault();

    let { id, question, answer, status } = this.state;

    let result = validateFAQ(question, answer);
    if (result) return toast.error(result);

    let faq = {
      id,
      question,
      answer,
      status,
    };

    this.props.updateFAQ(faq, this.state.token);

    this.setState({
      editor: "",
      question: "",
      answer: "",
      status: "",
      activeBtn: false,
      inactiveBtn: false,
      showUpdateFAQModal: false,
    });
  };

  handleFilter = (e)=>{
    console.log(e.target.value);
    let filterLocale = parseInt(e.target.value);
    this.setState({ filterLocale: filterLocale });
  }

  render() {
    const { faqs } = this.props;

    return (
      <React.Fragment>
        <div className="manage-faq-wrapper new-mfaq">
          <h5 className="page_heading new-page-heading">Manage FAQs</h5>

          <div className="manage-faq custom_class">
            <div className="row">
              <div className="col-md-5">
              </div>
              <div className="col-md-5">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search FAQ Title"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
              <div className="col-md-2" >
                <div className="filter-form float-right">
                  <>
                    <i className="fa fa-filter filter-size"></i>
                    <select
                      className="filter-select"
                    // value={this.state.selectedFilter}
                    onChange={this.handleFilter}
                    style={{cursor:'pointer'}}
                    >
                      <option value="" disabled>
                        Select Filter
                      </option>
                      <option value="0">English</option>
                      <option value="1">French</option>
                      <option value="2">German</option>
                    </select>
                  </>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr no.</th>
                    <th>FAQ Title</th>
                    <th>Questions</th>
                    <th>Answers</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.dashboard.faqs &&
                    this.props.dashboard.faqs.length > 0 &&
                    this.props.dashboard.faqs.map((faq, index) => (
                      <tr key={faq._id}>
                        <td>{index + 1}</td>
                        <td className="fw-bold">{faq.faq[this.state.filterLocale]?.faqTitle}</td>
                        <td colSpan="2" style={{ padding: "0px" }}>
                          <table>
                            <tbody>
                              {faq.faq[this.state.filterLocale]?.faqContent.map((faqQuestion, q) => (
                                <tr key={faqQuestion._id}>
                                  <td style={{ width: "200px" }}>
                                    {faqQuestion.question}
                                  </td>
                                  <td>{faqQuestion.answer}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            height: "0 auto",
                            verticalAlign: "middle",
                          }}
                        >
                          <Link
                            to={`/admin/faq-details/${faq._id}`}
                            className="Edit-Button"
                            onClick={() => this.handleUpdateFAQ(faq)}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>

                          <Link
                            to="#"
                            className="Delete-Button"
                            onClick={() => this.handleDelete(faq._id)}
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
            </div>
          </div>
        </div>

        <UpdateFAQ
          showUpdateFAQModal={this.state.showUpdateFAQModal}
          handleUpdateFAQModalClose={this.handleUpdateFAQModalClose}
          handleUpdateFAQSubmit={this.handleUpdateFAQSubmit}
          handleInputChange={this.handleInputChange}
          handleInit={this.handleInit}
          handleChange={this.handleChange}
          handleRadioChange={this.handleRadioChange}
          question={this.state.question}
          answer={this.state.answer}
          activeBtn={this.state.activeBtn}
          inactiveBtn={this.state.inactiveBtn}
        />

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title="Delete Faq"
          text="Are you sure you want to delete this FAQ ?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />
      </React.Fragment>
    );
  }
}

ManageFAQ.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllFAQS: PropTypes.func.isRequired,
  deleteFAQ: PropTypes.func.isRequired,
  searchFAQ: PropTypes.func.isRequired,
  updateFAQ: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  faqs: state?.dashboard?.faqs,
});

export default connect(mapStateToProps, {
  getAllFAQS,
  deleteFAQ,
  searchFAQ,
  updateFAQ,
})(ManageFAQ);
