import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";

import LazyLoadImage from "../../common/Lazy/Image";

import "./styles.scss";

const AddThemeImage = (props) => {
  return (
    <React.Fragment>
      <Modal
        className="AddSubcategory-Modal"
        show={props.showAddThemeImageModal}
        onHide={props.handleAddThemeImageModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Theme Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={props.handleAddThemeImageSubmit}
            encType="multipart/form-data"
          >
            <FormInput
              icon="fa fa-users icon"
              size="15px"
              type="text"
              name="themeImage"
              placeholder="Theme Image"
              value={props.themeImage}
              handleChange={props.handleChange}
            />

            {/* <div className="upload">
              <LazyLoadImage src={props.imagePreview} alt="profile-pic" />

              <div className="text-center mx-auto">
                <label htmlFor="upload-button">Upload Image</label>
              </div>

              <input
                size="15px"
                name="image"
                type="file"
                value={props.image}
                accept="image/*"
                onChange={props.handleImageChange}
              />
            </div> */}

            <Button
              type="submit"
              value="Add Theme Image"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddThemeImage;
