import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";


function VienBarChart({ stats }) {
  const { allSubscriber } = stats;

  let data = allSubscriber || [];

  let newData = data && data.reduce(function (r, a) {
    r[a._id] = r[a._id] || [];
    r[a._id].push(a.subscribers);
    return r;
  }, Object.create(null));

  let arr = new Array(12);
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  for (var i = 0; i < arr.length; i++) {
    if (newData[i]) {
      arr[i] = { month: monthNames[i], subscribers: newData[i][0] }
    } else {
      arr[i] = { month: monthNames[i], subscribers: 0 }
    }
  }

  return (
    <React.Fragment>
      <div className="bar-chart">
        <h6>Monthly Subscribers</h6>
        <BarChart width={500} height={300} data={data}>
          <XAxis dataKey="month" />
          <YAxis dataKey="subscribers" />
          <Tooltip />
          <Bar dataKey="subscribers" fill="#8884d8" />
        </BarChart>
      </div>
    </React.Fragment>
  );
}

export default VienBarChart;
