import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import "./styles.scss";
import FormDropdownGender from "../../common/FormDropdown/FormDropdownGender";

const AddCustomer = (props) => {
  const [selectedGender, setSelectedGender] = useState(props?.gender);

  const genders = [
    { index: 1, gender: "Female" },
    { index: 2, gender: "Male" },
    { index: 3, gender: "Others" },
    { index: 4, gender: "Prefer not to answer" },
  ];

  useEffect(() => {
    props.handleSelectChange({
      gender: getSelectedGender(),
    });
  }, [selectedGender]);

  const changeGenderHandler = useCallback(
    (value) => {
      setSelectedGender(value);
    },
    [selectedGender]
  );

  const getSelectedGender = () => {
    let res = null;

    if (
      selectedGender &&
      genders.findIndex((p) => p?.index == selectedGender) > -1
    ) {
      const selectedGenders =
        genders[genders.findIndex((p) => p?.index == selectedGender)];
      res = selectedGenders?.gender;
    }

    console.log("Gender--->", res);

    return res;
  };

  return (
    <React.Fragment>
      <Modal
        className="AddPreference-Modal"
        show={props.showAddCustomerModal}
        onHide={props.handleAddCustomerModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleAddCustomerSubmit}>
            <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="name"
              placeholder="Name"
              value={props.name}
              handleChange={props.handleChange}
            />

            <div style={{ position: "relative" }}>
              <FormInput
                icon="fa fa-user icon"
                size="15px"
                type="text"
                name="gender"
                placeholder="Gender"
                value={props.gender || getSelectedGender()}
                handleChange={props.handleChange}
              />
              <FormDropdownGender
                changeGenderHandler={changeGenderHandler}
                selectedGender={selectedGender}
                genders={genders}
              />
            </div>

            {/* <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="gender"
              placeholder="Gender"
              value={props.gender}
              handleChange={props.handleChange}
            /> */}
            <Button
              type="submit"
              value="Add Customer"
              className="btn btn-primary btn-block add-patient-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddCustomer;
