import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Statistics from "./Statistics";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import "./styles.scss";
import { dashboardStats } from "../../../actions/dashboardActions";
import { initializeApp } from "firebase/app";
import LazyLoadImage from "../../common/Lazy/Image";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDn54pOLnYB80hf_pUFgJvJbRgMRAmaDIE",
  authDomain: "msa-affirmation.firebaseapp.com",
  projectId: "msa-affirmation",
  storageBucket: "msa-affirmation.appspot.com",
  messagingSenderId: "631062463670",
  appId: "1:631062463670:ios:30767384356779b0f99217",
  measurementId: "G-BQ28DPPHPZ",
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const token = localStorage.getItem("jwtToken");
    this.props.dashboardStats(token);
  }

  render() {
    return (
      <div className="container-fluid dashboard new-dashboard">
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h3 className="new-page-heading">Dashboard</h3>
            {/* <hr /> */}
            <Statistics stats={this.props.dashboard.dashboardStats} />
            {/* <div className="row mx-0">
              <div className="col-md-6 pl-0">
                <LineChart stats={this.props.dashboard.dashboardStats} />
              </div>
              <div className="col-md-6">
                <BarChart stats={this.props.dashboard.dashboardStats} />
              </div>
            </div> */}
          </div>
        </div>
        <div className="dashboard">
          <div className="row">
            <div className="col-md-12 pr-2">
              <div
                className="card border-0 new-c"
                style={{ marginTop: "2rem" }}
              >
                {/* <hr /> */}
                <div className="card-body">
                  {/* <h4 style={{ marginTop: "2rem" }} className="new-page-heading">Analytics :</h4> */}
                  <h4
                    style={{ marginBottom: "1.5rem" }}
                    className="new-page-heading"
                  >
                    Reports ( Backend ) :
                  </h4>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Category</th>
                          <th>Affirmation / Theme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="dashboard-color-4">
                            <i className="fa fa-bell"></i>
                          </td>
                          <td>Most Notified Affirmation</td>
                          <td className="four_board">
                            {
                              this.props.dashboard.dashboardStats
                                .mostNotifiedAffirmation
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="dashboard-color-1">
                            <i className="fa fa-thumbs-down"></i>
                          </td>
                          <td>Most Disliked Affirmation</td>
                          <td className="one_board">
                            {
                              this.props.dashboard.dashboardStats
                                .mostDislikedAffirmation
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="dashboard-color-4 shared-affirmation">
                            <i className="fa fa-share-nodes"></i>
                          </td>
                          <td>Most Shared Affirmation</td>
                          <td className="shared_board">
                            {
                              this.props.dashboard.dashboardStats
                                .mostSharedAffirmation
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="dashboard-color-1 least-category">
                            <i className="fa fa-bars"></i>
                          </td>
                          <td>Least Favourite Category</td>
                          <td className="category_board">
                            {
                              this.props.dashboard.dashboardStats
                                .leastFavouriteCategory
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="dashboard-color-4 least-background">
                            <i className="fa fa-images"></i>
                          </td>
                          <td>Least Favourite Background</td>
                          <td className="four_board image-size">
                            <LazyLoadImage
                              src={
                                this.props.dashboard.dashboardStats
                                  .leastFavouriteBackground
                              }
                              key="leastFavouriteBackground"
                              alt="Blog-image"
                              width="60"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  dashboard: PropTypes.object.isRequired,
  dashboardStats: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  dashboardStats,
})(Dashboard);
